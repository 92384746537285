<template>
  <div>
    <form action="#" method="POST">
      <label for="filename" class="label">
        Name the fle <span class="optional">(Optional)</span>:
      </label>
      <br />
      <input
        type="text"
        id="filename"
        name="filename"
        @input="setFileName($event.target.value)"
        v-model="fileName"
      />
      <span class="file-upload-error" v-if="err.fileName.length > 0">
        {{ err.fileName }}
      </span>
      <div
        class="file-note"
        v-if="clientParticipant.length > 0 || portalParticipants.length > 0"
      >
        <label for="filename" class="label">
          Choose where you’d like to store this recording:
        </label>
        <div class="my-app">
          <span class="sub-label">In my app:</span>
          <div class="upload-path">
            <label class="checkbox">
              Tools > Files, Forms & Templates > My Files
              <input
                type="checkbox"
                @change="verifyCheckboxVal($event)"
                :value="showOnreportPage"
                v-model="showOnreportPage"
              />
              <span class="checkmark"></span>
            </label>
            <div class="charts" v-if="clientParticipant.length > 0">
              The Files tab of these charts:
              <div class="upload-path clientChart">
                <label
                  class="checkbox"
                  v-for="client in clientParticipant"
                  :key="client.user_id"
                >
                  <span class="checkbox-name">
                    {{
                      `${client.userName.firstName}${
                        client.userName.middleName
                          ? " " + client.userName.middleName
                          : ""
                      }${
                        client.userName.lastName
                          ? " " + client.userName.lastName
                          : ""
                      }`
                    }}
                  </span>
                  <input
                    type="checkbox"
                    @change="verifyCheckboxVal($event)"
                    :value="client.externalId"
                    v-model="selectedClientIds"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="portal-user-file-tabs" v-if="portalParticipants.length > 0">
          <span class="sub-label">In the Files tab of these portals:</span>
          <div class="upload-path portaluserfiles">
            <label
              class="checkbox"
              v-for="user in portalParticipants"
              :key="user.externalId"
            >
              <span class="checkbox-name">
                {{
                  `${user.userName.firstName}${
                    user.userName.middleName
                      ? " " + user.userName.middleName
                      : ""
                  }${
                    user.userName.lastName ? " " + user.userName.lastName : ""
                  }`
                }}
              </span>
              <input
                type="checkbox"
                :value="user.externalId"
                v-model="selectedPortalUserIds"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      <p class="file-note" v-else>
        Your file will be uploaded to the PSYBooks Tools > Files, Forms &
        Templates > My Files section. You’ll be able to access it at any time. A
        link will also appear here as soon as the upload is finished.
      </p>
      <span class="file-upload-error" v-if="err.destination.length > 0">
        {{ err.destination }}
      </span>
      <p class="file-upload-hint">
        The time it will take to upload the recording depends on your internet
        speed. Since video files are large, if you have a poor connection, it's
        possible that it could time out before it finishes. However, you may
        continue to do other things in the app while the file is uploading,
        including starting another video appointment.
      </p>
      <button
        class="btn btn-primary upload-btn"
        @click.prevent="validateAndUploadRecordFile"
      >
        Start Upload
      </button>
    </form>
  </div>
</template>

<script>
import { record } from "../../mixins/Record";
import GeneralConstant from "../../constant/GeneralConstant";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment-timezone";

export default {
  name: "UploadFile",
  mixins: [record],
  data() {
    return {
      fileName: "",
      err: {
        fileName: "",
        destination: ""
      },
      selectedClientIds: [],
      selectedPortalUserIds: [],
      showOnreportPage: true
    };
  },
  computed: {
    ...mapGetters({
      modal: "getModal"
    }),

    ...mapGetters("participant", {
      clients: "getClients",
      persons: "getPersons"
    }),

    ...mapGetters("meet", {
      participantList: "getParticipantList"
    }),

    /**
     * Returns all clients that invited/joined as a participant
     */
    clientParticipant() {
      return this.participantList.filter(participant => {
        const index = this.clients.findIndex(
          client => Number(client.user_id) === Number(participant.externalId)
        );

        return index > -1 && participant.externalId > 0;
      });
    },

    /**
     * Returns all portal participants
     */
    portalParticipants() {
      const portalUsers = this.clients.concat(this.persons);
      return this.participantList.filter(participant => {
        if (participant.externalId > 0) {
          const index = portalUsers.findIndex(
            portalUser =>
              Number(portalUser.user_id) === Number(participant.externalId)
          );
          return index > -1;
        } else {
          return false;
        }
      });
    }
  },
  methods: {
    ...mapMutations({
      setModal: "setModal"
    }),

    ...mapMutations("record", {
      setRecordedFileName: "setRecordedFileName"
    }),

    /**
     * Function to format and set the file name
     */
    setFileName(value) {
      this.resetAllErrors();
      this.fileName = value
        .replace("\\", "")
        .replace("/", "")
        .replace(".", "")
        .substring(0, 30);
    },

    /**
     * Function to verify the checkbox value and hide error messages
     */
    verifyCheckboxVal(event) {
      if (event.target.checked) {
        this.resetAllErrors();
      }
    },

    /**
     * Reset all error messages
     */
    resetAllErrors() {
      this.err.fileName = "";
      this.err.destination = "";
    },

    /**
     * Function to validate and upload the recorded file
     */
    async validateAndUploadRecordFile() {
      if (
        this.fileName.indexOf(".") > -1 ||
        this.fileName.indexOf("/") > -1 ||
        this.fileName.indexOf("\\") > -1
      ) {
        // Validating the file name.
        this.err.fileName = GeneralConstant.error.invalidFileName;
      } else if (
        0 === this.selectedClientIds.length &&
        0 === this.selectedPortalUserIds.length &&
        false === this.showOnreportPage
      ) {
        this.err.destination = GeneralConstant.error.noDestinationPath;
      } else {
        // Close the modal
        this.setModal({
          ...this.modal,
          display: false
        });

        // Sets recorded file name
        this.setRecordedFileName(
          this.recordFileName(this.fileName ? this.fileName : null)
        );

        // Calling the file upload function
        await this.uploadRecording({
          clientIds: this.selectedClientIds,
          portalUserIds: this.selectedPortalUserIds,
          showOnreportPage: this.showOnreportPage
        });
      }
    },

    /**
     * Returns the filename for recording
     */
    recordFileName(initialPart = null) {
      // Get the timezone name
      const timezoneName = moment.tz
        .zone(moment.tz.guess())
        .abbr(new Date().getTimezoneOffset());

      return `${
        initialPart ? initialPart : this.$route.params.meetCode
      }${moment().format("_MM-DD-YYYY_h:mma")}${timezoneName}.mp4`;
    }
  }
};
</script>

<style scoped>
.label {
  font-weight: bold;
  font-size: 14px;
  color: #eeb747;
}
.label .optional {
  font-size: 12px;
  font-weight: normal;
}
input {
  border: 1px solid #7a7a7a;
  background: #282828;
  max-width: calc(100% - 24px);
  padding: 5px 10px;
  color: #f6f6f6;
  margin: 10px 0px;
}
.my-app {
  margin-top: 10px;
}
.charts {
  margin: 0px 0px 0px 35px;
}
.upload-path {
  margin: 10px 0px 0px 10px;
}
.checkbox .checkbox-name {
  max-width: 100%;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.charts .checkbox,
.portal-user-file-tabs .checkbox {
  display: inline-block;
  /* width: 19%; */
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 6%;
}

.clientChart label:first-child:nth-last-child(1),
.clientChart label:first-child:nth-last-child(1) ~ label {
  width: 85%;
}

.clientChart label:first-child:nth-last-child(2),
.clientChart label:first-child:nth-last-child(2) ~ label {
  width: 32%;
}
.clientChart label:first-child:nth-last-child(3),
.clientChart label:first-child:nth-last-child(3) ~ label {
  width: 19%;
}
.portaluserfiles label:first-child:nth-last-child(1),
.portaluserfiles label:first-child:nth-last-child(1) ~ label {
  width: 85%;
}

.portaluserfiles label:first-child:nth-last-child(2),
.portaluserfiles label:first-child:nth-last-child(2) ~ label {
  width: 32%;
}
.portaluserfiles label:first-child:nth-last-child(3),
.portaluserfiles label:first-child:nth-last-child(3) ~ label {
  width: 19%;
}
.upload-btn {
  font-size: 14px;
  float: right;
}
.file-note {
  color: #fff;
  font-size: 14px;
}
.file-upload-error {
  font-size: 14px;
  color: #c13e3e;
  position: relative;
  top: -5px;
  display: block;
}
.sub-label {
  color: #eeb747;
}
.file-upload-hint {
  color: #eeb747;
  font-style: italic;
  font-size: 12px;
}
</style>
