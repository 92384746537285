/**
 * Mutations for chat
 */
export const mutations = {
  /**
   * Setting messages on message list
   *
   * @param {Object} state
   * @param {Array} messages
   */
  setMessages(state, messages) {
    state.messages = messages;
  },

  /**
   * Marking message as read
   *
   * @param {Object} state
   */
  readAllMessages(state) {
    state.messages
      .filter(message => message.senderId !== this.userId && !message.isRead)
      .forEach(message => {
        message.isRead = true;
      });
  },

  /**
   * Renaming user name on message list
   *
   * @param {Object} state
   * @param {Object} data
   */
  renameMessages(state, data) {
    state.messages.forEach(message => {
      if (
        message.receiverId === data.userId ||
        message.senderId === data.userId
      ) {
        message.userName = {
          ...message.userName,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName
        };
      }
    });
  },

  /**
   * Setting notifications
   *
   * @param {Object} state
   * @param {Object} notifications
   */
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },

  /**
   * Setting message fetch status
   *
   * @param {Object} state
   * @param {Boolean} status
   */
  setChatMessageFetchStatus(state, status) {
    state.chatMessageFetchStatus = status;
  }
};
