import GeneralConstant from "../constant/GeneralConstant";
import socket from "../socket/socket";
import { mapGetters, mapMutations, mapActions } from "vuex";

export const media = {
  data() {
    return {
      isVideoTogglingOnProgress: false,
      isAudioTogglingOnProgress: false
    };
  },
  computed: {
    ...mapGetters({
      userId: "getUserId"
    }),

    ...mapGetters("meet", {
      hostInfo: "getHostInfo",
      isCameraStreamReady: "getIsCameraStreamReady",
      meetDetails: "getMeetDetails",
      videoTrack: "getVideoTrack",
      audioTrack: "getAudioTrack"
    })
  },
  methods: {
    ...mapMutations("meet", {
      setMeetDetails: "setMeetDetails"
    }),

    /**
     * To mute/unmute video
     */
    toggleVideo(mute = null) {
      // If user is on waiting room, directly toggle video
      if ("waitRoom" === this.meetDetails.room) {
        this.videoTrack.enabled = !this.videoTrack.enabled;
        this.setMeetDetails({
          ...this.meetDetails,
          isUserVideoMuted: !this.videoTrack.enabled
        });

        return true;
      }

      if (this.isCameraStreamReady && !this.isVideoTogglingOnProgress) {
        this.isVideoTogglingOnProgress = true;

        // If screen share is on, participant can not toggle audio
        if ("camera" !== this.meetDetails.videoTrackType) {
          return false;
        }

        let isEnabled = null;
        if (null === mute) {
          isEnabled = this.videoTrack.enabled;
        } else {
          isEnabled = mute;
        }

        // * In case of un-mute, enable the video first
        if (!isEnabled) {
          this.videoTrack.enabled = !isEnabled;
        }

        // Change mute/un-mute flag on store
        this.setMeetDetails({
          ...this.meetDetails,
          isUserVideoMuted: isEnabled
        });

        // Emit to all other participants regarding video mute/un-mute status
        socket.io.emit(
          "userVideoMuted",
          {
            id: socket.io.id,
            muteStatus: isEnabled
          },
          res => {
            if (res.status) {
              // In case of mute, disable the video
              if (isEnabled) {
                this.videoTrack.enabled = !isEnabled;
              }
              this.isVideoTogglingOnProgress = false;
            }
          }
        );
      }
    },

    /**
     * To mute/unmute audio
     */
    toggleAudio(mute = null) {
      if (this.isCameraStreamReady && !this.isAudioTogglingOnProgress) {
        this.isAudioTogglingOnProgress = true;
        let isEnabled = null;
        if (null === mute) {
          isEnabled = this.audioTrack.enabled;
        } else {
          isEnabled = mute;
        }

        // Change the enable property of audio track
        this.audioTrack.enabled = !isEnabled;

        // Emit to all other participants regarding audio mute/un-mute status
        socket.io.emit(
          "userAudioMuted",
          {
            id: socket.io.id,
            muteStatus: isEnabled
          },
          res => {
            if (res.status) {
              this.isAudioTogglingOnProgress = false;
            }
          }
        );

        // Change the mute status on local state
        this.setMeetDetails({
          ...this.meetDetails,
          isUserAudioMuted: isEnabled
        });
      }
    },

    /**
     * Function to check and change audio status (mute/unmute)
     */
    checkAndChangeAudioStatus() {
      // If user is on waiting room, directly toggle audio
      if ("waitRoom" === this.meetDetails.room) {
        this.audioTrack.enabled = !this.audioTrack.enabled;
        this.setMeetDetails({
          ...this.meetDetails,
          isUserAudioMuted: !this.audioTrack.enabled
        });

        return true;
      }

      if (this.userId === this.hostInfo.id) {
        // Toggle Audio
        this.toggleAudio();
      } else {
        // In case of un-mute, check if host has disabled/enabled the un-mute functionality
        if (this.meetDetails.isUserAudioMuted) {
          if (this.meetDetails.enableUnmute) {
            this.toggleAudio();
          } else {
            this.$store.dispatch("displayGlobalAlert", {
              type: "alert",
              msg: GeneralConstant.error.hostDisabledUnmute
            });
          }
        } else {
          this.toggleAudio();
        }
      }
    }
  }
};
