/**
 * State for chat
 */
export const state = {
  /**
   * Message list
   */
  messages: [],

  /**
   * Notification list
   */
  notifications: [],

  /**
   * Chat message fetch status
   */
  chatMessageFetchStatus: "notInitiated"
};
