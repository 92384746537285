<template>
  <div id="rsvpHholder">
    <div class="header" id="header">
      <img id="logo-img" :src="logoIcon" />
    </div>
    <div class="page-body">
      <template v-if="showPageLoader">
        <div class="loader"></div>
      </template>
      <template v-else>
        <h1 class="confirm-block">{{ msg }}</h1>
      </template>
    </div>
    <div class="footer" id="copyright">
      <p>Copyright© 2023 by PSYBooks®, LLC. All Rights Reserved.</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import GeneralConstant from "../../constant/GeneralConstant";

export default {
  name: "Reply",
  data() {
    return {
      logoIcon: require("../../assets/img/app-logo.png"),
      showPageLoader: false,
      msg: ""
    };
  },
  methods: {
    ...mapActions("participant", {
      sendRsvpReply: "sendRsvpReply"
    })
  },
  async created() {
    // Verify the rsvp reply
    if (!["Confirmed", "Declined"].includes(this.$route.params.reply)) {
      this.msg = GeneralConstant.error.invalidURL;
      return false;
    }

    // Show Page Loader
    this.showPageLoader = true;

    // Verify the connect code
    const response = await this.sendRsvpReply({
      connectCode: this.$route.params.connectCode,
      rsvpReply: this.$route.params.reply
    });

    // Hide page Loader
    this.showPageLoader = false;

    // Set Message, if error response is coming
    if (response.Message) {
      if ("Invalid Connect Code present on the request" === response.Message) {
        this.msg = GeneralConstant.error.inactiveMeeting;
      } else {
        this.msg = response.Message;
      }
    } else {
      this.msg = GeneralConstant.success.rsvpSaved;
    }
  }
};
</script>

<style scoped>
#logo-img {
  max-width: 32%;
}
#rsvpHholder {
  padding: 30px;
}
.confirm-block {
  color: #fff;
  margin: 10px 0px;
  padding: 0px;
  font-size: 32px;
}
#copyright {
  position: fixed;
  bottom: 0px;
  left: 0px;
  display: block;
  width: 100%;
}
#copyright p {
  font-size: 9px;
  color: #bbb;
  text-align: center;
}
@media screen and (max-width: 768px) {
  #logo-img {
    max-width: 70%;
  }
  #rsvpHholder {
    padding: 15px;
  }
  .confirm-block {
    font-size: 25px;
  }
}
</style>
