<template>
  <div class="overlay-box">
    <!-- Overlay box -->
    <div
      ref="overlayBox"
      id="overlayBox"
      :class="{
        'wr-display-msg': true,
        'wr-host': userId === hostInfo.id,
        'wr-participant': userId !== hostInfo.id,
        'wr-expanded': !isOverlayBoxMinimized,
        'absolute-position': absolutePos,
        'relative-position': relativePos
      }"
    >
      <!-- Overlay header -->
      <ul class="wr-meeting-header" @mousedown="dragMouseDown">
        <li>
          <h4>
            <span class="user-name">
              Welcome,
              {{
                userId === hostInfo.id
                  ? `${hostInfo.name.firstName}${
                      hostInfo.name.middleName
                        ? " " + hostInfo.name.middleName
                        : ""
                    }${
                      hostInfo.name.lastName
                        ? " " + hostInfo.name.lastName
                        : hostInfo.name.lastName
                    }`
                  : `${userName.firstName}${
                      userName.middleName ? " " + userName.middleName : ""
                    }${userName.lastName ? " " + userName.lastName : ""}`
              }}
            </span>
            <span class="icon-toggle-collapse" @click="toggleOverlayBox">
              <i
                :class="{
                  bi: true,
                  'bi-arrow-up-circle-fill': !isOverlayBoxMinimized,
                  'bi-arrow-down-circle-fill': isOverlayBoxMinimized
                }"
              ></i>
            </span>
          </h4>
        </li>
      </ul>

      <!-- Overlay body -->
      <div class="contact-info">
        <div>
          <h5
            class="participant-instruction-close"
            v-if="isOverlayBoxMinimized"
          >
            You are in the waiting room. Your host will let you in soon. Click
            the arrow for more options.
          </h5>
          <h5
            class="participant-instruction-open"
            v-if="!isOverlayBoxMinimized"
          >
            You may start e chat with any one below.
          </h5>
        </div>
        <div>
          <span class="mob-number"> Contact : {{ hostInfo.contact }} </span>
          <a
            @click="openChatPanel"
            class="btn-primary-outline btn-start-chat cursorPointer"
            style="position: relative"
          >
            Start Chat
            <span class="un-read-msg-count" v-if="unreadMsgCount > 0">
              {{ unreadMsgCount > 99 ? `${unreadMsgCount}+` : unreadMsgCount }}
            </span>
          </a>
        </div>
        <div v-if="userId === hostInfo.id">
          <a
            @click="gotToMeetRoom"
            class="btn-primary btn-start-meeting cursorPointer"
          >
            Start Meeting
          </a>
        </div>
        <div>
          <span class="icon-toggle-collapse" @click="toggleOverlayBox">
            <i
              :class="{
                bi: true,
                'bi-arrow-up-circle-fill': !isOverlayBoxMinimized,
                'bi-arrow-down-circle-fill': isOverlayBoxMinimized
              }"
            ></i>
          </span>
        </div>
      </div>

      <!-- Button List -->
      <div class="wr-content">
        <ul class="av-settings">
          <li class="test-audio">
            <a
              @click="toggleSpeakerAndMicTestPopup"
              class="btn-primary-outline cursorPointer"
            >
              <i class="bi bi-volume-up"></i>
              Test Audio
            </a>
          </li>
          <li class="test-video">
            <a
              @click="toggleVideoTestPopup"
              class="btn-primary-outline cursorPointer"
            >
              <i class="bi bi-camera-video"></i>
              Test Video
            </a>
          </li>
          <li>
            <a
              @click="openChatPanel"
              class="btn-primary-outline btn-start-chat cursorPointer"
              style="position: relative"
            >
              <i class="bi bi-chat-dots"></i>
              Start Chat
              <span class="un-read-msg-count" v-if="unreadMsgCount > 0">
                {{
                  unreadMsgCount > 99 ? `${unreadMsgCount}+` : unreadMsgCount
                }}
              </span>
            </a>
          </li>
        </ul>
      </div>

      <div class="wr-msg-2" v-if="!isOverlayBoxMinimized">
        <!-- Displaying meeting scheduled time and current time -->
        <ul class="mr-timings">
          <li class="mr-sc-time">
            <h6>Scheduled Meeting Time</h6>
            <p>{{ meetingStartTime }}</p>
          </li>
          <li class="mr-current-time">
            <h6>Current Time</h6>
            <p>{{ formattedCurrentTime }}</p>
          </li>
        </ul>

        <!-- Listing participants -->
        <div class="mpl-content">
          <p class="txt-bd">Participants for the meeting</p>
          <p class="txt-xs">
            (A blue dot means the person is in the waiting room)
          </p>
          <ul class="mpl custom-scroll-v2">
            <li
              v-for="participant in scheduledParticipants"
              :key="participant.userId"
            >
              <p class="contact-info">
                <span class="mp-name">
                  <span class="user-name">
                    {{
                      `${participant.userName.firstName}${
                        participant.userName.middleName
                          ? " " + participant.userName.middleName
                          : ""
                      }${
                        participant.userName.lastName
                          ? " " + participant.userName.lastName
                          : ""
                      }`
                    }}
                  </span>
                  <em
                    v-if="participant.waitRoomSocketConn.length > 0"
                    class="blue-dot"
                  ></em>
                </span>
                <span class="ph-no"> {{ participant.userPhone }} </span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Popup for speaker testing -->
    <speaker-test-vue
      v-if="testSpeaker"
      v-on:hideSpeakerTestPopup="hideSpeakerTestPopup"
    ></speaker-test-vue>

    <!-- Popup for microphone testing -->
    <mic-test-vue v-if="testMic" v-on:hideMicTestPopup="hideMicTestPopup">
    </mic-test-vue>

    <!-- Popup for video testing -->
    <video-test-vue
      v-if="testVideo"
      v-on:hideVideoTestPopup="hideVideoTestPopup"
    ></video-test-vue>
  </div>
</template>

<script>
import MicTestVue from "./MicTest";
import SpeakerTestVue from "./SpeakerTest";
import VideoTestVue from "./VideoTest";
import { mapGetters } from "vuex";
import { checkMobile } from "../../mixins/CheckMobile";
import moment from "moment-timezone";

export default {
  name: "OverlayBox",
  mixins: [checkMobile],
  data() {
    return {
      testSpeaker: false,
      testMic: false,
      testVideo: false,
      currentTime: moment(),
      isOverlayBoxMinimized: true,
      posx: 0,
      posy: 0,
      absolutePos: true,
      relativePos: false
    };
  },
  props: {
    toggleSidebar: Function,
    isChatPannelOpened: Boolean
  },
  components: {
    MicTestVue,
    SpeakerTestVue,
    VideoTestVue
  },
  computed: {
    ...mapGetters({
      userName: "getUserName",
      userId: "getUserId",
      userTimezone: "getUserTimezone"
    }),

    ...mapGetters("meet", {
      hostInfo: "getHostInfo",
      meetDetails: "getMeetDetails",
      participantList: "getParticipantList"
    }),

    ...mapGetters("chat", {
      messages: "getMessages"
    }),

    /**
     * Computed property to get the number of unread messages.
     */
    unreadMsgCount() {
      return this.messages.filter(message => {
        return message.senderId !== this.userId && !message.isRead;
      }).length;
    },

    /**
     * Returns list of scheduled participants from participant list
     */
    scheduledParticipants() {
      return this.participantList.filter(participant => !participant.isGuest);
    },

    /**
     * Returns the modified meeting start time
     */
    meetingStartTime() {
      let timezone = this.hostInfo.timezone;
      if (this.userTimezone) {
        timezone = this.userTimezone;
      }

      const scheduledTime = moment
        .utc(this.meetDetails.meetScheduledTime, null)
        .tz(timezone);

      const currentTime = moment().tz(timezone);

      if (0 === currentTime.diff(scheduledTime, "days")) {
        return scheduledTime.format("hh:mm A");
      } else {
        return scheduledTime.format("MM-YYYY-DD hh:mm A");
      }
    },

    /**
     * Returns the formatted current time
     */
    formattedCurrentTime() {
      let timezone = this.hostInfo.timezone;
      if (this.userTimezone) {
        timezone = this.userTimezone;
      }

      return moment(this.currentTime, null)
        .tz(timezone)
        .format("hh:mm A");
    }
  },
  watch: {
    /**
     * Watchers to check if chat panel opened/closed and based on that change the position of overlay box.
     */
    isChatPannelOpened: function(val) {
      if (val) {
        this.$refs.overlayBox.style.top = "10px";
        this.$refs.overlayBox.style.left = "10px";
        this.absolutePos = false;
        this.relativePos = true;
      } else {
        this.$refs.overlayBox.style.top =
          window.innerWidth > 768 ? "10px" : "0px";
        this.$refs.overlayBox.style.left =
          window.innerWidth > 768 ? "10px" : "0px";
        this.absolutePos = true;
        this.relativePos = false;
      }
    }
  },
  methods: {
    /**
     * Method to toggle overlay box
     */
    toggleOverlayBox() {
      this.isOverlayBoxMinimized = !this.isOverlayBoxMinimized;
    },

    /**
     * Function to enter in to meeting room
     */
    gotToMeetRoom() {
      // Construct the meet room url
      let url = `${window.location.origin}/${this.$route.params.meetCode}/${this.$route.params.passCode}?room=meetRoom`;

      // Redirect to meet room
      window.location.href = url;
    },

    /**
     * Function to show pop-up for speaker and microphone test
     */
    toggleSpeakerAndMicTestPopup() {
      if (this.testMic || this.testSpeaker) {
        this.$refs.overlayBox.style.top = "10px";
        this.$refs.overlayBox.style.left = "10px";
        this.absolutePos = true;
        this.relativePos = false;
        this.testMic = false;
        this.testSpeaker = false;
      } else {
        this.absolutePos = false;
        this.relativePos = true;
        this.toggleSidebar({ clickEvent: "chat", isClose: true });
        this.testVideo = false;
        this.testSpeaker = true;
        this.testMic = true;
      }
    },

    /**
     * Function to show pop-up for video test
     */
    toggleVideoTestPopup() {
      if (this.testVideo) {
        this.$refs.overlayBox.style.top = "10px";
        this.$refs.overlayBox.style.left = "10px";
        this.absolutePos = true;
        this.relativePos = false;
        this.testVideo = false;
      } else {
        this.absolutePos = false;
        this.relativePos = true;
        this.toggleSidebar({ clickEvent: "chat", isClose: true });
        this.testSpeaker = false;
        this.testMic = false;
        this.testVideo = true;
      }
    },

    /**
     * Function to hide pop-up for speaker test
     */
    hideSpeakerTestPopup() {
      if (!this.testMic) {
        this.$refs.overlayBox.style.top = "10px";
        this.$refs.overlayBox.style.left = "10px";
        this.absolutePos = true;
        this.relativePos = false;
      }
      this.testSpeaker = false;
    },

    /**
     * Function to hide pop-up for video test
     */
    hideVideoTestPopup() {
      this.$refs.overlayBox.style.top = "10px";
      this.$refs.overlayBox.style.left = "10px";
      this.absolutePos = true;
      this.relativePos = false;
      this.testVideo = false;
    },

    /**
     * Function to hide pop-up for microphone test
     */
    hideMicTestPopup() {
      if (!this.testSpeaker) {
        this.$refs.overlayBox.style.top = "10px";
        this.$refs.overlayBox.style.left = "10px";
        this.absolutePos = true;
        this.relativePos = false;
      }
      this.testMic = false;
    },

    /**
     * Function to open chat panel
     */
    openChatPanel() {
      this.testVideo = false;
      this.testSpeaker = false;
      this.testMic = false;
      this.toggleSidebar({
        clickEvent: "chat"
      });
    },

    /**
     * Dragging the mouse.
     */
    dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();

      // Get the mouse cursor position at startup:
      this.posx = e.clientX;
      this.posy = e.clientY;

      // Call a function upon mouse release
      document.onmouseup = this.closeDragElement;

      // Call a function whenever the mouse moves
      document.onmousemove = this.elementDrag;
    },

    /**
     * Drag the element on mouse drag
     */
    elementDrag(e) {
      e = e || window.event;
      e.preventDefault();

      // calculate the new cursor position:
      const posx = this.posx - e.clientX;
      const posy = this.posy - e.clientY;
      this.posx = e.clientX;
      this.posy = e.clientY;

      // set the element's new position:
      const elmnt = this.$refs.overlayBox;
      elmnt.style.top = elmnt.offsetTop - posy + "px";
      elmnt.style.left = elmnt.offsetLeft - posx + "px";
    },

    /**
     * Remove all event listener when mouse is released
     */
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }
  },
  created() {
    // Running a real time clock
    setInterval(() => {
      // Get current date and time in the specified format
      this.currentTime = moment();
    }, 1000);
  }
};
</script>

<style scoped>
.wr-display-msg {
  z-index: 3;
  background: #0a0a0a;
  border: 2px solid #000;
  padding: 0 15px 10px;
  border-radius: 5px;
  max-height: 120px;
  overflow: hidden;
  width: 40%;
  margin-right: 1%;
}
.absolute-position {
  position: absolute;
  top: 10px;
  left: 10px;
}
.relative-position {
  top: 20px !important;
  left: 0px !important;
  position: relative;
}
.wr-display-msg.wr-expanded {
  max-height: 100%;
  overflow: visible;
  height: fit-content;
}
.wr-msg-2 p {
  color: #fff;
  font-size: 14px;
  margin: 0 0 5px 0;
}
.un-read-msg-count {
  background: #468cc6;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  top: -14px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
}

em.blue-dot {
  background: #468cc6;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  display: inline-block;
  position: relative;
  top: -10px;
  left: 5px;
  font-style: normal;
}
.wr-msg-2 {
  padding: 0 0 10px 0;
}
.wr-msg-2 p {
  font-size: 14px;
  color: #fff;
  margin: 0 0 10px 0;
  text-align: left;
}
.mr-timings {
  padding: 15px;
  margin: 15px 0 10px 0;
  list-style: none;
  color: #fff;
  background: #333;
  border-radius: 5px;
  display: flex;
}
.mr-timings li {
  display: inline-block;
}
.mr-timings li p {
  margin-bottom: 0;
}
.mr-timings li.mr-sc-time {
  width: 60%;
}
.mr-timings li.mr-current-time {
  width: 40%;
}
.mr-timings h6 {
  color: #eeb747;
  font-weight: bold;
  font-size: 14px;
  margin: 0;
}
.wr-meeting-header {
  cursor: move;
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
}
.wr-meeting-header h4 {
  color: #fff;
  margin: 10px 0 5px;
  border-bottom: 1px solid #333;
  padding-bottom: 5px;
  position: relative;
  white-space: nowrap;
}
.wr-meeting-header h4 span.icon-toggle-collapse {
  position: absolute;
  right: 0;
  cursor: pointer;
}
.wr-meeting-header li {
  display: block;
}
.wr-meeting-header li:last-child {
  margin-top: 10px;
}
.user-name {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 25px);
}
.contact-info {
  color: #fff;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wr-host .contact-info div:nth-child(1) {
  width: 0px;
  display: none;
}
.wr-host .contact-info div:nth-child(2) {
  width: 49%;
}
.wr-host .contact-info div:nth-child(3) {
  width: 49%;
}
.wr-host .contact-info div:nth-child(4) {
  width: 0px;
  display: none;
}
.wr-participant .contact-info div:nth-child(1) {
  width: 100%;
}
.wr-participant .contact-info div:nth-child(2) {
  width: 0px;
  display: none;
}
.wr-participant .contact-info div:nth-child(3) {
  width: 0px;
  display: none;
}
.contact-info span {
  color: #eeb747;
}
.wr-host .contact-info .participant-instruction-close,
.wr-host .contact-info .participant-instruction-open {
  display: none;
}
.participant-instruction-close,
.participant-instruction-open {
  margin: 0px;
  opacity: 0.8;
}
.participant-header {
  position: relative;
}
.participant-header span.icon-toggle-collapse {
  position: absolute;
  right: 0;
  cursor: pointer;
}
.wr-content,
.mpl-content {
  display: block;
  color: #fff;
  font-size: 14px;
  clear: both;
  margin: 10px 0;
  padding: 0;
}
.mpl-content {
  padding: 15px;
  border-bottom: 0;
  margin: 20px 0 0 0;
  background: #222;
  border-radius: 5px;
}
.mpl-content .mpl {
  list-style: none;
  padding: 0;
  margin: 20px auto 10px;
  width: 98%;
  max-height: 25vh;
  overflow: auto;
}
.mpl-content .mpl li {
  display: block;
  width: auto;
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
  border-bottom: 1px solid #2c2c2c;
}
.mpl-content .mpl li p {
  margin: 0;
}
.mpl-content p.txt-xs {
  color: #bbb;
  margin-top: 0px;
}
.mpl-content p.txt-bd {
  font-size: 16px !important;
  margin-bottom: 0;
}
.wr-content span.txt-highlight-pri {
  color: #d26d13 !important;
}
.mpl-content .mpl li span.mp-name {
  /* margin-right: 15px; */
  display: inline-block;
  width: calc(100% - 120px);
}
.mpl-content .mpl li span.mp-name .user-name {
  width: auto;
  max-width: 95%;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mpl-content .mpl li span.ph-no {
  float: right;
  color: #eeb747 !important;
  width: 110px;
}
.av-settings {
  list-style: none;
  padding: 0 0 5px 0;
  display: flex;
  justify-content: space-between;
}
.av-settings li {
  display: inline-block;
  text-align: center;
}
.av-settings li a {
  display: block;
  margin: 0 4px 0 0px;
  padding: 5px 0;
  width: 100%;
}
.av-settings li:first-child:nth-last-child(3),
.av-settings li:first-child:nth-last-child(3) ~ li {
  width: 31%;
}
.btn-start-chat {
  margin-right: 0;
}
.btn-start-meeting {
  margin: 0px auto 0 !important;
  font-size: 15px;
  display: block;
}
.contact-info div a {
  padding: 0px 15px;
}
.btn-primary-outline i {
  font-size: 16px;
  padding-right: 5px;
  position: relative;
  top: 1px;
  line-height: 22px;
}
.wr-host .contact-info div .mob-number {
  display: block;
}
.wr-host .contact-info div .btn-start-chat {
  display: none;
}
.contact-info div .icon-toggle-collapse {
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}
.wr-meeting-header .icon-toggle-collapse {
  visibility: visible;
}
@media screen and (max-width: 768px),
  screen and (orientation: landscape) and (max-height: 425px) {
  .absolute-position {
    position: fixed;
    top: 0px;
    left: 0px;
  }
  .wr-display-msg {
    width: 100%;
    margin: 0 auto;
    padding: 0 0px 5px;
    max-height: 125px;
    border-radius: 0px;
  }
  .wr-meeting-header {
    padding: 0px 8px;
  }
  .wr-meeting-header li {
    margin-top: 0px !important;
  }
  .wr-meeting-header h4 {
    margin: 5px 0 10px;
    padding-bottom: 0px;
  }
  .wr-content {
    display: none;
  }
  .contact-info {
    padding: 0px 8px;
  }
  .wr-host .contact-info div:nth-child(2) {
    width: calc(48% - 8px);
  }
  .wr-host .contact-info div .mob-number {
    display: none;
  }
  .wr-host .contact-info div .btn-start-chat {
    display: block;
  }
  .wr-host .contact-info div:nth-child(3) {
    width: calc(48% - 8px);
  }
  .wr-host .contact-info div:nth-child(4) {
    width: 16px;
    display: block;
  }
  .wr-participant .contact-info div:nth-child(1) {
    width: calc(100% - 16px);
  }
  .wr-expanded.wr-participant .contact-info div:nth-child(1) {
    width: calc(100% - 131px);
  }
  .wr-expanded.wr-participant .contact-info div:nth-child(2) {
    width: 115px;
    display: block;
  }
  .wr-expanded.wr-participant .contact-info div:nth-child(2) .mob-number {
    display: none;
  }
  .wr-participant .contact-info div:nth-child(3) {
    width: 16px;
    display: block;
  }
  .contact-info div .icon-toggle-collapse {
    visibility: visible;
    width: fit-content;
  }
  .wr-meeting-header .icon-toggle-collapse {
    visibility: hidden;
  }
  .user-name {
    width: 100%;
  }
  .mr-timings {
    border-radius: 0px;
  }
  .mpl-content {
    border-radius: 0px;
  }
  .contact-info div .btn-start-chat {
    text-align: center;
    font-size: 15px;
  }
  .un-read-msg-count {
    top: -14px;
    right: -5px;
    color: #fff !important;
  }
}
@media screen and (orientation: landscape) and (max-height: 425px) {
  .contact-info {
    justify-content: flex-end;
  }
  .wr-host .contact-info div:nth-child(2) {
    width: auto;
    margin-right: 1%;
  }
  .wr-host .contact-info div:nth-child(3) {
    width: auto;
    margin-right: 1%;
  }
  .wr-host .contact-info div .btn-start-chat {
    display: inline-block;
  }
  .contact-info div .btn-start-meeting {
    display: inline-block;
  }
  .wr-msg-2 {
    padding: 0px 0px 0px 0px;
  }
  .mr-timings {
    display: none;
  }
}
@media screen and (max-width: 414px) {
  .mpl-content .mpl {
    /* max-height: 110px; */
    max-height: 165px;
  }
}
</style>
