import socket from "../../socket/socket";
import { mapGetters, mapMutations } from "vuex";
import { sanitizeAndFormat } from "../sanitizeAndFormat";
import { bus } from "../../main";

export const chatEventListener = {
  data() {
    return {};
  },
  mixins: [sanitizeAndFormat],
  computed: {
    ...mapGetters({
      userId: "getUserId"
    }),

    ...mapGetters("chat", {
      messages: "getMessages",
      notifications: "getNotifications"
    })
  },
  methods: {
    ...mapMutations("chat", {
      setMessages: "setMessages",
      setNotifications: "setNotifications"
    }),

    /**
     * Event listener for listening to chat event
     */
    listenToChatEvent() {
      // Listener on receiving text messages
      socket.io.on("textMsg", data => {
        // Adding message to list of messages
        this.setMessages([
          ...this.messages,
          { ...data, message: data.message }
        ]);

        // If chat panel is opened, Scroll to bottom of list
        if (this.isChatPannelOpened) {
          bus.$emit("scrollChatMessagesToBottom", "receiveMessage", true);
        } else {
          // If chat panel is closed, mark as unread message exist
          if (data.senderId !== this.userId) {
            this.setNotifications([
              ...this.notifications,
              { ...data, arrivalTime: new Date() }
            ]);
            if (this.notifications.length > 2) {
              this.disappearChatAutomatically();
            }
          }
        }
      });

      setInterval(() => {
        if (this.notifications.length <= 2) {
          for (let i = 0; i < this.notifications.length; i++) {
            if (
              (new Date().getTime() -
                new Date(this.notifications[i].arrivalTime).getTime()) /
                1000 >
              20
            ) {
              this.disappearChatAutomatically(i);
            }
          }
        }
      }, 1000);
    },

    /**
     * Function to dissappear chat messages
     */
    disappearChatAutomatically(pos = 0) {
      const newNotifications = [...this.notifications];
      newNotifications.splice(pos, 1);
      this.setNotifications(newNotifications);
    }
  }
};
