<template>
  <div class="dropdown" @click="preventClickAction">
    <a class="close cursorPointer" @click="toggleViewPopup">
      <i data-v-c77ad274="" class="bi bi-x"></i>
    </a>
    <ul>
      <li>
        <a class="cursorPointer" @click="switchView('gallery')">
          <span class="icon-tick" v-if="'gallery' === displayView">
            <i class="bi bi-check2"></i>
          </span>
          <span class="list-option"> Gallery View </span>
          <i class="bi-grid-3x3-gap-fill"></i>
        </a>
      </li>
      <li>
        <a class="cursorPointer" @click="switchView('speaker')">
          <span class="icon-tick" v-if="'speaker' === displayView">
            <i class="bi bi-check2"></i>
          </span>
          <span class="list-option"> Speaker View </span>
          <i class="bi-menu-app-fill"></i>
        </a>
      </li>
      <li v-if="meetDetails.hideSelfView">
        <a class="cursorPointer" @click="showSelfView">
          <span class="icon-tick" v-if="!meetDetails.hideSelfView">
            <i class="bi bi-check2"></i>
          </span>
          <span class="list-option"> Show Self View </span>
          <i class="bi bi-display"></i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SwitchView",
  data() {
    return {};
  },
  mixins: [],
  props: {
    toggleViewPopup: Function
  },
  computed: {
    ...mapGetters("meet", {
      displayView: "getDisplayView",
      meetDetails: "getMeetDetails"
    })
  },
  methods: {
    ...mapMutations("meet", {
      setDisplayView: "setDisplayView",
      setMeetDetails: "setMeetDetails",
      setPinnedPeerId: "setPinnedPeerId"
    }),

    /**
     * Function to switch views(Gallery View/ Speaker View)
     */
    switchView(view) {
      this.setPinnedPeerId(null);
      this.setDisplayView(view);
      this.toggleViewPopup();
    },

    /**
     * Function to show user self view, if hidden.
     */
    showSelfView() {
      this.setMeetDetails({
        ...this.meetDetails,
        hideSelfView: false
      });

      this.toggleViewPopup();
    },

    /**
     * Prevents click action
     */
    preventClickAction(event) {
      event.stopPropagation();
    }
  }
};
</script>

<style scoped>
/* view pop-up css start */
div.dropdown {
  z-index: 99990;
  background-color: #282828;
  position: absolute;
  border: 1px solid #0d0d0d;
  border-radius: 5px;
  top: 30px;
  right: -15px;
  width: 180px;
}

div.dropdown ul {
  margin: 0px;
  padding: 0px;
  margin-top: 10%;
}

div.dropdown li {
  position: relative;
}

span.icon-tick {
  position: absolute;
  left: 0px;
  top: 0px;
}

a.close {
  position: absolute;
  right: 7px;
  color: #fff;
  top: 0px;
}

div.dropdown ul li {
  list-style: none;
  margin: 10px;
  padding-right: 30px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
}

div.dropdown ul li img {
  height: 20px;
  margin-right: 10px;
}

div.dropdown ul li a {
  font-size: 13px;
  color: #e6e6e6;
  font-weight: normal;
}

div.dropdown ul li a span.list-option {
  margin-left: 33px !important;
}

div.dropdown ul li:not(:last-of-type) {
  border-bottom: 1px solid #333;
  padding-bottom: 13px;
}

div.dropdown ul li:last-of-type {
  /* margin-bottom: 4px; */
}

div.dropdown ul li a i {
  position: absolute;
  right: 16px;
  top: 0;
}
div.dropdown ul li a span.icon-tick {
  left: 35px;
}

/* view pop-up css end */
</style>
