<template>
  <div @click="preventClickAction">
    <div class="common-modal" id="modal-id" :style="modalStyle">
      <div class="modal-container">
        <div class="modal-header" v-if="modal.title">
          <h4>
            {{ modal.title }}
          </h4>
          <a class="icon-close cursorPointer" @click="closeModal">
            <i class="bi bi-x"></i>
          </a>
        </div>

        <div class="modal-body">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "Modal",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      modal: "getModal"
    }),

    /**
     * Modal style to be overridden
     */
    modalStyle() {
      let style = "";
      for (const key in this.modal.styleOverride) {
        style = `${style} ${key}: ${this.modal.styleOverride[key]};`;
      }

      return style;
    }
  },
  methods: {
    ...mapMutations({
      setModal: "setModal"
    }),

    /**
     * Function to close the modal
     */
    closeModal() {
      this.setModal({
        ...this.modal,
        display: false
      });
    },

    /**
     * Prevents click action
     */
    preventClickAction(event) {
      event.stopPropagation();
    }
  }
};
</script>

<style scoped>
/* #modal-id {
  left: 50%;
  max-width: 480px;
  width: 100%;
  right: 50%;
  margin-left: -245px;
  top: 15%;
} */
/* Above commented style was there earlier. It was used as a generic style for all modla display */
/* #modal-id {
  left: 50%;
  margin-left: -240px;
  max-width: 480px;
  width: 100%;
  top: 50px;
} */
/* Above commented style was only used for displaying invite participant modal. */
/* The avove two styles are commented now and below style is used as common style for all modal. 
https://thoughtbot.com/blog/positioning#position
*/
#modal-id {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 12%;
  max-width: 480px;
  width: 100%;
}
#modal-id .modal-body {
  padding: 0 !important;
}
#modal-id .modal-header {
  border-bottom: 1px solid #222;
  padding-bottom: 10px;
}
#modal-id .modal-header h4 {
  margin-bottom: 0;
}
.common-modal.modal-header {
  border-bottom: 1px solid #222;
}

@media (max-width: 540px) {
  /* #modal-id {
    width: 80%;
    margin-left: auto;
    max-width: 100%;
    left: 7%;
    right: auto;
  } */
  /* Above commented style was there earlier. It was used as a generic style for all modla display */
  /* #modal-id {
    left: 50%;
    margin-left: -162px !important;
    max-width: 300px;
    width: 100%;
  } */
  /* Above commented style was only used for displaying invite participant modal. */
  /* The avove two styles are commented now and below style is used as common style for all modal. */
  #modal-id {
    top: 7% !important;
    width: 80% !important;
    max-width: 100% !important;
  }
}

/* For Showing invite participant popup on landscape mode  */
@media screen and (orientation: landscape) and (max-height: 425px) {
  #modal-id {
    position: fixed;
    top: 0% !important;
    max-width: 100% !important;
    width: 99.8% !important;
    padding: 0px !important;
    height: -webkit-fill-available;
    z-index: 999;
  }
  .unsetHeight {
    height: unset !important;
  }
  #modal-id .modal-body {
    height: calc(100vh - 125px);
    overflow-y: scroll;
  }
  #modal-id .modal-body::-webkit-scrollbar {
    display: none;
  }
}
</style>
