var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'full-panel': true,
    'wait-room': 'waitRoom' === _vm.meetDetails.room
  },attrs:{"id":"chat-container"}},[_c('div',{staticClass:"sideblock-container"},[_c('div',{staticClass:"side-topbar"},[_c('h4',[_vm._v("Chat")]),_c('div',{staticClass:"side-topbar-dropdown"},[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){return _vm.toggleSidebar({ clickEvent: 'chat', isClose: true })}}},[_c('i',{staticClass:"bi bi-x icon-dropdown icon-sidepanel-close"})])])]),_c('div',{staticClass:"side-topbar-content"},[_c('Message-list-vue',{attrs:{"toggle-dropdown":_vm.toggleDropdown}}),(_vm.showGoDown)?_c('div',{class:{
          'wait-room': 'waitRoom' === _vm.meetDetails.room
        },attrs:{"id":"unread-chat-con"},on:{"click":_vm.scrollChatMessagesToBottom}},[_c('a',{staticClass:"icon-msg-scroll cursorPointer"}),(_vm.unreadMsgCount > 0)?_c('span',{staticClass:"msg-count"},[_vm._v(" "+_vm._s(_vm.unreadMsgCount)+" ")]):_vm._e()]):_vm._e(),_c('div',{class:{
          'chat-disabled': !_vm.meetDetails.enableChat && _vm.userId !== _vm.hostInfo.id
        },attrs:{"id":"chat-content"}},[(_vm.userId !== _vm.hostInfo.id && !_vm.meetDetails.enableChat)?_c('span',{attrs:{"id":"chat-warning"}},[_vm._v(" Sorry! The host has disabled the chat. ")]):[_c('div',{staticClass:"chat-header"},[_c('div',{staticClass:"cls"},[_c('div',{staticClass:"to"},[_vm._v(" To: "),_c('div',{staticClass:"custom-select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.receiverId),expression:"receiverId"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.receiverId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{staticClass:"bold",attrs:{"value":"all"}},[_vm._v(" Everyone ")]),_vm._l((_vm.chatUsers),function(user){return _c('option',{key:user.userId,domProps:{"value":user.userId,"innerHTML":_vm._s(
                        ((user.userName.firstName) + " " + (user.userName.lastName))
                      )}})})],2)])])]),_c('div',{staticClass:"crs"}),_c('div',{staticClass:"clear"})]),_c('div',{staticClass:"chat-footer"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.composeMessage),expression:"composeMessage"}],ref:"chatInputBox",attrs:{"type":"text","placeholder":"Type message here..."},domProps:{"value":(_vm.composeMessage)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendMessage.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.composeMessage=$event.target.value}}}),_c('a',{staticClass:"enter-chat cursorPointer",on:{"click":function($event){$event.preventDefault();return _vm.sendMessage.apply(null, arguments)}}},[_c('i',{staticClass:"bi bi-arrow-right"})])])]],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }