<template>
  <div>
    Modal Body
  </div>
</template>

<script>
export default {
  name: "Modal-Body",
  data() {
    return {};
  }
};
</script>
