import { mapGetters, mapMutations, mapActions } from "vuex";
import { sanitizeAndFormat } from "./sanitizeAndFormat";
import socket from "../socket/socket";
import moment from "moment-timezone";
import GeneralConstant from "../constant/GeneralConstant";

export const record = {
  data() {
    return {};
  },
  mixins: [sanitizeAndFormat],
  computed: {
    ...mapGetters({
      modal: "getModal"
    }),

    ...mapGetters("meet", {
      audioTrack: "getAudioTrack",
      peerConnections: "getPeerConnections",
      meetDetails: "getMeetDetails"
    }),

    ...mapGetters("record", {
      composedStream: "getComposedStream",
      audioContext: "getAudioContext",
      recordingState: "getRecordingState",
      audioDestinationNode: "getAudioDestinationNode",
      mediaRecorder: "getMediaRecorder",
      recordedScreenStream: "getRecordedScreenStream",
      uploadPercentage: "getUploadPercentage",
      recordedChunks: "getRecordedChunks",
      recordFileUploadStatus: "getRecordFileUploadStatus",
      recordedFileName: "getRecordedFileName",
      isRecordFileDownloading: "getIsRecordFileDownloading",
      firstTimeRecord: "getFirstTimeRecord"
    })
  },
  methods: {
    ...mapMutations({
      setModal: "setModal",
      setDisplayModalOverlay: "setDisplayModalOverlay"
    }),

    ...mapMutations("meet", {
      setMeetDetails: "setMeetDetails"
    }),

    ...mapMutations("record", {
      setComposedStream: "setComposedStream",
      setAudioContext: "setAudioContext",
      setRecordingState: "setRecordingState",
      setAudioDestinationNode: "setAudioDestinationNode",
      setMediaRecorder: "setMediaRecorder",
      setRecordedChunks: "setRecordedChunks",
      setRecordedScreenStream: "setRecordedScreenStream",
      setRecordFileUploadStatus: "setRecordFileUploadStatus",
      setIsRecordFileDownloading: "setIsRecordFileDownloading",
      setFileId: "setFileId",
      setFirstTimeRecord: "setFirstTimeRecord",
      setUploadPercentage: "setUploadPercentage"
    }),

    ...mapActions("record", {
      uploadRecordingFile: "uploadRecordingFile",
      downloadRecordingFile: "downloadRecordingFile",
      getRecordUploadPostPolicy: "getRecordUploadPostPolicy",
      updateUploadStatus: "updateUploadStatus"
    }),

    /**
     * Function to start recording the user screen
     */
    async startRecording(showConfirmPopupForPrevRecord = false) {
      try {
        // Show confirm record popup
        if (showConfirmPopupForPrevRecord && this.recordedChunks.length > 0) {
          this.setModal({
            ...this.modal,
            display: true,
            title: "Record Again",
            bodyComponentName: "new-record-popup-vue",
            styleOverride: {}
          });
          this.setDisplayModalOverlay(true);
          return false;
        }

        // Reset the record chunks
        this.setRecordedChunks([]);

        // Sets file download link as blank
        this.setFileId(0);

        // Get stream from screen
        let stream = null;
        if (this.recordedScreenStream && this.recordedScreenStream.active) {
          stream = this.recordedScreenStream;
        } else {
          stream = await navigator.mediaDevices.getDisplayMedia({
            video: {
              frameRate: { ideal: 10, max: 15 },
              width: 1280, // { max: 1920 }
              height: 720 // { max: 1280 },
            },
            audio: false,
            preferCurrentTab: true
          });

          // Sets stream from screen
          this.setRecordedScreenStream(stream);
        }

        // Change recording state
        this.setRecordingState("recording");

        // Send record status to server
        socket.io.emit("hostControl", {
          key: "recordStatus",
          value: true
        });

        // Set reocrding on on meet state
        this.setMeetDetails({
          ...this.meetDetails,
          recordStatus: true
        });

        // Set Composed Stream as a new media stream
        this.setComposedStream(new MediaStream());

        // Add screen video track on the composed stream
        stream.getVideoTracks().forEach(videoTrack => {
          this.composedStream.addTrack(videoTrack);
        });

        // Creating Audio Context
        this.setAudioContext(new AudioContext());

        // Set audio destination node on the Audio Context
        this.setAudioDestinationNode(
          this.audioContext.createMediaStreamDestination()
        );

        // Creating MediaStream Source
        const sourceNode = this.audioContext.createMediaStreamSource(
          new MediaStream([this.audioTrack])
        );
        const gainNode = this.audioContext.createGain();
        gainNode.value = 1.0;
        sourceNode.connect(gainNode).connect(this.audioDestinationNode);

        for (let id in this.peerConnections) {
          let peerSourceNode = this.audioContext.createMediaStreamSource(
            this.peerConnections[id].stream
          );
          let peerGgainNode = this.audioContext.createGain();
          peerGgainNode.value = 1.0;
          peerSourceNode
            .connect(peerGgainNode)
            .connect(this.audioDestinationNode);
        }

        // Add audio track from destination node to composed stream
        this.audioDestinationNode.stream
          .getAudioTracks()
          .forEach(audioTrack => {
            this.composedStream.addTrack(audioTrack);
          });

        // Initializing MediaRecorder
        this.setMediaRecorder(
          new MediaRecorder(this.composedStream, {
            audioBitsPerSecond: 90000, // Default was 128000
            videoBitsPerSecond: 500000 // Default was 2500000
            // mimeType: "video/webm"
          })
        );

        // Add MediaRecorder event listeners for stop event
        this.mediaRecorder.onstop = () => {
          // Change recording state
          this.setRecordingState("idle");

          // Reset all variable
          this.setMediaRecorder(null);
          this.setRecordedScreenStream(null);
          this.setAudioContext(null);
          this.setAudioDestinationNode(null);
        };

        // Add MediaRecorder event listeners for pause event
        this.mediaRecorder.onpause = () => {
          this.setRecordingState("paused");
        };

        // Add MediaRecorder event listeners for resume event
        this.mediaRecorder.onresume = () => {
          this.setRecordingState("recording");
        };

        // Push the video chunk to array on data available
        this.mediaRecorder.ondataavailable = e => {
          this.setRecordedChunks([...this.recordedChunks, e.data]);
        };

        // Start Recording
        this.mediaRecorder.start(2);

        // Add event listener
        stream.getTracks()[0].onended = this.stopRecording;

        // Show hint tips for first time recording
        if (!this.firstTimeRecord) {
          this.setModal({
            ...this.modal,
            display: true,
            title: "Recording",
            bodyComponentName: "record-first-time-vue",
            styleOverride: {}
          });
          this.setDisplayModalOverlay(true);
          this.setFirstTimeRecord(true);
        }
      } catch (err) {
        console.error(`Error: ${err}`);
      }

      // navigator.mediaDevices
      //   .getDisplayMedia({
      //     video: {
      //       frameRate: { ideal: 10, max: 15 },
      //       width: 1280, // { max: 1920 }
      //       height: 720 // { max: 1280 }
      //     },
      //     audio: true
      //   })
      //   .then(stream => {})
      //   .catch(error => {});
    },

    /**
     * Function to stop the recording
     */
    stopRecording() {
      // Stop the record stream
      if (this.recordedScreenStream) {
        const tracks = this.recordedScreenStream.getTracks();
        for (let i = 0; i < tracks.length; i++) {
          tracks[i].stop();
        }
      }

      // Stop media recorder
      if (this.mediaRecorder) {
        if ("inactive" !== this.mediaRecorder.state) {
          this.mediaRecorder.stop();
        }
      }

      // Send record status to server
      socket.io.emit("hostControl", {
        key: "recordStatus",
        value: false
      });

      // Set reocrding off, on meet state
      this.setMeetDetails({
        ...this.meetDetails,
        recordStatus: false
      });

      // Hide record for first time modal, if it is opened.
      if (
        this.modal.display &&
        "record-first-time-vue" === this.modal.bodyComponentName
      ) {
        this.setModal({
          ...this.modal,
          display: false
        });
      }
    },

    /**
     * Rset recording state
     */
    resetRecordingState() {
      // Reset all variable
      this.setRecordedChunks([]);
      this.setRecordFileUploadStatus("not-uploaded");
      this.setRecordingState("idle");
      this.setMediaRecorder(null);
      this.setRecordedScreenStream(null);
      this.setAudioContext(null);
      this.setAudioDestinationNode(null);
    },

    /**
     * Function to pause recording
     */
    pausePlayRecording() {
      if ("recording" === this.recordingState) {
        this.mediaRecorder.pause();
      } else if ("paused" === this.recordingState) {
        this.mediaRecorder.resume();
      }
    },

    /**
     * Downloads the recorded video to local file system
     */
    /* downloadScreenRecording() {
      // Create a blob object
      const blob = new Blob(this.recordedChunks, { type: "video/mp4" });
      const videoURL = window.URL.createObjectURL(blob);

      // Download the blob object in form of file
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = videoURL;
      a.download = "abc.mp4";
      a.click();
      window.URL.revokeObjectURL(videoURL);
    }, */

    /**
     * Uploads recording to server
     *
     * @param {Array} clientIds
     * @param {Array} portalUserIds
     * @param {Array} showOnreportPage
     */
    async uploadRecording({ clientIds, portalUserIds, showOnreportPage }) {
      // Upload the recorded file
      if ("uploading" !== this.recordFileUploadStatus) {
        // Setting record file uploading flag to true
        this.setRecordFileUploadStatus("uploading");

        // Setting file downloadable link as blank
        this.setFileId(0);

        // Create the blob
        const blob = new Blob(this.recordedChunks, { type: "video/mp4" });

        // Create the file
        let file = new File([blob], this.recordedFileName);

        // Get policy to upload file
        let policyRes = await this.getRecordUploadPostPolicy({
          clientIds,
          showOnreportPage,
          portalUserIds,
          timezone: moment.tz.guess(),
          fileSize: file.size,
          fileName: this.recordedFileName
        });

        if (
          "0" !== policyRes.reasonCode ||
          (policyRes.policyDetails && policyRes.policyDetails.error)
        ) {
          // Show error messages, if any
          let errorMsg = "";
          if ("0" !== policyRes.reasonCode) {
            errorMsg = policyRes.error.message;
          } else {
            errorMsg = policyRes.policyDetails.msg;
          }

          this.$store.dispatch("displayGlobalAlert", {
            msg: errorMsg,
            type: "alert"
          });

          // Set record fle upload status on vuex
          this.setRecordFileUploadStatus("not-uploaded");
        } else {
          policyRes = policyRes.policyDetails;

          // Create the data
          let data = new FormData();
          data.append("acl", "public-read");
          data.append("success_action_status", "201");
          data.append("policy", policyRes.base64policy);
          data.append("X-amz-credential", policyRes.credentials);
          data.append("X-amz-algorithm", "AWS4-HMAC-SHA256");
          data.append("X-amz-date", policyRes.policyCreatedAt);
          data.append("X-amz-expires", policyRes.expiryTimeInSec);
          data.append("X-amz-signature", policyRes.signature);
          data.append("key", policyRes.filePath);
          // data.append("Content-Type", "video/mp4");
          data.append("file", file, this.recordedFileName);

          // Upload the file to aws server
          const fileUploadres = await this.uploadRecordingFile({
            formData: data,
            url: policyRes.uploadUrl
          });
          if (201 !== fileUploadres.status) {
            // Sets file upload status
            this.setRecordFileUploadStatus("not-uploaded");

            // Show error, if any
            this.$store.dispatch("displayGlobalAlert", {
              msg: GeneralConstant.error.errInAwsFileUpload,
              type: "alert"
            });
          } else {
            // Setting uploading percentage
            this.setUploadPercentage(0);

            // Get policy to upload file
            await this.updateUploadStatus({
              recordId: policyRes.fileId
            });

            // Sets file Id
            this.setFileId(policyRes.fileId);

            // Reset the record chunks
            this.setRecordedChunks([]);

            // Sets file upload status
            this.setRecordFileUploadStatus("uploaded");

            // Show the alert message
            this.$store.dispatch("displayGlobalAlert", {
              msg: GeneralConstant.success.fileUploadSuccess,
              type: "success"
            });
          }
        }
      }
    },

    /**
     * Function to download the recently uploaded recording
     */
    async downloadRecording() {
      if (!this.isRecordFileDownloading) {
        this.setIsRecordFileDownloading(true);
        let response = await this.downloadRecordingFile();
        let urlCreator = window.URL || window.webkitURL;
        let videoUrl = urlCreator.createObjectURL(response);
        let tag = document.createElement("a");
        tag.href = videoUrl;
        tag.target = "_blank";
        tag.download = this.recordedFileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
        this.setIsRecordFileDownloading(false);
      }
    }
  }
};
