/**
 * State for participant
 */
export const state = {
  /**
   * Flag to determine if send invitation request is still pending
   */
  isSendInvitationRequestPending: false,

  /**
   * user List
   */
  userList: [],

  /**
   * user list fetched statuses.
   */
  userListFetchStatus: "notInitiated" // Possible Values: notInitiated/pending/success
};
