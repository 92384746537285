<template>
  <div id="security-options" class="cb-dropdown" @click="preventClickAction">
    <h6>
      Settings
      <a
        class="icon-mobsettings-close cursorPointer"
        @click="closeSettingsDrpDwn"
      >
        <i class="bi bi-x icon-dropdown"></i
      ></a>
    </h6>
    <div class="menu">
      <ul class="submenu">
        <li>
          <a
            class="cursorPointer"
            @click="changeRoomLockStatus(!meetDetails.meetRoomLockStatus)"
          >
            <div
              v-if="islockUnlockMeetRoomReqPending"
              class="loader loader-xs loader-fs"
            ></div>
            <span class="icon-tick" v-else-if="meetDetails.meetRoomLockStatus">
              <i class="bi bi-check2"></i>
            </span>
            <span class="list-option">Lock Meeting</span>
          </a>
        </li>
        <li>
          <a
            class="cursorPointer"
            @click="changeWaitRoomStatus(!meetDetails.enableWaitRoom)"
          >
            <div
              v-if="isToggleWaitRoomReqPending"
              class="loader loader-xs loader-fs"
            ></div>
            <span class="icon-tick" v-else-if="meetDetails.enableWaitRoom">
              <i class="bi bi-check2"></i>
            </span>
            <span class="list-option">Enable Waiting Room </span>
          </a>
        </li>
        <li>
          <a
            class="cursorPointer"
            @click="toggleMirrorEffect(!meetDetails.enableMirrorEffect)"
          >
            <span class="icon-tick" v-if="meetDetails.enableMirrorEffect">
              <i class="bi bi-check2"></i>
            </span>
            <span class="list-option">Allow Mirror Effect </span>
          </a>
        </li>
      </ul>

      <h6 class="mt-2">Allow participants to</h6>
      <ul class="submenu">
        <li>
          <a
            class="cursorPointer"
            @click="openShareScreenDropdown = !openShareScreenDropdown"
          >
            <span class="list-option">
              Share Screen
              <i
                :class="{
                  bi: true,
                  'bi-chevron-right': !openShareScreenDropdown,
                  'bi-chevron-down': openShareScreenDropdown
                }"
              ></i>
            </span>
          </a>
          <transition name="slide">
            <ul class="share-list" v-if="openShareScreenDropdown">
              <li>
                <a
                  class="cursorPointer"
                  @click="
                    hostControl({
                      key: 'screenShare',
                      value: 'single-share'
                    })
                  "
                >
                  <div
                    v-if="'pending' === meetDetails.screenShare"
                    class="loader loader-xs loader-fs"
                  ></div>
                  <span
                    class="icon-tick"
                    v-else-if="'single-share' === meetDetails.screenShare"
                  >
                    <i class="bi bi-check2"></i>
                  </span>
                  <span class="list-option">One person at a time</span>
                </a>
              </li>
              <li>
                <a
                  class="cursorPointer"
                  @click="
                    hostControl({
                      key: 'screenShare',
                      value: 'multi-share'
                    })
                  "
                >
                  <div
                    v-if="'pending' === meetDetails.screenShare"
                    class="loader loader-xs loader-fs"
                  ></div>
                  <span
                    class="icon-tick"
                    v-else-if="'multi-share' === meetDetails.screenShare"
                  >
                    <i class="bi bi-check2"></i>
                  </span>
                  <span class="list-option">Multiple sharing</span>
                </a>
              </li>
            </ul>
          </transition>
        </li>
        <li>
          <a
            class="cursorPointer"
            @click="
              hostControl({ key: 'enableChat', value: !meetDetails.enableChat })
            "
          >
            <div
              v-if="'pending' === meetDetails.enableChat"
              class="loader loader-xs loader-fs"
            ></div>
            <span class="icon-tick" v-else-if="true === meetDetails.enableChat">
              <i class="bi bi-check2"></i>
            </span>
            <span class="list-option">Chat </span>
          </a>
        </li>
        <li>
          <a
            class="cursorPointer"
            @click="
              hostControl({
                key: 'enableUnmute',
                value: !meetDetails.enableUnmute
              })
            "
          >
            <div
              v-if="'pending' === meetDetails.enableUnmute"
              class="loader loader-xs loader-fs"
            ></div>
            <span
              class="icon-tick"
              v-else-if="true === meetDetails.enableUnmute"
            >
              <i class="bi bi-check2"></i>
            </span>
            <span class="list-option">Unmute Themselves</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import socket from "../../socket/socket";

export default {
  name: "Settings",
  props: {
    toggleDrpDwn: Function
  },
  data() {
    return {
      isToggleWaitRoomReqPending: false,
      islockUnlockMeetRoomReqPending: false,
      openShareScreenDropdown: false
    };
  },
  computed: {
    ...mapGetters("meet", {
      meetDetails: "getMeetDetails"
    })
  },
  methods: {
    ...mapActions("meet", {
      lockUnlockMeetRoom: "lockUnlockMeetRoom",
      toggleWaitRoom: "toggleWaitRoom"
    }),

    ...mapMutations("meet", {
      setMeetDetails: "setMeetDetails"
    }),

    /**
     * Function to enable/disable wait room
     */
    async changeWaitRoomStatus(isEnable) {
      if (!this.isToggleWaitRoomReqPending) {
        this.isToggleWaitRoomReqPending = true;
        await this.toggleWaitRoom({ isEnable: isEnable });
        this.isToggleWaitRoomReqPending = false;
      }
    },

    /**
     * Function to toggle the mirror effect
     */
    toggleMirrorEffect(isEnable) {
      this.setMeetDetails({
        ...this.meetDetails,
        enableMirrorEffect: isEnable
      });
    },

    /**
     * Function to lock/Unlock meet room
     */
    async changeRoomLockStatus(isLock) {
      if (!this.islockUnlockMeetRoomReqPending) {
        this.islockUnlockMeetRoomReqPending = true;
        await this.lockUnlockMeetRoom({ isLock: isLock });
        this.islockUnlockMeetRoomReqPending = false;
      }
    },

    /**
     * Controlling screen-sharing/chat/un-mute for participants
     */
    hostControl(data) {
      // Get the key and value
      const key = data["key"];

      if ("pending" !== data["key"]) {
        // Set meet details to pending
        this.setMeetDetails({
          ...this.meetDetails,
          [key]: "pending"
        });

        // Send to server for changes
        socket.io.emit("hostControl", data);
      }
    },

    /**
     * Function to close settings dropdown
     */
    closeSettingsDrpDwn() {
      this.toggleDrpDwn("isSettingsDrpDwnOpened");
    },

    /**
     * Prevents click action
     */
    preventClickAction(event) {
      event.stopPropagation();
    }
  }
};
</script>

<style scoped>
#host-pc-controls li {
  position: relative;
}
#host-pc-controls li a.security-dropdown {
  position: absolute;
  left: 40px;
  top: -5px;
}

#host-pc-controls li a.security-dropdown {
  left: 55px;
}
#security-options span.icon-tick {
  top: 10px;
  left: 3px;
}
#security-options.show {
  display: block;
}
#security-options h6 {
  position: relative;
}
#security-options p {
  color: #fff;
}
.con-lhs {
  float: left;
  color: #fff;
}
.con-rhs {
  float: right;
  color: #fff;
}
.loader-fs {
  left: 8px !important;
  top: -42px !important;
}
a.icon-mobsettings-close {
  color: #fff;
  position: absolute;
  right: 30px;
  font-size: 24px !important;
  display: none;
  top: -3px !important;
}
ul.share-list {
  list-style: none;
  padding: 0;
  margin: 10px 0 0 50px;
}
ul.share-list li {
  margin-bottom: 5px;
}
ul.share-list li .bi-chevron-right {
  margin-left: 70px;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

@media screen and (max-width: 768px),
  /* Changes for settings on landscape view */screen and (orientation: landscape) and (max-height: 425px) {
  #security-options.cb-dropdown {
    position: fixed;
    display: flex;
    top: 0;
    flex-direction: column;
    /* Changes for settings on portrait view */
    /* height: calc(100vh - 91px); */
    height: 100%;
    left: 0;
    width: 100%;
    z-index: 999;
  }
  #security-options.cb-dropdown ul.submenu {
    width: 94% !important;
    margin: 0;
  }
  a.icon-mobsettings-close {
    display: block;
  }
  .menu {
    max-height: calc(100% - 45px);
    overflow-y: scroll;
  }
}
</style>
