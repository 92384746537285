<template>
  <div id="app">
    <!-- Modal Overlay -->
    <div v-if="displayModalOverlay" id="modal-overlay"></div>

    <!-- Displaying router  specific UI -->
    <router-view></router-view>

    <!-- Global Alert -->
    <transition name="slide-up">
      <alert-vue
        v-if="globalAlert.msg.length > 0 && globalAlert.type.length > 0"
      />
    </transition>
  </div>
</template>

<script>
import "./assets/css/styles.css";
import "./assets/css/pysbook-theme.css";
import { mapGetters, mapMutations } from "vuex";
import AlertVue from "./components/common/Alert.vue";
import { participant } from "./mixins/Participant";

export default {
  name: "App",
  components: {
    AlertVue
  },
  mixins: [participant],
  computed: {
    ...mapGetters({
      displayModalOverlay: "getDisplayModalOverlay",
      userId: "getUserId",
      globalAlert: "getGlobalAlert",
      modal: "getModal"
    }),

    ...mapGetters("meet", {
      meetDetails: "getMeetDetails",
      hostInfo: "getHostInfo"
    })
  },
  data: function() {
    return {};
  },
  methods: {
    ...mapMutations({
      setModal: "setModal",
      setDisplayModalOverlay: "setDisplayModalOverlay",
      setOpenedDropdown: "setOpenedDropdown"
    }),

    ...mapMutations("meet", {
      setMeetDetails: "setMeetDetails"
    })
  },
  async created() {
    window.addEventListener("keydown", e => {
      if (
        "meetRoom" === this.meetDetails.room &&
        this.userId === this.hostInfo.id
      ) {
        // On pressing alt+i open invite participant pop-up
        if (e.altKey == true && e.keyCode == 73) {
          this.setModal({
            ...this.modal,
            display: true,
            title: "Invite Participants",
            bodyComponentName: "invite-participant-vue",
            styleOverride: {
              top: "8%"
            }
          });
          this.setDisplayModalOverlay(true);
        }

        // On pressing escape close the invite participant pop-up
        if (e.key === "Escape") {
          if (this.modal.display) {
            this.setModal({
              ...this.modal,
              display: false
            });
          }
        }
      }
    });

    document.body.addEventListener("click", () => {
      this.setOpenedDropdown("");
    });
  }
};
</script>

<style>
.alert {
  color: #fff !important;
  font-size: 16px;
  position: absolute;
  left: 50%;
  max-width: 300px;
  margin-left: -150px;
  top: 45%;
  text-align: center;
}

.slide-up-enter-active {
  animation: slide-up 0.2s ease-in-out forwards;
}

.slide-up-leave-active {
  animation: slide-down 0.2s ease-in-out forwards;
}

@keyframes slide-up {
  from {
    bottom: 0px;
    opacity: 0;
  }
  to {
    bottom: 80px;
    opacity: 1;
  }
}

@keyframes slide-down {
  from {
    bottom: 80px;
    opacity: 1;
  }
  to {
    bottom: 0px;
    opacity: 0;
  }
}
</style>
