import Vue from "vue";
import Router from "vue-router";
import NotFoundHome from "../components/NotFound/Home";
import Home from "../components/Dashboard/Home";
import VerifyConnectCode from "../components/VerifyConnectCode/Home";
import RsvpReply from "../components/Rsvp/Reply";
import GuestUser from "../components/GuestUser/Home";

// Use router
Vue.use(Router);

export default new Router({
  routes: [
    /**
     * GuestUser Page
     */
    {
      path: "/guestUser/:meetCode/:passCode",
      name: "GuestUser",
      component: GuestUser
    },

    /**
     * Verify Connect-Code Page
     */
    {
      path: "/join/:connectCode",
      name: "VerifyConnectCode",
      component: VerifyConnectCode
    },

    /**
     * Dashboard page
     */
    {
      path: "/:meetCode/:passCode",
      name: "Home",
      component: Home
    },

    /**
     * RSVP Reply Page
     */
    {
      path: "/setRsvp/:reply/:connectCode",
      name: "RSVP",
      component: RsvpReply
    },

    /**
     * Default 404 page
     */
    {
      path: "*",
      name: "404*",
      component: NotFoundHome
    }
  ],

  /**
   * History mode for beautified routes
   */
  mode: "history"
});
