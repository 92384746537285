import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import meet from "./modules/meet/meet";
import chat from "./modules/chat/chat";
import participant from "./modules/participant/participant";
import record from "./modules/record/record";
import router from "../router";

Vue.use(Vuex);
let globalAlertTimeout = false; // Global message timeout for hiding message
let hideHeaderFooterTimeOut = 0; // Hiding header and footer on landscape mode upon timeout

export const store = new Vuex.Store({
  state: {
    /**
     * Authentication token
     */
    token: "",

    /**
     * User name
     */
    userName: {
      firstName: "",
      middleName: "",
      lastName: ""
    },

    /**
     * User timezone
     */
    userTimezone: "",

    /**
     * Encrypted provider id
     */
    encryptedProviderId: "",

    /**
     * User id
     */
    userId: 0,

    /**
     * Flag to check if user is authenticated
     */
    isUserAuthenticated: false,

    /**
     * Flag to check if user is a guest user
     */
    isGuestUser: false,

    /**
     * Global Alert
     */
    globalAlert: {
      msg: "",
      type: ""
    },

    /**
     * Flag to determine if modal overlay has to display on body or not.
     */
    displayModalOverlay: false,

    /**
     * Configuration to display/hide a modal
     */
    modal: {
      display: false, // Property to toggle modal display(Hide/Show)
      title: "Title", // Modal title
      bodyComponentName: "default-modal-vue", // A component to render on the modal body,
      styleOverride: {} // Format: { top: "auto", z-index: 999 ...},
    },

    /**
     * Opened Dropdown name
     */
    openedDropdown: "",

    /**
     * Socket Id of current socket connection
     */
    socketId: null
  },
  getters: {
    /**
     * Returns the token for the me
     *
     * @param {Object} state
     */
    getToken(state) {
      return state.token;
    },

    /**
     * Returns the loggedin username
     *
     * @param {Object} state
     */
    getUserName(state) {
      return state.userName;
    },

    /**
     * Return user timezone
     *
     * @param {Object} state
     */
    getUserTimezone(state) {
      return state.userTimezone;
    },

    /**
     * Returns encrypted provider id
     *
     * @param {Object} state
     */
    getEncryptedProviderId(state) {
      return state.encryptedProviderId;
    },

    /**
     * Returns user id
     *
     * @param {Object} state
     */
    getUserId(state) {
      return state.userId;
    },

    /**
     * Returns if user is a guest
     *
     * @param {Object} state
     */
    getIsGuestUser(state) {
      return state.isGuestUser;
    },

    /**
     * Returns global error message
     *
     * @param {Object} state
     */
    getGlobalAlert(state) {
      return state.globalAlert;
    },

    /**
     * Returns flag to determine if overlay has to display on body or not
     *
     * @param {Object} state
     */
    getDisplayModalOverlay(state) {
      return state.displayModalOverlay;
    },

    /**
     * Returns the modal object from vuex
     *
     * @param {Object} state
     */
    getModal(state) {
      return state.modal;
    },

    /**
     * Returns the opened dropdown name
     *
     * @param {Object} state
     */
    getOpenedDropdown(state) {
      return state.openedDropdown;
    },

    /**
     * Returns the socket id
     *
     * @param {Object} state
     */
    getSocketId(state) {
      return state.socketId;
    }
  },
  mutations: {
    /**
     * Sets the authentication token
     *
     * @param {Object} state
     * @param {String} token
     */
    setToken(state, token) {
      state.token = token;
    },

    /**
     * Sets the logged in user id
     *
     * @param {Object} state
     * @param {Integer} userId
     */
    setUserId(state, userId) {
      state.userId = userId;
    },

    /**
     * Sets if user is a guest user
     *
     * @param {Object} state
     * @param {Boolean} value
     */
    setIsGuestUser(state, value) {
      state.isGuestUser = value;
    },

    /**
     * Sets user name
     *
     * @param {Object} state
     * @param {string} userName
     */
    setUserName(state, { firstName, lastName, middleName }) {
      state.userName = {
        firstName,
        lastName,
        middleName
      };
    },

    /**
     * Sets user timezone
     *
     * @param {Object} state
     * @param {String} timezone
     */
    setUserTimezone(state, timezone) {
      state.userTimezone = timezone;
    },

    /**
     * Sets encrypted provider id
     *
     * @param {Object} state
     * @param {String} encryptedProviderId
     */
    setEncryptedProviderId(state, encryptedProviderId) {
      state.encryptedProviderId = encryptedProviderId;
    },

    /**
     * Sets the global alert message
     *
     * @param {Object} state
     * @param {Object} param1
     */
    setGlobalAlert(state, { type, msg }) {
      state.globalAlert = {
        type,
        msg
      };
    },

    /**
     * Sets flag which determine if overlay has to be displayed.
     *
     * @param {Object} state
     * @param {Boolean} value
     */
    setDisplayModalOverlay(state, value) {
      state.displayModalOverlay = value;
    },

    /**
     * Sets modal object
     *
     * @param {Object} state
     * @param {Boolean} modal object
     */
    setModal(state, modalObj) {
      state.modal = modalObj;

      // Hide modal overlay
      if (!state.modal.display) {
        state.displayModalOverlay = false;
      }
    },

    /**
     * Sets the opened dropdown name
     *
     * @param {Object} state
     * @param {String} dropdownName
     */
    setOpenedDropdown(state, dropdownName) {
      state.openedDropdown = dropdownName;
    },

    /**
     * Sets the socket id
     *
     * @param {Object} state
     * @package {String} socketId
     */
    setSocketId(state, socketId) {
      state.socketId = socketId;
    }
  },
  actions: {
    /**
     * General request with auth tokens
     *
     * @param {Object} context
     * @param {Object} data
     */
    makeHttpRequest(context, data) {
      let headers = {
        "Content-Type":
          data.header == "multipart/form-data"
            ? "multipart/form-data"
            : "application/json; charset=utf-8"
      };

      // Include connectcode, if present on session storage
      if (window.sessionStorage.getItem("connectCode")) {
        headers["connectCode"] = window.sessionStorage.getItem("connectCode");
      }

      // Include session hash if present on query parameter
      if (router.currentRoute.query.sessionHash) {
        headers["sessionHash"] = router.currentRoute.query.sessionHash;
      }

      // Include token of present on the session storage
      if (window.sessionStorage.getItem("token")) {
        headers["token"] = window.sessionStorage.getItem("token");
      }

      return new Promise(async resolve => {
        // Build request based on method type
        let requestParams = {
          url: "/" + data.url,
          method: data.method,
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers,
          redirect: "follow",
          referrer: "no-referrer"
        };

        // Stringify the data
        if (data.data) {
          requestParams.data = await JSON.stringify(data.data);
        }

        try {
          // Make query
          const res = await axios(requestParams);

          // Resolve
          resolve(res.data);
        } catch (err) {
          // Get the response
          const res = err.response;

          if (res !== undefined) {
            // Return
            resolve(res.data);
          }
        }
      });
    },

    /**
     * Set a global alert and remove it after the specified time
     *
     * @param {Object} context
     * @param {*} param1
     */
    displayGlobalAlert(context, { type, msg }) {
      // Set the message
      context.commit("setGlobalAlert", { type, msg });

      // Set the timeout if user is in meet/wait room
      if (
        "Home" === router.currentRoute.name &&
        null !== context.getters["meet/getMeetDetails"].room
      ) {
        // Get timeout value from .env file
        const timeOut = process.env.VUE_APP_GLOBAL_ALERT_EXPIRY_TIME;

        // Clear the old timeout if there is any
        if (globalAlertTimeout) {
          clearTimeout(globalAlertTimeout);
        }

        // Set another timeout and after the timeout remove the message
        globalAlertTimeout = setTimeout(() => {
          context.commit("setGlobalAlert", { type: "", msg: "" });
          globalAlertTimeout = false;
          clearTimeout(globalAlertTimeout);
        }, timeOut * 1000);
      }
    },

    /**
     * Empty the message and hence successfully hide the popup
     *
     * @param {Object} context
     */
    hideGlobalAlert(context) {
      // Clear time out
      if (globalAlertTimeout) {
        clearTimeout(globalAlertTimeout);
        globalAlertTimeout = false;
      }

      // Clear the message
      context.commit("setGlobalAlert", { type: "", msg: "" });
    }
  },
  strict: true,
  modules: {
    meet,
    chat,
    participant,
    record
  }
});
