import { mapGetters, mapMutations, mapActions } from "vuex";
import socket from "../socket/socket";

export const participant = {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("meet", {
      videoTrack: "getVideoTrack",
      audioTrack: "getAudioTrack",
      peerConnections: "getPeerConnections",
      rtcRtpSenders: "getRtcRtpSenders",
      participantList: "getParticipantList",
      guestUserList: "getGuestUserList",
      displayView: "getDisplayView",
      pinnedPeerId: "getPinnedPeerId"
    })
  },
  methods: {
    ...mapMutations("meet", {
      resetMeetState: "resetMeetState",
      setPeerConnections: "setPeerConnections",
      setRtcRtpSenders: "setRtcRtpSenders",
      removeParticipantFromWaitingRoom: "removeParticipantFromWaitingRoom",
      removeParticipantFromMeetingRoom: "removeParticipantFromMeetingRoom",
      setParticipantList: "setParticipantList",
      setGuestUserList: "setGuestUserList",
      setPinnedPeerId: "setPinnedPeerId",
      setDisplayView: "setDisplayView"
    }),

    ...mapActions("meet", {
      clearUpdateSocketLeaveTimeInterval: "clearUpdateSocketLeaveTimeInterval"
    }),

    /**
     * Function to remove socket connections
     *
     * @param {String} id
     * @param {String} reason
     */
    removeSocketConnection(id, reason = null) {
      if ("all" === id) {
        // Removing all socket connection
        if (this.audioTrack) {
          this.audioTrack.stop();
        }

        if (this.videoTrack) {
          this.videoTrack.stop();
        }

        // Closing all peer connection
        for (const connection in this.peerConnections) {
          if (connection.timeOut) {
            clearTimeout(connection.timeOut);
          }
          this.peerConnections[connection].pc.close();
        }

        // Resetting the meet state
        this.resetMeetState();
      } else {
        let iceState = null;
        let isCallInitiated = null;
        // Removing a particular socket connection
        if (
          null === reason ||
          "client namespace disconnect" == reason ||
          "server namespace disconnect" == reason ||
          "transport close" == reason
        ) {
          if (this.peerConnections[id]) {
            // Storing ice state and call initiated variables
            iceState = this.peerConnections[id].iceState;
            isCallInitiated = this.peerConnections[id].isCallInitiated;

            // Close the peer connection against the socket-id
            this.peerConnections[id].pc.close();

            // ClearTimer
            if (this.peerConnections[id].timeOut) {
              clearTimeout(this.peerConnections[id].timeOut);
            }

            // Remove the participant from peer connection list
            let newPeerConnections = { ...this.peerConnections };
            delete newPeerConnections[id];
            this.setPeerConnections({ ...newPeerConnections });
          }

          // Remove the participant from rtcRtpSender list
          if (this.rtcRtpSenders[id]) {
            let newRtcRtpSenders = { ...this.rtcRtpSenders };
            delete newRtcRtpSenders[id];
            this.setRtcRtpSenders(newRtcRtpSenders);
          }

          // Remove the participant from meeting room list
          this.removeParticipantFromMeetingRoom(id);
        }

        // Remove the participant from waiting room list
        this.removeParticipantFromWaitingRoom(id);

        // Removing the socket id from guest user list as well
        const newGuestUserList = [...this.guestUserList];
        const guestConnIndex = newGuestUserList.findIndex(
          participant => participant.socketId === id
        );
        if (guestConnIndex > -1) {
          newGuestUserList.splice(guestConnIndex, 1);
        }
        this.setGuestUserList(newGuestUserList);

        // Removing the participant from pinned view
        if ("pinned" === this.displayView && this.pinnedPeerId === id) {
          this.setPinnedPeerId("self");
        }

        // Rejoin peer connection, if permanently disconnected
        console.log("isCallInitiated");
        console.log(isCallInitiated);
        console.log(iceState);
        if (!isCallInitiated && "permanentlyDisconnected" === iceState) {
          console.log("readyToReconnect emitted");
          socket.io.emit("readyToReconnect", {
            dest: id,
            socketId: socket.io.id
          });
        }
      }

      // If all peer connections are removed, set the current view to speaker view
      if (0 === Object.keys(this.peerConnections).length) {
        this.setPinnedPeerId(null);
        this.setDisplayView("speaker");
      }
    }
  }
};
