<template>
  <div class="upload-record-popup">
    <p
      v-if="
        this.recordFileUploadStatus !== 'uploaded' &&
          this.recordedChunks.length > 0 &&
          'idle' === this.recordingState
      "
    >
      You have not uploaded your recording. Would you like to do that now? (If
      you meant to save the recording, it must be uploaded.)
    </p>

    <p v-else-if="'recording' === recordingState">
      Leaving the meeting will end the recording. If you want to save the
      recording, it must be uploaded. Would you like to upload it after you
      leave the meeting? (Do not turn your device off until the upload is
      finished.)
    </p>

    <div>
      <button class="btn btn-primary yes-btn" @click="confirmRecord">
        Yes
      </button>

      <button class="btn-secondary" @click="IgnoreRecordingAndExit">
        No
      </button>
    </div>
  </div>
</template>

<script>
import { record } from "../../mixins/Record";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "UploadRecordPopupConfirm",
  mixins: [record],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      modal: "getModal"
    }),

    ...mapGetters("record", {
      isRecordFileDownloading: "getIsRecordFileDownloading",
      recordFileUploadStatus: "getRecordFileUploadStatus"
    })
  },
  methods: {
    ...mapMutations({
      setModal: "setModal",
      setOpenedDropdown: "setOpenedDropdown"
    }),

    ...mapMutations("meet", {
      setIsEndMeetPopupOpened: "setIsEndMeetPopupOpened",
      setIsRecordDrpDwnOpened: "setIsRecordDrpDwnOpened"
    }),

    /**
     * Function to close the modal
     */
    closeModal() {
      this.setModal({
        ...this.modal,
        display: false
      });
    },

    /**
     * Function to confirm recording
     */
    confirmRecord() {
      this.closeModal();
      this.stopRecording();
      this.setIsRecordDrpDwnOpened(true);
      this.setOpenedDropdown("isRecordDrpDwnOpened");
      if (
        "uploading" !== this.recordFileUploadStatus &&
        !this.isRecordFileDownloading
      ) {
        this.setModal({
          ...this.modal,
          display: true,
          title: "",
          bodyComponentName: "upload-file-vue",
          styleOverride: {
            top: "auto",
            left: "auto",
            right: "50px",
            bottom: "147px",
            "max-width": "550px",
            "z-index": 999
          }
        });
      }
    },

    /**
     * Ignore recording and exit the meeting
     */
    IgnoreRecordingAndExit() {
      this.closeModal();
      this.stopRecording(true);
      setTimeout(this.resetRecordingState, 10);
      this.setIsEndMeetPopupOpened(true);
      this.setOpenedDropdown("isEndMeetPopupOpened");
    }
  }
};
</script>

<style scoped>
.yes-btn {
  margin-right: 1%;
}

.upload-record-popup {
  font-size: 14px;
  color: #fff;
}
</style>
