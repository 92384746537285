var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cb-dropdown",attrs:{"id":"security-options"},on:{"click":_vm.preventClickAction}},[_c('h6',[_vm._v(" Settings "),_c('a',{staticClass:"icon-mobsettings-close cursorPointer",on:{"click":_vm.closeSettingsDrpDwn}},[_c('i',{staticClass:"bi bi-x icon-dropdown"})])]),_c('div',{staticClass:"menu"},[_c('ul',{staticClass:"submenu"},[_c('li',[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){return _vm.changeRoomLockStatus(!_vm.meetDetails.meetRoomLockStatus)}}},[(_vm.islockUnlockMeetRoomReqPending)?_c('div',{staticClass:"loader loader-xs loader-fs"}):(_vm.meetDetails.meetRoomLockStatus)?_c('span',{staticClass:"icon-tick"},[_c('i',{staticClass:"bi bi-check2"})]):_vm._e(),_c('span',{staticClass:"list-option"},[_vm._v("Lock Meeting")])])]),_c('li',[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){return _vm.changeWaitRoomStatus(!_vm.meetDetails.enableWaitRoom)}}},[(_vm.isToggleWaitRoomReqPending)?_c('div',{staticClass:"loader loader-xs loader-fs"}):(_vm.meetDetails.enableWaitRoom)?_c('span',{staticClass:"icon-tick"},[_c('i',{staticClass:"bi bi-check2"})]):_vm._e(),_c('span',{staticClass:"list-option"},[_vm._v("Enable Waiting Room ")])])]),_c('li',[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){return _vm.toggleMirrorEffect(!_vm.meetDetails.enableMirrorEffect)}}},[(_vm.meetDetails.enableMirrorEffect)?_c('span',{staticClass:"icon-tick"},[_c('i',{staticClass:"bi bi-check2"})]):_vm._e(),_c('span',{staticClass:"list-option"},[_vm._v("Allow Mirror Effect ")])])])]),_c('h6',{staticClass:"mt-2"},[_vm._v("Allow participants to")]),_c('ul',{staticClass:"submenu"},[_c('li',[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){_vm.openShareScreenDropdown = !_vm.openShareScreenDropdown}}},[_c('span',{staticClass:"list-option"},[_vm._v(" Share Screen "),_c('i',{class:{
                bi: true,
                'bi-chevron-right': !_vm.openShareScreenDropdown,
                'bi-chevron-down': _vm.openShareScreenDropdown
              }})])]),_c('transition',{attrs:{"name":"slide"}},[(_vm.openShareScreenDropdown)?_c('ul',{staticClass:"share-list"},[_c('li',[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){return _vm.hostControl({
                    key: 'screenShare',
                    value: 'single-share'
                  })}}},[('pending' === _vm.meetDetails.screenShare)?_c('div',{staticClass:"loader loader-xs loader-fs"}):('single-share' === _vm.meetDetails.screenShare)?_c('span',{staticClass:"icon-tick"},[_c('i',{staticClass:"bi bi-check2"})]):_vm._e(),_c('span',{staticClass:"list-option"},[_vm._v("One person at a time")])])]),_c('li',[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){return _vm.hostControl({
                    key: 'screenShare',
                    value: 'multi-share'
                  })}}},[('pending' === _vm.meetDetails.screenShare)?_c('div',{staticClass:"loader loader-xs loader-fs"}):('multi-share' === _vm.meetDetails.screenShare)?_c('span',{staticClass:"icon-tick"},[_c('i',{staticClass:"bi bi-check2"})]):_vm._e(),_c('span',{staticClass:"list-option"},[_vm._v("Multiple sharing")])])])]):_vm._e()])],1),_c('li',[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){return _vm.hostControl({ key: 'enableChat', value: !_vm.meetDetails.enableChat })}}},[('pending' === _vm.meetDetails.enableChat)?_c('div',{staticClass:"loader loader-xs loader-fs"}):(true === _vm.meetDetails.enableChat)?_c('span',{staticClass:"icon-tick"},[_c('i',{staticClass:"bi bi-check2"})]):_vm._e(),_c('span',{staticClass:"list-option"},[_vm._v("Chat ")])])]),_c('li',[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){return _vm.hostControl({
              key: 'enableUnmute',
              value: !_vm.meetDetails.enableUnmute
            })}}},[('pending' === _vm.meetDetails.enableUnmute)?_c('div',{staticClass:"loader loader-xs loader-fs"}):(true === _vm.meetDetails.enableUnmute)?_c('span',{staticClass:"icon-tick"},[_c('i',{staticClass:"bi bi-check2"})]):_vm._e(),_c('span',{staticClass:"list-option"},[_vm._v("Unmute Themselves")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }