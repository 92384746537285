<template>
  <!-- Logged in participant Video -->
  <div
    id="active-pinned-video"
    :class="{
      'full-vd-container':
        0 === Object.keys(peerConnections).length && fullScreenMode,
      thumb:
        Object.keys(peerConnections).length > 0 && 'speaker' === displayView,
      'room-pinned': 'pinned' === displayView
    }"
  >
    <div class="ap-video-con">
      <!-- Showing loader when camera is not yet loaded -->
      <div
        class="user-pro-pic"
        v-show="
          !isCameraStreamReady &&
            !('pinned' === displayView && 'self' !== pinnedPeerId)
        "
      >
        <img ref="speakerViewImg1" src="../../assets/img/loader.gif" />
      </div>

      <!-- Show first character of first name and last name on mute -->
      <div class="name-thumb" v-show="muteCharacter.length > 0">
        <div class="name">
          {{ muteCharacter }}
        </div>
      </div>

      <!-- Playing peer video -->
      <video
        v-show="displayVideo"
        autoplay
        muted
        playsinline
        :class="{
          flipEffect:
            ('speaker' === displayView ||
              ('pinned' === displayView && 'self' === pinnedPeerId)) &&
            meetDetails.enableMirrorEffect &&
            'camera' === meetDetails.videoTrackType
        }"
        ref="speakerViewVideo"
        id="speakerViewVideo"
      ></video>

      <template v-if="'pinned' !== displayView">
        <!-- User Options -->
        <div class="view-options">
          <span class="gallery-view-options">
            <a class="cursorPointer" @click.stop="openUserOptionsPopup">
              <i class="bi-three-dots"></i>
            </a>
          </span>
          <user-options-vue
            v-if="openUserOptions"
            :open-rename-popup="openRenamePopup"
            :remove-self-view="removeSelfView"
          >
          </user-options-vue>
        </div>
      </template>
      <div class="mobile-view win-participant">
        <span class="participant-name">{{ name }}</span>
      </div>
      <div
        :class="{
          'web-view': true,
          'win-participant': true,
          'blue-background': 'pinned' === displayView
        }"
        @click="pinParticipant"
      >
        <span class="participant-name">{{ name }}</span>
        <span
          v-if="
            'speaker' === displayView && Object.keys(peerConnections).length > 0
          "
          class="pin-btn"
        >
          <i class="bi bi-pin-fill"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import UserOptionsVue from "./UserOptions.vue";

export default {
  name: "VideoThumb",
  data() {
    return {
      openUserOptions: false
    };
  },
  components: {
    UserOptionsVue
  },
  props: {
    toggleRenamePopup: Function,
    hideSelfView: Function
  },
  computed: {
    ...mapGetters({
      userName: "getUserName",
      openedDropdown: "getOpenedDropdown"
    }),

    ...mapGetters("meet", {
      peerConnections: "getPeerConnections",
      fullScreenMode: "getFullScreenMode",
      isCameraStreamReady: "getIsCameraStreamReady",
      meetDetails: "getMeetDetails",
      cameraStream: "getCameraStream",
      screenShareStream: "getScreenShareStream",
      pinnedPeerId: "getPinnedPeerId",
      displayView: "getDisplayView"
    }),

    /**
     * Computed property to get the first characters of names when mute.
     */
    muteCharacter() {
      if (
        ("speaker" === this.displayView ||
          ("pinned" === this.displayView && "self" === this.pinnedPeerId)) &&
        this.isCameraStreamReady &&
        "camera" === this.meetDetails.videoTrackType &&
        this.meetDetails.isUserVideoMuted
      ) {
        return `${this.userName.firstName ? this.userName.firstName[0] : ""}${
          this.userName.lastName ? this.userName.lastName[0] : ""
        }`.toUpperCase();
      } else if (
        "pinned" === this.displayView &&
        "self" !== this.pinnedPeerId &&
        "camera" === this.peerConnections[this.pinnedPeerId].videoTrackType &&
        this.peerConnections[this.pinnedPeerId].isVideoMuted
      ) {
        return `${
          this.peerConnections[this.pinnedPeerId].userName.firstName
            ? this.peerConnections[this.pinnedPeerId].userName.firstName[0]
            : ""
        }${
          this.peerConnections[this.pinnedPeerId].userName.lastName
            ? this.peerConnections[this.pinnedPeerId].userName.lastName[0]
            : ""
        }`.toUpperCase();
      } else {
        return "";
      }
    },

    /**
     * Computed property to determine if video need to show/hide
     */
    displayVideo() {
      if (
        ("speaker" === this.displayView ||
          ("pinned" === this.displayView && "self" === this.pinnedPeerId)) &&
        this.isCameraStreamReady &&
        ("screen" === this.meetDetails.videoTrackType ||
          ("camera" === this.meetDetails.videoTrackType &&
            !this.meetDetails.isUserVideoMuted))
      ) {
        return true;
      } else if (
        "pinned" === this.displayView &&
        "self" !== this.pinnedPeerId &&
        ("screen" === this.peerConnections[this.pinnedPeerId].videoTrackType ||
          ("camera" ===
            this.peerConnections[this.pinnedPeerId].videoTrackType &&
            !this.peerConnections[this.pinnedPeerId].isVideoMuted))
      ) {
        return true;
      } else {
        return false;
      }
    },

    /**
     * Computed property to return the name of user
     */
    name() {
      if ("pinned" === this.displayView && "self" !== this.pinnedPeerId) {
        return `${this.peerConnections[this.pinnedPeerId].userName.firstName}${
          this.peerConnections[this.pinnedPeerId].userName.middleName
            ? " " + this.peerConnections[this.pinnedPeerId].userName.middleName
            : ""
        }${
          this.peerConnections[this.pinnedPeerId].userName.lastName
            ? " " + this.peerConnections[this.pinnedPeerId].userName.lastName
            : ""
        }`;
      } else {
        return `${this.userName.firstName}${
          this.userName.middleName ? ` ${this.userName.middleName}` : ""
        }${this.userName.lastName ? ` ${this.userName.lastName}` : ""}`;
      }
    }
  },
  watch: {
    /**
     * Watchers for video track type change, changing loggedin user stream view.
     */
    "meetDetails.videoTrackType": function(value) {
      if (
        "pinned" !== this.displayView ||
        ("pinned" === this.displayView && "self" === this.pinnedPeerId)
      ) {
        this.displayLoggedinUserStreamOnSpeakerView();
      }
    },

    /**
     * Loading user video, When camera stream is ready.
     */
    isCameraStreamReady: function(value) {
      if (
        value &&
        !("pinned" === this.displayView && "self" !== this.pinnedPeerId)
      ) {
        this.displayLoggedinUserStreamOnSpeakerView();
      }
    },

    /**
     * Loading new video, When pined  peer id is changing
     */
    pinnedPeerId: function(value) {
      if (!value || "self" === value) {
        this.displayLoggedinUserStreamOnSpeakerView();
      } else {
        this.$refs.speakerViewVideo.srcObject = this.peerConnections[
          this.pinnedPeerId
        ].stream;
        this.$refs.speakerViewVideo.load();
      }
    },

    openedDropdown: function(drpDwnName) {
      let keys = ["openUserOptions"];
      keys.forEach(key => {
        if (key !== drpDwnName) {
          this[key] = false;
        }
      });
    }
  },
  methods: {
    ...mapMutations({
      setOpenedDropdown: "setOpenedDropdown"
    }),

    ...mapMutations("meet", {
      setMeetDetails: "setMeetDetails",
      setDisplayView: "setDisplayView",
      setPinnedPeerId: "setPinnedPeerId"
    }),

    /**
     * Function to set the camera/screenshare stream on speaker view
     */
    displayLoggedinUserStreamOnSpeakerView() {
      if ("camera" === this.meetDetails.videoTrackType) {
        this.$refs.speakerViewVideo.srcObject = this.cameraStream;
        this.$refs.speakerViewVideo.load();
      } else if ("screen" === this.meetDetails.videoTrackType) {
        this.$refs.speakerViewVideo.srcObject = this.screenShareStream;
        this.$refs.speakerViewVideo.load();
      }
    },

    /**
     * Function to open rename pop-up.
     */
    openRenamePopup() {
      this.openUserOptions = false;
      this.setOpenedDropdown("");
      this.toggleRenamePopup();
    },

    removeSelfView() {
      this.openUserOptions = false;
      this.setOpenedDropdown("");
      this.hideSelfView();
    },

    /**
     * Function to pin participant to mid.
     */
    pinParticipant() {
      if (
        "speaker" === this.displayView &&
        Object.keys(this.peerConnections).length > 0
      ) {
        this.setMeetDetails({
          ...this.meetDetails,
          hideSelfView: false
        });
        this.setPinnedPeerId("self");
        this.setDisplayView("pinned");
      }
    },

    /**
     * Opens user options popup
     */
    openUserOptionsPopup() {
      this.openUserOptions = true;
      this.setOpenedDropdown("openUserOptions");
    }
  }
};
</script>

<style scoped>
video {
  min-width: 100%;
  max-width: 100%;
}
.flipEffect {
  -webkit-transform: scaleX(-1) !important;
  transform: scaleX(-1) !important;
}
.flipEffect::-webkit-media-controls {
  display: none !important;
}
.gallery-view-options {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 25px;
  text-align: center;
  background: #d26d13;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery-view-options a {
  color: #fff;
}
#active-pinned-video {
  width: 70%;
  height: 100%;
  clear: both;
  overflow: hidden;
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.ap-video-con {
  background: #1b1d1e;
  border-radius: 5px;
  width: 100%;
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.ap-video-con video {
  max-height: 100%;
}
.user-pro-pic {
  max-width: 100%;
  min-width: 300px;
}
.user-pro-pic img {
  width: 100%;
  display: block;
  margin: 0 auto;
  max-width: 128px;
}
#active-pinned-video.thumb {
  width: 235px;
  height: 132px;
  position: absolute;
  bottom: 8%;
  right: 1%;
}
#active-pinned-video.thumb .ap-video-con {
  height: 100%;
  border-radius: 8px;
  transform: translate3d(0, 0, 0);
}
#active-pinned-video.thumb .ap-video-con .user-pro-pic {
  width: 100%;
  height: 100%;
  min-width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#active-pinned-video.thumb .ap-video-con .user-pro-pic img {
  max-width: 80px;
}
#active-pinned-video.full-vd-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  clear: both;
  max-width: 100%;
  margin: 0 auto;
}
/*---Video Full Screen---*/
#active-pinned-video.full-vd-container .ap-video-con {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#active-pinned-video.full-vd-container .ap-video-con video {
  /*-- max-height: 55vh;--*/
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}
/* h4.host-name {
  color: #fff;
  text-align: center;
  margin: 10px auto;
} */
#active-pinned-video.full-vd-container .ap-video-con video.flipEffect {
  top: 0;
  left: 0;
  transform: translate(0%, 0%);
}
#active-pinned-video.full-vd-container .user-pro-pic {
  max-width: 100%;
  min-width: 300px;
}
/* #active-pinned-video.full-vd-container h4.host-name {
  color: #fff;
  position: absolute;
  bottom: 120px;
  margin-left: -50%;
  z-index: 9999999999;
  left: 50%;
  padding: 5px 10px;
  width: 100%;
} */
.name-thumb {
  width: 165px;
  height: 165px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d26e2f;
}
.name {
  color: #fff;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
#active-pinned-video.thumb .ap-video-con .name-thumb {
  width: 80px;
  height: 80px;
}
#active-pinned-video.thumb .ap-video-con .name-thumb .name {
  font-size: 30px;
}
/* CSS for pinned participant */
#active-pinned-video.room-pinned {
  width: 85%;
  height: 98%;
  padding: 0 1%;
}
#active-pinned-video.room-pinned .ap-video-con {
  height: 100%;
}
#active-pinned-video.room-pinned .ap-video-con .name-thumb {
  width: 145px;
  height: 145px;
}
.mobile-view.win-participant {
  display: none;
}
.web-view.win-participant {
  display: flex;
}
@media screen and (max-width: 1024px) {
  .name-thumb {
    width: 145px;
    height: 145px;
  }

  .name {
    font-size: 40px;
  }

  #active-pinned-video.thumb {
    width: 180px;
    height: 120px;
  }

  #active-pinned-video.thumb .ap-video-con .name-thumb {
    width: 65px;
    height: 65px;
  }

  #active-pinned-video.thumb .ap-video-con .name-thumb .name {
    font-size: 27px;
  }

  /* CSS for pinned participant */
  #active-pinned-video.room-pinned {
    width: 80%;
  }
  #active-pinned-video.room-pinned .ap-video-con .name-thumb {
    width: 130px;
    height: 130px;
  }

  .mobile-view.win-participant {
    display: flex;
  }
  .web-view.win-participant {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  #active-pinned-video {
    width: 98%;
  }

  .ap-video-con {
    height: 98%;
  }

  #active-pinned-video.thumb {
    right: 3%;
    width: 100px;
    height: 132px;
  }

  .ap-video-con video {
    object-fit: cover;
  }

  .view-options {
    display: none;
  }

  .name-thumb {
    width: 100px;
    height: 100px;
  }

  .name {
    font-size: 35px;
  }

  #active-pinned-video.thumb .ap-video-con .name-thumb {
    width: 50px;
    height: 50px;
  }
  #active-pinned-video.thumb .ap-video-con .name-thumb .name {
    font-size: 25px;
  }
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  /* CSS for pinned participant */
  #active-pinned-video.room-pinned {
    width: 100%;
    height: calc(100% - 85px);
  }

  #active-pinned-video.room-pinned .ap-video-con {
    height: 98%;
  }
}
@media screen and (orientation: landscape) and (max-height: 425px) {
  /* CSS for pinned participant */
  #active-pinned-video.room-pinned {
    width: 85%;
    height: 100%;
  }

  .ap-video-con {
    height: 100%;
  }

  .view-options {
    display: none;
  }
}
</style>
