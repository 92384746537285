<template>
  <div id="wrapper">
    <div class="error-page">
      <img class="error-image" :src="notFoundGif" />
      <div class="wrapper-text-404">
        <h1 class="error-heading">OOPS! Page Not Found</h1>
        <p class="error-subtext">
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
        <a @click="goBackToPrev" class="error-go-back cursorPointer">
          Go Back
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notFoundGif: require("../../assets/img/404-animated.gif")
    };
  },
  computed: {},
  methods: {
    goBackToPrev() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
/* CSS HERE */
.error-page {
  background-color: #fff;
  min-height: 100vh;
  height: 100%;
  text-align: center;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.error-heading {
  margin: 0px;
  text-transform: uppercase;
  text-shadow: 2px 3px 5px #c9cbce;
}
.error-go-back {
  background-color: #498bde;
  padding: 10px 36px;
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  margin-top: 20px;
  text-transform: uppercase;
  -webkit-box-shadow: 0px 4px 15px -5px #0046d5;
  box-shadow: 0px 4px 15px -5px #0046d5;
  max-width: 190px;
  width: 90%;
  margin-bottom: 20px;
}
.error-image {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}
.error-subtext {
  font-size: 13px;
  line-height: 20px;
}
.wrapper-text-404 {
  margin-top: -30px;
}
#wrapper {
  height: auto !important;
}
</style>
