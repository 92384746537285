/**
 * State for record
 */
export const state = {
  /**
   * AudioContext
   */
  audioContext: null,

  /**
   * Composed Stream
   */
  composedStream: null,

  /**
   * Recording State
   */
  recordingState: "idle", // idle/paused/recording

  /**
   * Audio Destination Node
   */
  audioDestinationNode: null,

  /**
   * Media Recorder
   */
  mediaRecorder: null,

  /**
   * Video stream to record
   */
  recordedScreenStream: null,

  /**
   * Flag to check, whether record file getting uploaded
   */
  recordFileUploadStatus: "not-uploaded", // 'not-uploaded', 'uploading', 'uploaded'

  /**
   * Flag to check, whether record file getting downloaded
   */
  isRecordFileDownloading: false,

  /**
   * Recorded file name
   */
  recordedFileName: "",

  /**
   * Percentage of video uploaded to server
   */
  uploadPercentage: 0,

  /**
   * File downloadable link
   */
  fileId: "",

  /**
   * Chunks of videos been recorded
   */
  recordedChunks: [],

  /**
   * Flag to check if recording is for first time
   */
  firstTimeRecord: false
};
