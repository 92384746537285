<template>
  <div class="upload-record-popup">
    <p>
      Your recording is in progress.
    </p>

    <p>
      If you want to save the recording, please upload it before you close or refresh your browser tab.
    </p>

    <div>
      <button class="btn btn-primary yes-btn" @click="closeModal">
        Ok
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "RecordFirstTime",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      modal: "getModal"
    })
  },
  methods: {
    ...mapMutations({
      setModal: "setModal"
    }),

    /**
     * Function to close the modal
     */
    closeModal() {
      this.setModal({
        ...this.modal,
        display: false
      });
    }
  }
};
</script>

<style scoped>
.yes-btn {
  margin-right: 1%;
}

.upload-record-popup {
  font-size: 14px;
  color: #fff;
}
</style>
