var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app-header"}},[_c('a',{staticClass:"app-logo cursorPointer"},[_c('img',{attrs:{"src":_vm.logoIcon,"alt":"Psybooks Connect"}})]),_c('a',{staticClass:"app-title cursorPointer"},[_vm._v(" PSYBooks Connect ")]),_c('div',{staticClass:"header-icons"},[_c('div',{staticClass:"timer"},[_vm._v(" "+_vm._s(_vm.timeDiff)+" "),(Object.keys(_vm.peerConnections).length > 0)?_c('a',{staticClass:"fullscreen-toggle switch-view cursorPointer",on:{"click":function($event){$event.stopPropagation();return _vm.toggleViewPopup.apply(null, arguments)}}},[_c('i',{staticClass:"bi-menu-app-fill"}),_vm._v(" VIEW ")]):_vm._e(),_c('div',{staticClass:"switch-view"},[(_vm.openViewPopup)?_c('switch-view-vue',{attrs:{"toggle-view-popup":_vm.toggleViewPopup}}):_vm._e()],1),(_vm.checkFullScreenSupport)?_c('a',{staticClass:"fullscreen-toggle cursorPointer",on:{"click":_vm.toggleFullScreen}},[_c('i',{staticClass:"bi bi-arrows-angle-expand"})]):_vm._e()]),_c('ul',{staticClass:"header-tr-mob"},[_c('li',[(_vm.checkFullScreenSupport)?_c('a',{staticClass:"fullscreen-toggle cursorPointer",on:{"click":_vm.toggleFullScreen}},[_c('i',{staticClass:"bi bi-arrows-angle-expand"})]):_vm._e()]),_c('li',[_c('a',{staticClass:"icon-flip cursorPointer",on:{"click":_vm.switchMobileCamera}},[_c('i',{staticClass:"bi bi-arrow-repeat"})])]),_c('li',[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){return _vm.toggleSidebar({
              clickEvent: 'participant'
            })}}},[_c('span',{staticClass:"participant-count"},[_vm._v(" "+_vm._s(Object.keys(_vm.peerConnections).length + 1)+" ")]),_c('i',{staticClass:"bi bi-people-fill"})])]),_c('li',[_c('a',{class:{
            btn: true,
            'btn-primary': true,
            'btn-sm': true,
            'click-here': true,
            disabled: _vm.isEndMeetPopupOpened,
            cursorPointer: true
          },on:{"click":function($event){$event.stopPropagation();return _vm.toggleEndMeeting(true)}}},[_vm._v(" End ")])])])]),(_vm.isEndMeetPopupOpened)?_c('div',{staticClass:"meeting-cta"},[_c('div',{attrs:{"id":"end-meeting"}},[_c('div',{staticClass:"common-modal",attrs:{"id":"modal-end-meeting"}},[_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"modal-header"},[_c('a',{staticClass:"icon-close cursorPointer",on:{"click":function($event){$event.stopPropagation();return _vm.toggleEndMeeting(false)}}},[_c('i',{staticClass:"bi bi-x"})])]),_c('div',{staticClass:"modal-body"},[_c('a',{staticClass:"btn-primary cursorPointer",on:{"click":_vm.endMeetingForAll}},[_vm._v(" End Meeting for All ")]),_c('a',{staticClass:"btn-primary cursorPointer",on:{"click":_vm.leaveMeeting}},[_vm._v(" Leave Meeting ")])])])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }