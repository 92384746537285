var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'cb-dropdown': true,
    'record-dropdown': true,
    'full-width': 'idle' === _vm.recordingState && _vm.recordedChunks.length > 0
  },attrs:{"id":"security-options"},on:{"click":_vm.preventClickAction}},[_c('h6',[_vm._v(" "+_vm._s(_vm.recordText)+" "),_vm._m(0)]),_c('div',{staticClass:"menu"},[_c('ul',{staticClass:"submenu"},[('idle' !== _vm.recordingState)?_c('li',{staticClass:"record-options"},[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){$event.stopPropagation();return _vm.pausePlayRecording.apply(null, arguments)}}},[_c('span',{staticClass:"icon-tick"},[('paused' === _vm.recordingState)?_c('i',{staticClass:"bi bi-record-btn"}):_c('i',{staticClass:"bi bi-pause"})]),_c('span',{staticClass:"list-option"},[_vm._v(" "+_vm._s("paused" === _vm.recordingState ? "Resume" : "Pause")+" ")])])]):_vm._e(),('idle' !== _vm.recordingState)?_c('li',{staticClass:"record-options"},[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){$event.stopPropagation();return _vm.toggleRecording.apply(null, arguments)}}},[_vm._m(1),_c('span',{staticClass:"list-option"},[_vm._v(" Stop ")])])]):_vm._e(),('idle' === _vm.recordingState && _vm.recordedChunks.length > 0)?_c('li',[_c('div',{staticClass:"file-upload-tool"},[_c('a',{staticClass:"upload-link cursorPointer",on:{"click":function($event){$event.stopPropagation();return _vm.uploadRecordFile.apply(null, arguments)}}},[('uploading' === _vm.recordFileUploadStatus)?_c('div',{staticClass:"loader loader-xs loader-fs"}):_c('span',{staticClass:"icon-tick"},[_c('i',{staticClass:"bi bi-arrow-bar-up"})]),_c('span',{staticClass:"list-option"},[_vm._v(" Upload ")])]),(
              'uploading' === _vm.recordFileUploadStatus && _vm.uploadPercentage > 0
            )?_c('span',{staticClass:"file-upload-info"},[_vm._v(" "+_vm._s(_vm.uploadPercentage)+"% ")]):_vm._e()]),_c('div',{staticClass:"file-upload-hint"},[_vm._v(" NOTICE: The recording will not be saved until you upload it. ")])]):_vm._e(),(_vm.fileId > 0)?_c('li',{staticClass:"record-options"},[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){$event.stopPropagation();return _vm.downloadRecording.apply(null, arguments)}}},[(_vm.isRecordFileDownloading)?_c('div',{staticClass:"loader loader-xs loader-fs"}):_c('span',{staticClass:"icon-tick"},[_c('i',{staticClass:"bi bi-download"})]),_c('span',{staticClass:"list-option"},[_vm._v(" Download ")])]),_c('span',{staticClass:"file-upload-info"},[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){$event.stopPropagation();return _vm.downloadRecording.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.recordedFileName)+" ")])])]):_vm._e(),(
          'idle' === _vm.recordingState &&
            'uploading' !== this.recordFileUploadStatus
        )?_c('li',{staticClass:"record-options"},[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){$event.stopPropagation();return _vm.startRecording(true)}}},[_vm._m(2),_c('span',{staticClass:"list-option"},[_vm._v(" Record Again ")])])]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"icon-mobsettings-close cursorPointer"},[_c('i',{staticClass:"bi bi-x icon-dropdown"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon-tick"},[_c('i',{staticClass:"bi bi-stop"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon-tick"},[_c('i',{staticClass:"bi bi-record-btn"})])}]

export { render, staticRenderFns }