import GeneralConstant from "../constant/GeneralConstant";

export const soundMeter = {
  data() {
    return {
      pids: [
        {
          id: 0,
          isActive: false
        },
        {
          id: 1,
          isActive: false
        },
        {
          id: 2,
          isActive: false
        },
        {
          id: 3,
          isActive: false
        },
        {
          id: 4,
          isActive: false
        },
        {
          id: 5,
          isActive: false
        },
        {
          id: 6,
          isActive: false
        },
        {
          id: 7,
          isActive: false
        },
        {
          id: 8,
          isActive: false
        },
        {
          id: 9,
          isActive: false
        }
      ],
      context: null,
      analyser: null,
      script: null,
      volumeLevel: 0.0,
      source: null
    };
  },
  methods: {
    createAudioContext() {
      try {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        this.context = new AudioContext();
      } catch (e) {
        this.$store.dispatch("displayGlobalAlert", {
          type: "alert",
          msg: GeneralConstant.error.webAudioApiNotSupported
        });
      }
    },

    createAnalyser() {
      this.analyser = this.context.createAnalyser();
      this.analyser.smoothingTimeConstant = 0.8;
      this.analyser.fftSize = 1024;
    },

    createScriptProcessor() {
      this.script = this.context.createScriptProcessor(2048, 1, 1);
      this.script.onaudioprocess = event => {
        let array = new Uint8Array(this.analyser.frequencyBinCount);
        this.analyser.getByteFrequencyData(array);

        let values = 0;
        let length = array.length;
        for (let i = 0; i < length; i++) {
          values += array[i];
        }

        this.colorPids(values / length);
      };
    },

    colorPids(vol) {
      for (let i = 0; i < this.pids.length; i++) {
        if (this.pids[i]) {
          this.pids[i].isActive = false;
        }
      }

      for (let i = 0; i < Math.round(vol / this.pids.length); i++) {
        if (this.pids[i]) {
          this.pids[i].isActive = true;
        }
      }
    },

    connectToSource(stream, audioElement) {
      if (stream) {
        this.source =
          this.source || this.context.createMediaStreamSource(stream);
      } else {
        this.source =
          this.source || this.context.createMediaElementSource(audioElement);
      }
      this.source.connect(this.analyser);
      this.source.connect(this.context.destination);
      this.analyser.connect(this.script);
      this.script.connect(this.context.destination);
    },

    disconnect() {
      if (this.source) {
        this.source.disconnect();
      }
      if (this.script) {
        this.script.disconnect();
      }
      if (this.analyser) {
        this.analyser.disconnect();
      }

      for (let i = 0; i < this.pids.length; i++) {
        if (this.pids[i]) {
          this.pids[i].isActive = false;
        }
      }

      this.context = null;
      this.analyser = null;
      this.script = null;
      this.volumeLevel = 0.0;
      this.source = null;
    }
  }
};
