/**
 * Getters for participant
 */
export const getters = {
  /**
   * Return flag which determine if send invitation request is still pending
   *
   * @param {Object} state
   */
  getIsSendInvitationRequestPending(state) {
    return state.isSendInvitationRequestPending;
  },

  /**
   * Return the flag which determine if user list fetched status
   *
   * @param {Object} state
   */
  getUserListFetchStatus(state) {
    return state.userListFetchStatus;
  },

  /**
   * Return the client list
   *
   * @param {Object} state
   */
  getClients(state) {
    return state.clients;
  },

  /**
   * Return the person list
   *
   * @param {Object} state
   */
  getPersons(state) {
    return state.persons;
  },

  /**
   * Return the subscribers list
   *
   * @param {Object} state
   */
  getSubscribers(state) {
    return state.subscribers;
  },

  /**
   * Return the app user list
   *
   * @param {Object} state
   */
  getAppUsers(state) {
    return state.appUsers;
  }
};
