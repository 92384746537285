var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'full-vd-container':
      0 === Object.keys(_vm.peerConnections).length && _vm.fullScreenMode,
    thumb:
      Object.keys(_vm.peerConnections).length > 0 && 'speaker' === _vm.displayView,
    'room-pinned': 'pinned' === _vm.displayView
  },attrs:{"id":"active-pinned-video"}},[_c('div',{staticClass:"ap-video-con"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        !_vm.isCameraStreamReady &&
          !('pinned' === _vm.displayView && 'self' !== _vm.pinnedPeerId)
      ),expression:"\n        !isCameraStreamReady &&\n          !('pinned' === displayView && 'self' !== pinnedPeerId)\n      "}],staticClass:"user-pro-pic"},[_c('img',{ref:"speakerViewImg1",attrs:{"src":require("../../assets/img/loader.gif")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.muteCharacter.length > 0),expression:"muteCharacter.length > 0"}],staticClass:"name-thumb"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.muteCharacter)+" ")])]),_c('video',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayVideo),expression:"displayVideo"}],ref:"speakerViewVideo",class:{
        flipEffect:
          ('speaker' === _vm.displayView ||
            ('pinned' === _vm.displayView && 'self' === _vm.pinnedPeerId)) &&
          _vm.meetDetails.enableMirrorEffect &&
          'camera' === _vm.meetDetails.videoTrackType
      },attrs:{"autoplay":"","muted":"","playsinline":"","id":"speakerViewVideo"},domProps:{"muted":true}}),('pinned' !== _vm.displayView)?[_c('div',{staticClass:"view-options"},[_c('span',{staticClass:"gallery-view-options"},[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){$event.stopPropagation();return _vm.openUserOptionsPopup.apply(null, arguments)}}},[_c('i',{staticClass:"bi-three-dots"})])]),(_vm.openUserOptions)?_c('user-options-vue',{attrs:{"open-rename-popup":_vm.openRenamePopup,"remove-self-view":_vm.removeSelfView}}):_vm._e()],1)]:_vm._e(),_c('div',{staticClass:"mobile-view win-participant"},[_c('span',{staticClass:"participant-name"},[_vm._v(_vm._s(_vm.name))])]),_c('div',{class:{
        'web-view': true,
        'win-participant': true,
        'blue-background': 'pinned' === _vm.displayView
      },on:{"click":_vm.pinParticipant}},[_c('span',{staticClass:"participant-name"},[_vm._v(_vm._s(_vm.name))]),(
          'speaker' === _vm.displayView && Object.keys(_vm.peerConnections).length > 0
        )?_c('span',{staticClass:"pin-btn"},[_c('i',{staticClass:"bi bi-pin-fill"})]):_vm._e()])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }