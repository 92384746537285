<template>
  <footer
    :class="{
      'view-collapsed': isChatPannelOpened || isParticipantPannelOpened,
      'footer-waiting-room': 'waitRoom' === meetDetails.room
    }"
  >
    <div id="cta-control-bar">
      <div class="cta-control-1">
        <ul class="user-av-controls">
          <!-- Section to control microphone/speaker (Audio Devices) -->
          <li
            id="mute-btn"
            :class="{ 'audio-toggle': meetDetails.isUserAudioMuted }"
          >
            <a
              class="link-mute cursorPointer"
              @click="checkAndChangeAudioStatus"
            >
              <i
                :class="{
                  bi: true,
                  'bi-mic-fill': !meetDetails.isUserAudioMuted,
                  'bi-mic-mute-fill': meetDetails.isUserAudioMuted
                }"
                id="mute-icon"
              ></i>
              <span id="mute-text">
                {{ meetDetails.isUserAudioMuted ? "UnMute" : "Mute" }}
              </span>
            </a>
            <!-- <a
              class="audio-control-dropdown cursorPointer"
              @click.stop="toggleDrpDwn('isAudioDrpDwnOpened')"
              v-if="
                !checkIfMobile &&
                  audioInput.length > 0 /* || audioOutput.length > 0*/
              "
            >
              <i class="bi bi-chevron-down icon-dropdown"></i>
            </a>
            <audio-device-vue v-if="isAudioDrpDwnOpened"></audio-device-vue> -->
          </li>

          <!-- Section to control camera(Video Devices) -->
          <li
            id="video-btn"
            :class="{
              'screen-share-mode': 'screen' === meetDetails.videoTrackType
            }"
          >
            <a class="cursorPointer" @click="toggleVideo(null)">
              <i
                :class="{
                  bi: true,
                  'bi-camera-video-fill': !meetDetails.isUserVideoMuted,
                  'bi-camera-video-off-fill': meetDetails.isUserVideoMuted
                }"
                id="start-video-icon"
              ></i>
              <span id="video-text">
                {{ meetDetails.isUserVideoMuted ? "Start" : "Stop" }}
                Video
              </span>
            </a>
            <!-- <a
              class="nav-link video-control-dropdown cursorPointer"
              @click.stop="toggleDrpDwn('isVideoDrpDwnOpened')"
              v-if="!checkIfMobile && videoInput.length > 0"
            >
              <i class="bi bi-chevron-down icon-dropdown"></i>
            </a>
            <video-device-vue v-if="isVideoDrpDwnOpened"></video-device-vue> -->
            <div
              class="icon-overlay"
              v-if="'screen' === meetDetails.videoTrackType"
            ></div>
          </li>

          <!-- User Instruction -->
          <li class="user-instructions" v-if="'waitRoom' === meetDetails.room">
            <p class="web-instructions">
              Use these tools to determine your audio and video status when you
              enter the meeting. They do not affect the participant's audio or
              video settings.
            </p>
          </li>
        </ul>
      </div>

      <div class="cta-control-2" v-if="'meetRoom' === meetDetails.room">
        <ul id="host-pc-controls">
          <!-- Section for settings (host controlling on meet room) -->
          <li v-if="userId === hostInfo.id">
            <a
              class="cursorPointer"
              @click.stop="toggleDrpDwn('isSettingsDrpDwnOpened')"
            >
              <i class="bi bi-gear-fill"></i>
              <span>Settings</span>
            </a>
            <settings-vue
              :toggle-drp-dwn="toggleDrpDwn"
              v-if="isSettingsDrpDwnOpened"
            ></settings-vue>
          </li>

          <!-- Participants Section -->
          <li class="ftr-participant-link">
            <a
              class="nav-participants par-count-holder cursorPointer"
              @click="toggleSidebar({ clickEvent: 'participant' })"
            >
              <span class="participant-count">
                {{ Object.keys(peerConnections).length + 1 }}
              </span>
              <i class="bi bi-people-fill"></i>
              <span>Participants</span>
            </a>
            <a
              @click.stop="toggleDrpDwn('isParticipantDrpDwnOpened')"
              v-if="userId === hostInfo.id"
              class="participants-dropdown cursorPointer"
            >
              <i class="bi bi-chevron-down icon-dropdown"></i>
            </a>
            <div
              class="cb-dropdown"
              id="participants-dropdown-options"
              v-if="isParticipantDrpDwnOpened"
              @click="preventClickAction"
            >
              <ul class="submenu">
                <li>
                  <a class="cursorPointer" @click="openInviteParticipantPopup">
                    <i class="fa fa-chevron-right mr-1"></i> Invite (Ait + i)
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <!-- Chat Section -->
          <li>
            <a
              :class="{
                'nav-chat': true,
                cursorPointer: true,
                'par-count-holder': unreadMsgCount > 0
              }"
              @click="toggleSidebar({ clickEvent: 'chat' })"
            >
              <span class="notify-chat-dot" v-if="unreadMsgCount > 0">
                {{
                  unreadMsgCount > 99 ? `${unreadMsgCount}+` : unreadMsgCount
                }}
              </span>
              <i class="bi bi-chat-square-fill"></i>
              <span>Chat</span>
            </a>
          </li>

          <!-- Screen share Section -->
          <li v-if="checkScreenShareSupport">
            <a class="cursorPointer" @click="checkAndShareScreen">
              <i class="bi bi-tv"></i>
              <span>
                {{
                  "camera" === meetDetails.videoTrackType
                    ? "Share Screen"
                    : "Stop Sharing"
                }}
              </span>
            </a>
          </li>
        </ul>
      </div>

      <!-- End meeting button and record button -->
      <div class="meeting-cta" v-if="'meetRoom' === meetDetails.room">
        <ul class="host-controls">
          <!-- Record Menu -->
          <li v-if="userId === hostInfo.id" class="record-li">
            <div>
              <a class="record cursorPointer" @click="toggleRecording">
                <i
                  :class="{
                    bi: true,
                    'bi-record-btn': true,
                    'red-icon': 'recording' === recordingState
                  }"
                ></i>
              </a>
              <a
                class="participants-dropdown cursorPointer"
                @click.stop="toggleRecordDropDwn"
                v-if="
                  !(
                    'idle' === recordingState &&
                    recordedChunks.length === 0 &&
                    'not-uploaded' === recordFileUploadStatus
                  )
                "
              >
                <i
                  :class="{
                    'red-icon': 'recording' === recordingState,
                    bi: true,
                    'bi-chevron-down': true,
                    'icon-dropdown': true
                  }"
                ></i>
              </a>
            </div>
            <span>
              {{ recordText }}
            </span>
            <record-options-vue
              v-if="isRecordDrpDwnOpened"
              :toggle-recording="toggleRecording"
              :pause-play-recording="pausePlayRecording"
              :start-recording="startRecording"
              :download-recording="downloadRecording"
            ></record-options-vue>
          </li>

          <!-- End Meeting Button -->
          <li>
            <a
              class="btn btn-primary btn-sm click-here cursorPointer"
              @click.stop="toggleEndMeeting(true)"
              :class="{
                disabled: isEndMeetPopupOpened
              }"
            >
              End
            </a>

            <!-- End Meeting Pop-Up -->
            <div
              id="end-meeting"
              v-if="isEndMeetPopupOpened"
              @click="preventClickAction"
            >
              <div class="common-modal" id="modal-end-meeting">
                <div class="modal-container">
                  <div class="modal-header">
                    <a
                      @click.stop="toggleEndMeeting(false)"
                      class="icon-close cursorPointer"
                    >
                      <i class="bi bi-x"></i>
                    </a>
                  </div>
                  <div class="modal-body">
                    <a
                      class="btn-primary cursorPointer"
                      @click="endMeetingForAll"
                    >
                      End Meeting for All
                    </a>
                    <a class="btn-primary cursorPointer" @click="leaveMeeting">
                      Leave Meeting
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- Mobile View Footer Design goes here -->
    <div id="mob-cta-control-bar">
      <div class="cta-control-1">
        <ul class="user-av-controls">
          <li id="mute-btn">
            <a
              class="link-mute cursorPointer"
              @click="checkAndChangeAudioStatus"
            >
              <i
                :class="{
                  bi: true,
                  'bi-mic-fill': !meetDetails.isUserAudioMuted,
                  'bi-mic-mute-fill': meetDetails.isUserAudioMuted
                }"
              ></i>
            </a>
          </li>
          <li id="video-btn">
            <a class="cursorPointer" @click="toggleVideo(null)">
              <i
                :class="{
                  bi: true,
                  'bi-camera-video-fill': !meetDetails.isUserVideoMuted,
                  'bi-camera-video-off-fill': meetDetails.isUserVideoMuted
                }"
              ></i>
            </a>
          </li>
          <li class="user-instructions" v-if="'waitRoom' === meetDetails.room">
            <p class="mob-instructions">
              These tools will determine your audio and video status when you
              enter the meeting.
            </p>
          </li>
          <li v-if="userId === hostInfo.id && 'meetRoom' === meetDetails.room">
            <a
              class="cursorPointer"
              @click.stop="toggleDrpDwn('isSettingsDrpDwnOpened')"
            >
              <i class="bi bi-gear-fill"></i>
            </a>
            <settings-vue
              v-if="isSettingsDrpDwnOpened"
              :toggle-drp-dwn="toggleDrpDwn"
            ></settings-vue>
          </li>
          <li v-if="'meetRoom' === meetDetails.room">
            <a
              :class="{
                'nav-chat': true,
                cursorPointer: true
              }"
              @click="toggleSidebar({ clickEvent: 'chat' })"
            >
              <span class="notify-chat-dot" v-if="unreadMsgCount > 0">
                {{
                  unreadMsgCount > 99 ? `${unreadMsgCount}+` : unreadMsgCount
                }}
              </span>
              <i class="bi bi-chat-square-fill"></i>
            </a>
          </li>
          <li v-if="'meetRoom' === meetDetails.room && checkScreenShareSupport">
            <a class="cursorPointer" @click="checkAndShareScreen">
              <i class="bi bi-arrow-bar-up"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import socket from "../../socket/socket";
import SettingsVue from "./Settings";
import GeneralConstant from "../../constant/GeneralConstant";
import { media } from "../../mixins/Media";
import { checkMobile } from "../../mixins/CheckMobile";
import { endMeeting } from "../../mixins/EndMeeting";
import RecordOptionsVue from "./RecordOptions";
import { record } from "../../mixins/Record";

export default {
  name: "Footer",
  mixins: [media, checkMobile, endMeeting, record],
  props: {
    isChatPannelOpened: Boolean,
    isParticipantPannelOpened: Boolean,
    toggleSidebar: Function
  },
  data() {
    return {
      isSettingsDrpDwnOpened: false,
      isAudioDrpDwnOpened: false,
      isVideoDrpDwnOpened: false,
      isParticipantDrpDwnOpened: false
    };
  },
  components: {
    SettingsVue,
    RecordOptionsVue
  },
  computed: {
    ...mapGetters({
      userId: "getUserId",
      modal: "getModal",
      openedDropdown: "getOpenedDropdown"
    }),

    ...mapGetters("meet", {
      videoInput: "getVideoInput",
      audioInput: "getAudioInput",
      audioOutput: "getAudioOutput",
      hostInfo: "getHostInfo",
      cameraStream: "getCameraStream",
      peerConnections: "getPeerConnections",
      meetDetails: "getMeetDetails",
      rtcRtpSenders: "getRtcRtpSenders",
      videoTrack: "getVideoTrack",
      screenShareStream: "getScreenShareStream",
      isRecordDrpDwnOpened: "getIsRecordDrpDwnOpened",
      isEndMeetPopupOpened: "getIsEndMeetPopupOpened"
    }),

    ...mapGetters("chat", {
      messages: "getMessages"
    }),

    ...mapGetters("record", {
      recordingState: "getRecordingState",
      recordFileUploadStatus: "getRecordFileUploadStatus",
      recordedChunks: "getRecordedChunks"
    }),

    /**
     * Props to check if screen-share is supported on the device
     */
    checkScreenShareSupport() {
      return (
        navigator.mediaDevices &&
        "function" === typeof navigator.mediaDevices.getDisplayMedia
      );
    },

    /**
     * Props to get the number of unread messages
     */
    unreadMsgCount() {
      return this.messages.filter(message => {
        return message.senderId !== this.userId && !message.isRead;
      }).length;
    },

    /**
     * Returns recording text to display on the footer
     */
    recordText() {
      if ("idle" === this.recordingState) {
        return "Start Record";
      } else if (
        "recording" === this.recordingState ||
        "paused" === this.recordingState
      ) {
        return "Recording (Click to stop)";
      } else {
        return "";
      }
    }
  },
  watch: {
    recordingState: function(state) {
      // On recording stop
      if ("idle" === state && this.recordedChunks.length > 0) {
        this.setIsRecordDrpDwnOpened(true);
        this.setOpenedDropdown("isRecordDrpDwnOpened");
      }
    },

    isRecordDrpDwnOpened: function(isOpened) {
      if (!isOpened) {
        if ("upload-file-vue" === this.modal.bodyComponentName) {
          this.setModal({
            display: false
          });
        }
      }
    },

    openedDropdown: function(drpDwnName) {
      let keys = [
        "isAudioDrpDwnOpened",
        "isVideoDrpDwnOpened",
        "isSettingsDrpDwnOpened",
        "isParticipantDrpDwnOpened"
      ];
      keys.forEach(key => {
        if (key !== drpDwnName) {
          this[key] = false;
        }
      });

      if ("isRecordDrpDwnOpened" !== drpDwnName) {
        this.setIsRecordDrpDwnOpened(false);
      }

      if ("isEndMeetPopupOpened" !== drpDwnName) {
        this.setIsEndMeetPopupOpened(false);
      }
    }
  },
  methods: {
    ...mapMutations({
      setModal: "setModal",
      setDisplayModalOverlay: "setDisplayModalOverlay",
      setOpenedDropdown: "setOpenedDropdown"
    }),

    ...mapMutations("meet", {
      setVideoTrack: "setVideoTrack",
      setScreenShareStream: "setScreenShareStream",
      setIsEndMeetPopupOpened: "setIsEndMeetPopupOpened",
      setIsRecordDrpDwnOpened: "setIsRecordDrpDwnOpened"
    }),

    ...mapMutations("record", {
      setRecordingState: "setRecordingState"
    }),

    /**
     * Function to open invite participant pop-up
     */
    openInviteParticipantPopup() {
      this.setModal({
        ...this.modal,
        display: true,
        title: "Invite Participants",
        bodyComponentName: "invite-participant-vue",
        styleOverride: {
          top: "8%"
        }
      });
      this.setDisplayModalOverlay(true);
      this.isParticipantDrpDwnOpened = false;
    },

    /**
     * Function to be triggered on toggling any dropdowns
     */
    toggleDrpDwn(drpDwn) {
      this[drpDwn] = !this[drpDwn];
      this.setOpenedDropdown(this[drpDwn] ? drpDwn : "");
    },

    /**
     * Function to be triggered on click of end meeting button
     */
    toggleEndMeeting(open) {
      if (this.recordFileUploadStatus === "uploading") {
        this.$store.dispatch("displayGlobalAlert", {
          type: "alert",
          msg: GeneralConstant.error.recordFileUploadInProgress
        });
      } else if (
        "paused" === this.recordingState ||
        "recording" === this.recordingState ||
        (this.recordFileUploadStatus === "not-uploaded" &&
          this.recordedChunks.length > 0 &&
          "idle" === this.recordingState)
      ) {
        this.setModal({
          ...this.modal,
          display: true,
          title: "Upload Recording",
          bodyComponentName: "upload-record-popup-confirm-vue",
          styleOverride: {}
        });
        this.setDisplayModalOverlay(true);
      } else {
        // If user is host, display the endMeetPopUp modal, else leave meeting
        if (this.userId === this.hostInfo.id) {
          this.setIsEndMeetPopupOpened(open);
          if (open) {
            this.setOpenedDropdown("isEndMeetPopupOpened");
          }
        } else {
          this.leaveMeeting();
        }
      }
    },

    /**
     * Function to do screen sharing
     */
    async screenShare() {
      // Get stream for screen
      const screenShareStream = await navigator.mediaDevices.getDisplayMedia();

      // Set screen share stream on vuex state
      this.setScreenShareStream(screenShareStream);

      // Set Currently shared video track
      this.setVideoTrack(this.screenShareStream.getTracks()[0]);

      // Change the video track for all rtcRtpSenders
      for (const id in this.rtcRtpSenders) {
        this.rtcRtpSenders[id]
          .find(sender => "video" === sender.track.kind)
          .replaceTrack(this.videoTrack);
      }

      // Change the shared video track name on local state
      this.setMeetDetails({
        ...this.meetDetails,
        videoTrackType: "screen"
      });

      // Listen to event on screenshare end
      this.screenShareStream.getTracks()[0].onended = this.stopSharing;

      // Emit to all other participants regarding video track change status
      socket.io.emit("videoTrackChanged", {
        id: socket.io.id,
        track: "screen"
      });
    },

    /**
     * Function to stop screen sharing
     */
    async stopSharing() {
      // Get stream for Camera
      const videoTrack = this.cameraStream
        .getTracks()
        .find(track => "video" === track.kind);

      // Set Currently shared video track
      this.setVideoTrack(videoTrack);

      // Stop the stream for screen
      this.screenShareStream.getTracks()[0].stop();

      // Share the camera with all existing peer connections
      for (const id in this.rtcRtpSenders) {
        this.rtcRtpSenders[id]
          .find(sender => "video" === sender.track.kind)
          .replaceTrack(videoTrack);
      }

      // Change the shared video track name on local state
      this.setMeetDetails({
        ...this.meetDetails,
        videoTrackType: "camera"
      });

      // Emit to all other participants regarding video track change status
      socket.io.emit("videoTrackChanged", {
        id: socket.io.id,
        track: "camera"
      });
    },

    /**
     * Function check if screen is sharable and share the scrren
     */
    checkAndShareScreen() {
      if ("camera" === this.meetDetails.videoTrackType) {
        // If user is not host, check if user has disabled screen sharing else share screen
        if (this.userId !== this.hostInfo.id) {
          // Checking if screen is sharable
          if ("single-share" === this.meetDetails.screenShare) {
            let isScreenSharedByOtherPeer = false;
            for (const id in this.peerConnections) {
              if ("screen" === this.peerConnections[id].videoTrackType) {
                isScreenSharedByOtherPeer = true;
                break;
              }
            }
            if (!isScreenSharedByOtherPeer) {
              this.screenShare();
            } else {
              this.$store.dispatch("displayGlobalAlert", {
                type: "alert",
                msg: GeneralConstant.error.hostDisabledMultipleScreenShare
              });
            }
          } else {
            this.screenShare();
          }
        } else {
          this.screenShare();
        }
      } else {
        this.stopSharing();
      }
    },

    /**
     * Function to start/stop recording based on user interaction
     */
    toggleRecording() {
      if ("idle" === this.recordingState) {
        if ("uploading" !== this.recordFileUploadStatus) {
          this.startRecording(true);
        }
      } else {
        this.stopRecording();
      }
    },

    /**
     * Prevents click action
     */
    preventClickAction(event) {
      event.stopPropagation();
    },

    /**
     * Function to toggles record drop down
     */
    toggleRecordDropDwn() {
      this.setIsRecordDrpDwnOpened(!this.isRecordDrpDwnOpened);
      if (this.isRecordDrpDwnOpened) {
        this.setOpenedDropdown("isRecordDrpDwnOpened");
      }
    }
  }
};
</script>

<style scoped>
/*===Footer===*/
footer {
  background: #000;
  position: fixed;
  height: 60px;
  bottom: 0;
  width: 100%;
  left: 0;
  /* display: block !important; */
  z-index: 99;
}

footer a {
  color: #fff;
}
*:focus {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

#cta-control-bar {
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  height: 100%;
}

.cta-control-1 {
  display: flex;
  justify-content: flex-start;
}

.cta-control-2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.meeting-cta {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
  text-align: right;
}

.user-av-controls {
  display: flex;
  margin: 0;
  padding: 0 0 0 20px;
  justify-content: center;
  align-items: center;
}

.user-av-controls li {
  display: inline-block;
  margin: 0 20px 0 0;
  position: relative;
}
.user-av-controls li:last-child {
  margin-right: 0;
}
.user-av-controls li a {
  font-size: 12px;
  line-height: 18px;
  color: #e6e6e6;
}

.user-av-controls li a i {
  display: block;
  text-align: center;
  font-size: 22px;
  color: #eeb747;
}

.user-av-controls li a i.icon-dropdown {
  position: absolute;
  top: 10px;
  right: 0;
  font-size: 11px;
}

.user-av-controls li a.audio-control-dropdown,
.user-av-controls li a.video-control-dropdown {
  position: absolute;
  left: 40px;
  top: -5px;
}

.user-av-controls li a.video-control-dropdown {
  left: 60px;
}

.user-av-controls li#mute-btn.audio-toggle a.audio-control-dropdown {
  left: 50px;
}

.user-av-controls li .icon-overlay {
  position: absolute;
  top: 0;
  left: 0px;
  width: 90px;
  height: 47px;
  background: rgba(000, 000, 000, 0.9);
  z-index: 99999999;
}

/*---Host PC Control--*/
#host-pc-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

#host-pc-controls li {
  display: inline-block;
  margin: 0px 40px 0 0;
  position: relative;
}

#host-pc-controls li:last-child {
  margin-right: 0;
}

#host-pc-controls li a {
  color: #fff;
  padding: 0;
  font-size: 12px;
  line-height: 18px;
  color: #e6e6e6;
  position: relative;
}

#host-pc-controls li a i {
  display: block;
  text-align: center;
  font-size: 22px;
  color: #eeb747;
}

#host-pc-controls li a i.icon-dropdown {
  position: absolute;
  top: 10px;
  right: 0;
  font-size: 11px;
}

#host-pc-controls li a.security-dropdown,
#host-pc-controls li a.participants-dropdown,
#host-pc-controls li a.chat-dropdown {
  position: absolute;
  left: 40px;
  top: -5px;
}

#host-pc-controls li a.security-dropdown {
  left: 55px;
}

#host-pc-controls li a.participants-dropdown {
  left: 75px;
}

.host-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.host-controls li {
  display: inline-block;
  margin: 0px 20px 0 0;
  position: relative;
}

.host-controls li:last-child {
  margin-right: 0;
}

.host-controls li.record-li {
  padding: 0;
  font-size: 12px;
  line-height: 18px;
  color: #e6e6e6;
  position: relative;
  text-align: center;
}

.host-controls li.record-li div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.host-controls li.record-li div a i {
  display: block;
  text-align: center;
  font-size: 22px;
  color: #eeb747;
}

.host-controls li.record-li div a:last-child {
  margin-left: 5px;
  position: relative;
}

.host-controls li.record-li div a:last-child i.icon-dropdown {
  font-size: 11px;
  position: absolute;
  top: -7px;
}

.red-icon {
  color: #ff0000 !important;
}

.nav-participants {
  position: relative;
}

.par-count-holder {
  position: relative;
  display: inline-block;
}

.participant-count {
  position: absolute;
  left: 45px;
  top: -5px;
  font-size: 11px;
  line-height: 12px;
}

li span.notify-chat-dot {
  position: absolute;
  left: 30px;
  top: -7px;
  font-size: 11px;
  line-height: 12px;
}

#participants-dropdown-options.show {
  display: block;
}

.screen-share-mode {
  pointer-events: none;
  cursor: none;
}

.footer-waiting-room #cta-control-bar {
  justify-content: center;
}

.footer-waiting-room #cta-control-bar .cta-control-1 .user-av-controls {
  padding: 0px;
}

/* End Meeting Style Start */
#end-meeting {
  position: absolute;
  bottom: 213px;
  right: 460px;
  padding: 0;
  text-align: center;
}

#modal-end-meeting {
  background: #282828;
  width: 420px;
  padding: 20px 0;
}

#modal-end-meeting .modal-header {
  position: relative;
  padding-top: 0;
}

#modal-end-meeting .modal-header a.icon-close i {
  color: #fff;
  font-size: 30px;
  position: absolute;
  right: 10px;
  top: -20px;
}

#modal-end-meeting .modal-body {
  padding: 10px;
  margin-top: 30px;
}

#modal-end-meeting .modal-body a {
  margin: 0px 10px 0;
}

li.user-instructions {
  font-size: 14px;
  color: #fff;
  max-width: 500px;
}

/* li.user-instructions p {
  position: relative;
  margin: 0;
  top: -6px;
} */

em.blue-dot {
  left: 30px;
  top: -5px;
  position: absolute;
}

li.user-instructions p.web-instructions {
  display: block;
}

/* End Meeting Style End */
/* @media screen and (max-width: 768px) {
  #cta-control-bar span {
    display: none;
  }
  .user-av-controls {
    padding: 0;
  }
  .user-av-controls li:first-child {
    margin-right: 40px;
  }
  .user-av-controls li#mute-btn.audio-toggle a.audio-control-dropdown {
    left: 40px;
  }
  .user-av-controls li,
  #host-pc-controls li {
    margin-top: 20px;
  }
  .user-av-controls li a.video-control-dropdown {
    left: 45px;
  }
  #host-pc-controls li a.participants-dropdown {
    left: 45px;
  }
  #cta-control-bar {
    justify-content: center;
    width: 98%;
  }
  #host-pc-controls,
  .user-av-controls {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  #host-pc-controls li.ftr-participant-link {
    display: none;
  }
  .user-av-controls li:last-child {
    margin-right: 60px;
  }
  .cta-control-1 {
    width: 50%;
  }
  .cta-control-2 {
    width: 50%;
  }
  #host-pc-controls li {
    margin-right: 60px;
  }
  .meeting-cta {
    display: block !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .meeting-cta a.btn.btn-primary.btn-sm.click-here {
    display: none;
  }

  #modal-end-meeting {
    width: 90%;
    right: 320px;
    bottom: 400px;
  }
  li.user-instructions p.mob-instructions {
    display: block;
  }
  li.user-instructions p.web-instructions {
    display: none;
  }
  .footer-waiting-room .cta-control-1 {
    width: 90%;
  }
  .footer-waiting-room li#mute-btn {
    margin-right: 0px;
  }
  .footer-waiting-room li#video-btn {
    margin-right: 10px;
  }
  footer {
    position: fixed;
    left: 0;
    bottom: 0;
  }
  #active-pinned-video[data-v-610d756c] {
    max-width: 440px;
    margin: 0 auto;
  }
  body {
    background: #000;
  }
} */

/* @media screen and (min-width: 641px) {
  #mob-cta-control-bar {
    display: none;
  }
} */
#mob-cta-control-bar {
  display: none;
}
@media screen and (max-width: 768px),
  screen and (orientation: landscape) and (max-height: 425px) {
  #cta-control-bar {
    display: none;
  }

  #mob-cta-control-bar {
    display: block;
  }

  #mob-cta-control-bar {
    width: 100%;
    height: 60px;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
  }

  #mob-cta-control-bar .cta-control-1 {
    width: 100%;
    height: 100%;
  }

  #mob-cta-control-bar .cta-control-1 .user-av-controls {
    width: 100%;
    padding: 0px;
    justify-content: space-around;
  }

  #mob-cta-control-bar .cta-control-1 .user-av-controls li {
    margin: 0px;
  }

  .footer-waiting-room #mob-cta-control-bar .cta-control-1 .user-av-controls {
    justify-content: center;
  }

  .footer-waiting-room
    #mob-cta-control-bar
    .cta-control-1
    .user-av-controls
    li {
    margin-right: 15px;
  }

  .footer-waiting-room
    #mob-cta-control-bar
    .cta-control-1
    .user-av-controls
    li:last-child {
    margin-right: 0px;
  }

  #mob-cta-control-bar .cta-control-1 .user-av-controls .user-instructions {
    max-width: 75%;
  }

  #mob-cta-control-bar .cta-control-1 .user-av-controls li.user-instructions p {
    top: 0px;
    font-size: 13px;
  }

  li span.notify-chat-dot {
    left: 27px;
  }
}

@media screen and (orientation: landscape) and (max-height: 425px) {
  footer {
    z-index: 111;
  }
}

/* @media screen and (min-width: 321px) and (max-width: 375px) {
  .user-av-controls li:first-child {
    margin-right: 25px;
  }
  .user-av-controls li:last-child {
    margin-right: 25px;
  }
  #host-pc-controls li {
    margin-right: 25px;
  }
}

@media screen and (max-width: 320px) {
  .user-av-controls li:first-child {
    margin-right: 40px;
  }
  .user-av-controls li:last-child {
    margin-right: 40px;
  }
  #host-pc-controls li {
    margin-right: 40px;
  }
} */
</style>
