import axios from "axios";

/**
 * Actions for record
 */
export const actions = {
  /**
   * Request to get the post policy for uploading the record file
   *
   * @param {Object} context
   * @param {Object} timezone
   * @param {Object} fileSize
   * @param {Object} fileName
   */
  async getRecordUploadPostPolicy(context, data) {
    // Make http request
    const json = await context.dispatch(
      "makeHttpRequest",
      {
        url: `file/getPostPolicy`,
        method: "POST",
        data: {
          ...data
        }
      },
      {
        root: true
      }
    );

    return json;
  },

  /**
   * Upload recording to AWS
   *
   * @param {Object} context
   * @param {Object} formData
   * @param {String} url
   */
  async uploadRecordingFile(context, { formData, url }) {
    let res = null;
    try {
      // Make http request
      res = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          accept: "application/json"
        },
        onUploadProgress: event => {
          const position = event.loaded || event.position;
          const percent = Math.ceil((position / event.total) * 100);
          context.commit("setUploadPercentage", percent);
        }
      });
    } catch (err) {
      // Handle error
      res = err;
    }

    return res;
  },

  /**
   * Download recording from server
   *
   * @param {Object} context
   */
  async downloadRecordingFile(context) {
    return new Promise(function(resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        `${process.env.VUE_APP_MAINSITE_URL}/downloadConnectFiles.php?fileId=${
          context.state.fileId
        }&encryptedProviderId=${encodeURIComponent(
          context.rootState.encryptedProviderId
        )}`,
        true
      );
      xhr.responseType = "blob";
      xhr.onload = () => {
        resolve(xhr.response);
      };
      xhr.onerror = err => {
        reject(err);
      };
      xhr.send();
    });
  },

  /**
   * Request to update the uploaded file status
   *
   * @param {Object} context
   * @param {Object} data
   */
  async updateUploadStatus(context, data) {
    // Make http request
    const json = await context.dispatch(
      "makeHttpRequest",
      {
        url: `file/updateUploadStatus`,
        method: "PUT",
        data: {
          ...data
        }
      },
      {
        root: true
      }
    );

    return json;
  }
};
