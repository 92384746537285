<template>
  <div class="common-modal" id="speaker-setting-m1">
    <div class="modal-container">
      <!-- Test Audio to play -->
      <audio
        src="../../assets/audio/test.mp3"
        id="audio"
        type="audio/mpeg"
        class="hide"
        controls
      ></audio>

      <div class="modal-header">
        <h4>Do you hear a ringtone?</h4>
        <a @click="hideSpeakerTestPopup" class="icon-close cursorPointer">
          <i class="bi bi-x"></i>
        </a>
      </div>

      <div class="modal-body">
        <form action="#">
          <template v-if="audioOutput.length > 0">
            <label id="user-speaker-opt" class="dis-bl">Select speaker</label>
            <div class="custom-select sel-medium">
              <select
                id="user-speaker-opt"
                ref="speakerList"
                @change="changeSpeaker()"
              >
                <option
                  :value="output.key"
                  v-for="output in audioOutput"
                  :key="output.key"
                  :selected="output.key === selectedAudioOutput"
                >
                  {{ output.value }}
                </option>
              </select>
            </div>
          </template>
          <div class="pids-wrapper">
            <h5>Output Level</h5>
            <div
              v-for="pid in pids"
              :class="{ pid: true, active: pid.isActive }"
              :key="pid.id"
            ></div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <div class="modal-f-ctas">
          <a
            @click="hideSpeakerTestPopup"
            class="btn-primary-outline mr-1 cursorPointer"
          >
            Yes
          </a>
          <a
            @click="toggleSpeakerTesting(!testSpeaker)"
            class="btn-primary ds-bl mt-2 cursorPointer"
          >
            {{ testSpeaker ? "Stop" : "Start" }} Testing
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { soundMeter } from "../../mixins/SoundMeter";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SpeakerTest",
  mixins: [soundMeter],
  data() {
    return {
      testSpeaker: false,
      isSpeakerReadyForTesting: true
    };
  },
  computed: {
    ...mapGetters("meet", {
      selectedAudioOutput: "getSelectedAudioOutput",
      audioOutput: "getAudioOutput"
    })
  },
  methods: {
    /**
     * Function to toggle speaker testing
     */
    toggleSpeakerTesting(test) {
      const audioElement = document.getElementById("audio");
      if (test) {
        if (this.isSpeakerReadyForTesting) {
          audioElement.currentTime = 0;
          this.testSpeaker = true;
          audioElement.play();
        }
      } else {
        audioElement.pause();
        this.testSpeaker = false;
      }
    },

    /**
     * Function to change the selected speaker
     */
    async changeSpeaker() {
      if (this.isSpeakerReadyForTesting) {
        this.isSpeakerReadyForTesting = false;
        this.toggleSpeakerTesting(false);
        await document
          .getElementById("audio")
          .setSinkId(this.$refs.speakerList.value);
        this.isSpeakerReadyForTesting = true;
      }
    },

    /**
     * Function to hide the popup for speaker test
     */
    hideSpeakerTestPopup() {
      this.$emit("hideSpeakerTestPopup");
    }
  },
  async mounted() {
    // Event Listener on audio play. Showing the speaker level
    document.getElementById("audio").onplay = () => {
      let audioElement = document.getElementById("audio");
      this.disconnect();
      this.createAudioContext();
      this.createAnalyser();
      this.createScriptProcessor();
      this.connectToSource(
        navigator.userAgent.indexOf("Firefox") > -1
          ? audioElement.mozCaptureStream()
          : audioElement.captureStream(),
        null
      );
    };

    // Event Listener on audio playing completed
    document.getElementById("audio").addEventListener("ended", event => {
      this.testSpeaker = false;
    });
  }
};
</script>

<style scoped>
#speaker-setting-m1.common-modal {
  z-index: 4;
  /*--max-width: 360px;--*/
  position: relative;
  left: unset;
  width: 32%;
  margin-right: 1%;
  height: fit-content;
}
#speaker-setting-m1.common-modal .custom-select {
  margin-left: 0;
}

#speaker-setting-m1.common-modal .custom-select.sel-medium select {
  max-width: 100%;
}
#speaker-setting-m1.common-modal form {
  overflow-x: hidden;
}
@media screen and (max-width: 768px) {
  #speaker-setting-m1 {
    display: none;
  }
}
</style>
