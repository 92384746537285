/**
 * Getters for record
 */
export const getters = {
  /**
   * Returns AudioContext
   *
   * @param {Object} state
   */
  getAudioContext(state) {
    return state.audioContext;
  },

  /**
   * Returns composed stream
   *
   * @param {Object} state
   */
  getComposedStream(state) {
    return state.composedStream;
  },

  /**
   * Returns recordingState
   *
   * @param {Object} state
   */
  getRecordingState(state) {
    return state.recordingState;
  },

  /**
   * Returns Audio Destination Node
   *
   * @param {Object} state
   */
  getAudioDestinationNode(state) {
    return state.audioDestinationNode;
  },

  /**
   * Returns Media Recorder
   *
   * @param {Object} state
   */
  getMediaRecorder(state) {
    return state.mediaRecorder;
  },

  /**
   * Returns record stream
   *
   * @param {Object} state
   */
  getRecordedScreenStream(state) {
    return state.recordedScreenStream;
  },

  /**
   * Returns record file upload status
   *
   * @param {Object} state
   */
  getRecordFileUploadStatus(state) {
    return state.recordFileUploadStatus;
  },

  /**
   * Returns flag to determine whether record file is getting downloaded
   *
   * @param {Object} state
   */
  getIsRecordFileDownloading(state) {
    return state.isRecordFileDownloading;
  },

  /**
   * Returns recorded file name
   *
   * @param {Object} state
   */
  getRecordedFileName(state) {
    return state.recordedFileName;
  },

  /**
   * Returns percentage of video stream uploaded to server
   *
   * @param {Object} state
   */
  getUploadPercentage(state) {
    return state.uploadPercentage;
  },

  /**
   * Returns the file downloadable link
   *
   * @param {Object} state
   */
  getFileId(state) {
    return state.fileId;
  },

  /**
   * Returns the video chunks recorded so far
   *
   * @param {Object} state
   */
  getRecordedChunks(state) {
    return state.recordedChunks;
  },

  /**
   * Returns the flag to check for first time recording
   *
   * @param {Object} state
   */
  getFirstTimeRecord(state) {
    return state.firstTimeRecord;
  }
};
