<template>
  <div class="verify-code">
    <div v-if="showLoader" class="loader"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import GeneralConstant from "../../constant/GeneralConstant";

export default {
  name: "VerifyConnectCode",
  data() {
    return {
      showLoader: false
    };
  },
  methods: {
    ...mapActions("participant", {
      getConnectCodeDetails: "getConnectCodeDetails"
    })
  },
  async created() {
    // Clear session storage
    if (
      window.sessionStorage.getItem("connectCode") &&
      window.sessionStorage.getItem("connectCode").length > 0 &&
      this.$route.params.connectCode !==
        window.sessionStorage.getItem("connectCode")
    ) {
      sessionStorage.clear();
    }

    // Show Loader
    this.showLoader = true;

    // Fetch connect code details
    const connectCodeDetails = await this.getConnectCodeDetails({
      connectCode: this.$route.params.connectCode
    });

    // Hide Loader
    this.showLoader = false;

    if ("0" === connectCodeDetails.reasonCode) {
      // Storing connect code on session storage
      window.sessionStorage.setItem(
        "connectCode",
        this.$route.params.connectCode
      );

      // Store isGuestUser flag on session storage
      if (connectCodeDetails.meetResponse.isGuestUser) {
        window.sessionStorage.setItem("isGuestUser", true);
      }

      // Redirect to meet link validation page.
      this.$router.push({
        name: "Home",
        params: {
          meetCode: connectCodeDetails.meetResponse.meetCode,
          passCode: connectCodeDetails.meetResponse.passCode
        },
        query: { ...this.$router.currentRoute.query }
      });
    } else {
      // Display error
      this.$store.dispatch("displayGlobalAlert", {
        type: "error",
        msg:
          1014 === connectCodeDetails.error.code
            ? GeneralConstant.error.invalidURL
            : connectCodeDetails.error.message
      });
    }
  }
};
</script>

<style scoped>
.verify-code {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
