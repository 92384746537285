<template>
  <div
    id="chat-container"
    :class="{
      'full-panel': true,
      'wait-room': 'waitRoom' === meetDetails.room
    }"
  >
    <div class="sideblock-container">
      <!-- Chat Header -->
      <div class="side-topbar">
        <h4>Chat</h4>
        <div class="side-topbar-dropdown">
          <a
            class="cursorPointer"
            @click="toggleSidebar({ clickEvent: 'chat', isClose: true })"
          >
            <i class="bi bi-x icon-dropdown icon-sidepanel-close"></i>
          </a>
        </div>
      </div>

      <div class="side-topbar-content">
        <!-- Section listing chat conversations -->
        <Message-list-vue :toggle-dropdown="toggleDropdown"> </Message-list-vue>

        <!-- Icon to show a dropdown indicator  -->
        <div
          id="unread-chat-con"
          :class="{
            'wait-room': 'waitRoom' === meetDetails.room
          }"
          v-if="showGoDown"
          @click="scrollChatMessagesToBottom"
        >
          <a class="icon-msg-scroll cursorPointer"></a>
          <span class="msg-count" v-if="unreadMsgCount > 0">
            {{ unreadMsgCount }}
          </span>
        </div>

        <!-- Chat Input text box -->
        <div
          id="chat-content"
          :class="{
            'chat-disabled': !meetDetails.enableChat && userId !== hostInfo.id
          }"
        >
          <span
            id="chat-warning"
            v-if="userId !== hostInfo.id && !meetDetails.enableChat"
          >
            Sorry! The host has disabled the chat.
          </span>
          <template v-else>
            <div class="chat-header">
              <div class="cls">
                <div class="to">
                  To:
                  <div class="custom-select">
                    <select v-model="receiverId">
                      <option value="all" class="bold">
                        Everyone
                      </option>
                      <option
                        v-for="user in chatUsers"
                        :value="user.userId"
                        :key="user.userId"
                        v-html="
                          `${user.userName.firstName} ${user.userName.lastName}`
                        "
                      >
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="crs"></div>
              <div class="clear"></div>
            </div>
            <div class="chat-footer">
              <input
                v-on:keyup.enter="sendMessage"
                type="text"
                v-model="composeMessage"
                placeholder="Type message here..."
                ref="chatInputBox"
              />

              <a @click.prevent="sendMessage" class="enter-chat cursorPointer">
                <i class="bi bi-arrow-right"></i>
              </a>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import socket from "../../socket/socket";
import { mapGetters, mapMutations } from "vuex";
import MessageListVue from "./MessageList";
import { bus } from "../../main";
import { sanitizeAndFormat } from "../../mixins/sanitizeAndFormat";
import moment from "moment-timezone";

export default {
  name: "Chat",
  mixins: [sanitizeAndFormat],
  data() {
    return {
      composeMessage: null,
      showGoDown: false,
      receiverId: "all"
    };
  },
  components: {
    MessageListVue
  },
  computed: {
    ...mapGetters({
      userName: "getUserName",
      userId: "getUserId"
    }),

    ...mapGetters("meet", {
      meetDetails: "getMeetDetails",
      participantList: "getParticipantList",
      hostInfo: "getHostInfo"
    }),

    ...mapGetters("chat", {
      messages: "getMessages"
    }),

    /**
     * Computed property to get the number of unread messages.
     */
    unreadMsgCount() {
      const a = this.messages.filter(
        message => message.senderId !== this.userId && !message.isRead
      );

      return a.length;
    },

    /**
     * Returns the user list to display on chat room
     */
    chatUsers() {
      let userList = [];
      if (this.userId === this.hostInfo.id) {
        userList = this.participantList.filter(participant => {
          return {
            userId: participant.userId,
            userName: {
              ...participant.userName
            }
          };
        });
      } else {
        userList = [
          {
            userId: this.hostInfo.id,
            userName: {
              firstName: this.hostInfo.name.firstName,
              lastName: `${this.hostInfo.name.lastName} (Host)`
            }
          }
        ];
      }

      // Remove duplicate items from the array
      let uniqueObject = {};
      for (let i in userList) {
        uniqueObject[userList[i]["userId"]] = userList[i];
      }
      let newArray = [];
      for (let i in uniqueObject) {
        if (uniqueObject[i].userId !== this.userId) {
          newArray.push(uniqueObject[i]);
        }
      }

      return newArray;
    }
  },
  props: {
    toggleSidebar: Function
  },
  methods: {
    ...mapMutations("chat", {
      setMessages: "setMessages"
    }),

    /**
     * Method to toggle the showGoDown value
     */
    toggleDropdown(val) {
      this.showGoDown = val;
    },

    /**
     * Function to broadcast text messages to all users
     */
    sendMessage() {
      if (this.composeMessage && this.composeMessage.trim().length > 0) {
        let msgData = {
          userName: this.userName,
          senderId: this.userId,
          receiverId: this.receiverId,
          message: this.composeMessage,
          sentTime: moment.utc(),
          isRead: true
        };

        // Adding message to list of messages
        this.setMessages([
          ...this.messages,
          { ...msgData, id: new Date().getTime() }
        ]);

        // Emitting the chat message to participants
        socket.io.emit("textMsg", msgData);

        // Resetting compose message on text area
        this.composeMessage = null;

        // Scrolling the chat message to bottom of list
        bus.$emit("scrollChatMessagesToBottom", "sendMessage", true);
      }
      this.$refs.chatInputBox.focus();
    },

    /**
     * Emit event to scroll the chat messages to bottom
     */
    scrollChatMessagesToBottom() {
      bus.$emit("scrollChatMessagesToBottom", null);
    }
  }
};
</script>

<style scoped>
/*---Chat Block---*/
.error {
  color: #c13e3e;
}

#chat-container {
  /* display: none; */
}
#chat-container.full-panel .side-topbar-content {
  padding: 5px 0;
  /* Changes for chat */
  /* height: calc(100vh - 110px); */
  height: calc(100% - 50px);
}
#chat-container.half-panel .side-topbar-content {
  height: calc(50vh - 50px);
}
.chat-header {
  padding: 0;
  margin: 10px 0 0 0;
}
.chat-header p {
  margin: 5px 0;
  color: #000;
}
.cls {
  float: left;
}
.crs {
  float: right;
}
.cls .custom-select {
  min-width: 230px;
}
.cls .custom-select select {
  min-width: 230px;
  /* Changes for chat */
  max-width: 230px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 21px;
}
#chat-content {
  position: absolute;
  bottom: 0;
  border-top: 1px solid #ccc;
  width: 100%;
  padding: 0 15px;
  background: #fff;
  max-width: 300px;
  left: 0;
  width: calc(100% - 30px);
}
#chat-content i {
  color: #fff;
  -webkit-text-stroke: 1.5px;
}
.chat-footer {
  margin-bottom: 10px;
  margin-top: 5px;
  display: flex;
}
.chat-footer input {
  padding: 5px 10px !important;
  width: 100%;
  padding: 5px 0;
  max-width: 260px;
  border: none !important;
  border: 1px solid #ccc !important;
  color: #333;
  line-height: 24px;
  width: calc(100% - 70px) !important;
}
.chat-footer a.enter-chat {
  display: inline-block;
  width: 36px;
  height: 36px;
  background: chocolate;
  border-radius: 50%;
  vertical-align: middle;
  margin-left: 10px;
  color: #fff;
  top: 0px !important;
}
.chat-footer a.enter-chat i {
  color: #fff;
  position: relative;
  top: 4px;
  left: 8px;
}
.chat-footer input:focus {
  border: 1px solid #888 !important;
  outline: 0;
}
.btn-chat-everyone {
  padding: 2px 10px;
}
#chat-container.minified-panel .side-topbar-content {
  display: none;
}
#chat-container.maximized-panel .custom-scroll-v2 {
  /*--max-height: 62vh;--*/
  max-height: calc(100vh - 150px) !important;
  min-height: calc(100vh - 150px) !important;
}
#unread-chat-con {
  position: absolute;
  right: 20px;
  bottom: 90px;
}
.un-msg-count {
  background: #888;
  font-size: 9px;
  padding: 1px 10px;
  border-radius: 10px;
  z-index: 3;
  position: relative;
  left: 0px;
  top: 5px;
}
a.icon-msg-scroll {
  width: 45px;
  height: 45px;
  display: block;
  background: url("../../assets/img/icon-msg-scroll.png");
  position: relative;
  z-index: 1;
}
#chat-warning {
  color: #f16363;
  padding: 3px 10px;
  border: 1px solid #f16363;
  position: relative;
  top: 10px;
  font-size: 13px;
  display: block;
  font-weight: bold;
}
/* Changes for chat */
#chat-content.chat-disabled {
  bottom: 60px !important;
}
.msg-count {
  position: absolute;
  display: inline-block;
  background: #d26d13;
  padding: 5px 10px;
  top: 0;
  font-size: 12px;
  text-align: center;
  line-height: 21px;
  color: #fff;
  top: -15px;
  border-radius: 50%;

  padding: 0;
  width: 20px;
  height: 20px;
  font-size: 10px;
  right: 0px;
  z-index: 9;
}
.bold {
  font-weight: bold;
}
@media screen and (max-width: 768px),
  /* Changes for chat */screen and (orientation: landscape) and (max-height: 425px) {
  #chat-content {
    bottom: 0;
    max-width: 100%;
    /* For mobile view it's made fixed, as issue was coming on iphone */
    /*position: fixed;*/
    /* Above is commented as position: fixed is removed from chat-content section */
  }
  .chat-footer input {
    max-width: calc(100% - 90px) !important;
  }
  #chat-container.full-panel .side-topbar-content {
    padding: 5px 0;
    /* For mobile height is made 100%, as issue was coming on iphone */
    /* height: calc(100vh - 119px); */
    /* height: 100%; */
    /* Above is commented as position: fixed is removed from chat-content section */
  }
  .cls .custom-select {
    min-width: 214px;
  }
  .cls .custom-select select {
    min-width: 214px;
    /* Changes for chat */
    max-width: 214px;
  }
  /* Changes for chat */
  #unread-chat-con {
    /* For mobile view it's made fixed, as issue was coming on iphone */
    /* position: fixed; */
    /* Above is commented as position: fixed is removed from chat-content section */
  }
}
</style>
