<template>
  <div
    ref="screenContainer"
    :class="{
      'participant-screen-container': true,
      'room-pinned': 'pinned' === displayView
    }"
  >
    <ul
      id="participant-list"
      :class="{
        'gallery-view': 'gallery' === displayView,
        'speaker-view': 'speaker' === displayView,
        'hide-self-view': meetDetails.hideSelfView
      }"
    >
      <li
        v-show="
          ('gallery' === displayView || 'pinned' === displayView) &&
            !meetDetails.hideSelfView
        "
      >
        <div class="participant-quickview">
          <!-- Show loader when camera stream is not yet loaded -->
          <img
            v-show="!isCameraStreamReady"
            src="../../assets/img/loader.gif"
          />

          <!-- Play user video -->
          <video
            :class="{
              flipEffect:
                'camera' === meetDetails.videoTrackType &&
                meetDetails.enableMirrorEffect
            }"
            autoplay
            muted
            playsinline
            ref="localVideo"
            id="localVideo"
            v-show="
              isCameraStreamReady &&
                ('screen' === meetDetails.videoTrackType ||
                  ('camera' === meetDetails.videoTrackType &&
                    !meetDetails.isUserVideoMuted))
            "
          ></video>

          <!-- Show first character of first name and last name on mute -->
          <div
            class="name-thumb"
            v-show="
              isCameraStreamReady &&
                'camera' === meetDetails.videoTrackType &&
                meetDetails.isUserVideoMuted
            "
          >
            <div class="name">
              {{
                `${userName.firstName ? userName.firstName[0] : ""}${
                  userName.lastName ? userName.lastName[0] : ""
                }`.toUpperCase()
              }}
            </div>
          </div>
        </div>

        <!-- User Options -->
        <div class="view-options">
          <span class="gallery-view-options">
            <a class="cursorPointer" @click.stop="openUserOptions = true">
              <i class="bi-three-dots"></i>
            </a>
          </span>
          <user-options-vue
            v-if="openUserOptions"
            :open-rename-popup="openRenamePopup"
            :remove-self-view="removeSelfView"
          >
          </user-options-vue>
        </div>

        <div class="mobile-view win-participant">
          <span class="participant-name">
            {{
              `${userName.firstName}${
                userName.middleName ? " " + userName.middleName : ""
              }${userName.lastName ? " " + userName.lastName : ""}`
            }}
          </span>
        </div>
        <div
          :class="{
            'web-view': true,
            'win-participant': true,
            'blue-background': pinnedPeerId === 'self'
          }"
          @click="pinParticipant('self')"
        >
          <span class="participant-name">
            {{
              `${userName.firstName}${
                userName.middleName ? " " + userName.middleName : ""
              }${userName.lastName ? " " + userName.lastName : ""}`
            }}
          </span>
          <span class="pin-btn" v-if="pinnedPeerId !== 'self'">
            <i class="bi bi-pin-fill"></i>
          </span>
        </div>
      </li>

      <li v-for="(connection, key) in peerConnections" :key="key" :id="key">
        <div class="participant-quickview">
          <!-- Showing connecting image, when connection is not yet established-->
          <img
            v-show="
              'new' === connection.iceState ||
                'checking' === connection.iceState ||
                'permanentlyDisconnected' === connection.iceState
            "
            src="../../assets/img/connecting-blank.png"
            class="connecting-img"
          />

          <!-- Playing peer video -->
          <video
            autoplay
            playsinline
            v-show="
              ('connected' === connection.iceState ||
                'completed' === connection.iceState) &&
                ('screen' === connection.videoTrackType ||
                  ('camera' === connection.videoTrackType &&
                    !connection.isVideoMuted))
            "
            :ref="`remoteVideo_${key}`"
            :id="`remoteVideo_${key}`"
          ></video>

          <!-- Show first character of first name and last name on mute -->
          <div
            class="name-thumb"
            v-show="
              (('connected' === connection.iceState ||
                'completed' === connection.iceState) &&
                'camera' === connection.videoTrackType &&
                connection.isVideoMuted) ||
                'failed' === connection.iceState ||
                'disconnected' === connection.iceState
            "
          >
            <div class="name">
              {{
                `${
                  connection.userName.firstName
                    ? connection.userName.firstName[0]
                    : ""
                }${
                  connection.userName.lastName
                    ? connection.userName.lastName[0]
                    : ""
                }`.toUpperCase()
              }}
            </div>
          </div>
        </div>

        <div class="mobile-view win-participant">
          <span
            class="participant-name"
            v-html="
              `${connection.userName.firstName}${
                connection.userName.middleName
                  ? ' ' + connection.userName.middleName
                  : ''
              }${
                connection.userName.lastName
                  ? ' ' + connection.userName.lastName
                  : ''
              }`
            "
          ></span>
        </div>
        <div
          :class="{
            'win-participant': true,
            'web-view': true,
            'blue-background': pinnedPeerId === key
          }"
          @click="pinParticipant(key)"
        >
          <span
            class="participant-name"
            v-html="
              `${connection.userName.firstName}${
                connection.userName.middleName
                  ? ' ' + connection.userName.middleName
                  : ''
              }${
                connection.userName.lastName
                  ? ' ' + connection.userName.lastName
                  : ''
              }`
            "
          ></span>
          <span v-if="pinnedPeerId !== key" class="pin-btn">
            <i class="bi bi-pin-fill"></i>
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import socket from "../../socket/socket";
import { mapGetters, mapMutations } from "vuex";
import { checkMobile } from "../../mixins/CheckMobile";
import { peerConnectionEventListener } from "../../mixins/listener/PeerConnection";
import { participant } from "../../mixins/Participant";
import UserOptionsVue from "./UserOptions";

export default {
  name: "ParticipantList",
  mixins: [checkMobile, peerConnectionEventListener, participant],
  data() {
    return {
      openUserOptions: false
    };
  },
  props: {
    toggleRenamePopup: Function,
    hideSelfView: Function
  },
  components: {
    UserOptionsVue
  },
  computed: {
    ...mapGetters({
      userName: "getUserName",
      userId: "getUserId"
    }),

    ...mapGetters("meet", {
      peerConnections: "getPeerConnections",
      rtcRtpSenders: "getRtcRtpSenders",
      listenToPeerEvent: "getListenToPeerEvent",
      meetDetails: "getMeetDetails",
      audioTrack: "getAudioTrack",
      videoTrack: "getVideoTrack",
      cameraStream: "getCameraStream",
      screenShareStream: "getScreenShareStream",
      isCameraStreamReady: "getIsCameraStreamReady",
      cameraFacingMode: "getCameraFacingMode",
      displayView: "getDisplayView",
      pinnedPeerId: "getPinnedPeerId",
      peerEntryAudio: "getPeerEntryAudio"
    }),

    ...mapGetters("record", {
      audioContext: "getAudioContext",
      audioDestinationNode: "getAudioDestinationNode",
      recordingState: "getRecordingState"
    })
  },
  watch: {
    /**
     * Watchers to determine if socket connection success, if success listen to meet event
     */
    listenToPeerEvent(value) {
      if (value) {
        this.listenToPeerConnectionEvent();
      }
    },

    /**
     * Watchers for video track type change, changing loggedin user stream view.
     */
    "meetDetails.videoTrackType": function(value) {
      this.loadVideoStream();
    },

    /**
     * Watchers to load video stream when camera stream is ready.
     */
    isCameraStreamReady: function(value) {
      if (value) {
        this.loadVideoStream();
      }
    }
  },
  methods: {
    ...mapMutations("meet", {
      setRtcRtpSenders: "setRtcRtpSenders",
      setPeerConnections: "setPeerConnections",
      setMeetDetails: "setMeetDetails",
      setDisplayView: "setDisplayView",
      setPinnedPeerId: "setPinnedPeerId",
      setPeerEntryAudio: "setPeerEntryAudio"
    }),

    /**
     * Function to open rename pop-up
     */
    openRenamePopup() {
      this.openUserOptions = false;
      this.toggleRenamePopup();
    },

    /**
     * Setting up the peer connection for new peer
     */
    setUpPeer(
      {
        socketId,
        userName,
        userId,
        isAudioMuted,
        isVideoMuted,
        videoTrackType
      },
      initCall = false
    ) {
      // Creating a new peer connection and adding all handlers
      const peerConnection = new RTCPeerConnection({
        iceServers: [
          {
            urls: ["stun:stun.l.google.com:19302"]
          },
          {
            urls: ["turn:52.203.90.135:3478"],
            username: "psybooks_connect",
            credential: "P5YB00ksC0nn3ct"
          }
        ]
      });
      peerConnection.onicecandidate = event =>
        this.gotIceCandidate(event, socketId);
      peerConnection.ontrack = event => this.gotRemoteStream(event, socketId);
      peerConnection.oniceconnectionstatechange = event =>
        this.checkIceStateChange(event, socketId);

      // Creating rtcRtpSenders for video track
      const videoTrackRtcRtpSender = peerConnection.addTrack(
        this.videoTrack,
        this.cameraStream
      );

      // Creating rtcRtpSenders for audio track
      const audioTrackRtcRtpSender = peerConnection.addTrack(
        this.audioTrack,
        this.cameraStream
      );

      // Setting rtcRtpSenders for audio track and video track on store
      this.setRtcRtpSenders({
        ...this.rtcRtpSenders,
        [socketId]: [audioTrackRtcRtpSender, videoTrackRtcRtpSender]
      });

      // Initiating call - Create Offer and Share description
      if (initCall) {
        console.log("offer created for peer");
        console.log(socketId);
        peerConnection
          .createOffer({
            offerToReceiveVideo: 1,
            offerToReceiveAudio: 1
          })
          .then(description => this.createdDescription(description, socketId))
          .catch(this.errorHandler);
      }

      // Adding peer connection to store
      this.setPeerConnections({
        ...this.peerConnections,
        [socketId]: {
          pc: peerConnection,
          userName: userName,
          userId,
          isAudioMuted,
          isVideoMuted,
          stream: null,
          videoTrackType: videoTrackType,
          iceState: peerConnection.iceConnectionState,
          videoReceivedByteCount: 0,
          audioReceivedByteCount: 0,
          isCallInitiated: initCall
        }
      });

      // Changing the room name on store
      this.setMeetDetails({
        ...this.meetDetails,
        room: "meetRoom"
      });

      // Setting timer
      const timeOut = setTimeout(
        (peerId, oldIceState) => {
          console.log("callback called");
          if (
            this.peerConnections[peerId] &&
            this.peerConnections[peerId].pc &&
            oldIceState == this.peerConnections[peerId].pc.iceConnectionState
          ) {
            console.log("oldIceState");
            console.log(oldIceState);
            console.log("newIceState");
            console.log(this.peerConnections[peerId].pc.iceConnectionState);
            this.removeSocketConnection(peerId);
          }
        },
        120000,
        socketId,
        peerConnection.iceConnectionState
      );

      this.setPeerConnections({
        ...this.peerConnections,
        [socketId]: {
          ...this.peerConnections[socketId],
          timeOut
        }
      });
    },

    /**
     * Handling remote stream
     */
    gotRemoteStream(event, id) {
      if (this.peerConnections[id]) {
        // Setting the peer-connections on store
        this.setPeerConnections({
          ...this.peerConnections,
          [id]: {
            ...this.peerConnections[id],
            stream: event.streams[0]
          }
        });

        // Loading the video stream on html
        document.getElementById(`remoteVideo_${id}`).srcObject =
          event.streams[0];

        // If recording is On, then record the screen
        if ("start-record" === this.recordingState) {
          const sourceNode = this.audioContext.createMediaStreamSource(
            this.peerConnections[id].stream
          );
          const gainNode = this.audioContext.createGain();
          gainNode.value = 1.0;
          sourceNode.connect(gainNode).connect(this.audioDestinationNode);
        }

        // Create a bell sound on joining to meeting room
        if (
          !this.peerConnections[id].isCallInitiated &&
          !(id in this.peerEntryAudio) &&
          "1" === window.sessionStorage.getItem("notifyOnOtherEntry")
        ) {
          // For guest user allow, remove the bell sound
          let isGuestUserAndAllowedByCurrentHost = this.participantList.findIndex(
            participant =>
              participant.isGuest &&
              participant.userId === this.peerConnections[id].userId &&
              participant.allowedBySocketId === socket.io.id
          );

          if (isGuestUserAndAllowedByCurrentHost < 0) {
            // Create the sound
            this.setPeerEntryAudio({ ...this.peerEntryAudio, [id]: true });
            const audioElement = document.getElementById("entryAudio");
            audioElement.currentTime = 0;
            audioElement.play();
          }
        }
      }
    },

    /**
     * Broadcasting the description to other peer
     */
    createdDescription(description, id) {
      this.peerConnections[id].pc
        .setLocalDescription(description)
        .then(() => {
          socket.io.emit("description", {
            id: socket.io.id,
            description: description,
            dest: id
          });
        })
        .catch(this.errorHandler);
    },

    /**
     * Handling errors
     */
    errorHandler(error) {
      console.log(error);
    },

    /**
     * Sharing the ice candidate to other peer, upon getting ice information
     */
    gotIceCandidate(event, id) {
      if (null !== event.candidate) {
        socket.io.emit("ice", {
          id: socket.io.id,
          ice: event.candidate,
          dest: id
        });
      }
    },

    /**
     * Checking for ice connection state change,
     * When peer getting disconnected remove the element and peer connection
     */
    checkIceStateChange(event, id) {
      if (this.peerConnections[id]) {
        let state = this.peerConnections[id].pc.iceConnectionState;
        this.setPeerConnections({
          ...this.peerConnections,
          [id]: {
            ...this.peerConnections[id],
            iceState: state
          }
        });
        console.log(state);

        // Checking ice state change
        switch (state) {
          case "closed":
            this.removeSocketConnection(id);
            break;
          case "failed":
            this.checkStatePermanent("failed", id);
            break;
          case "disconnected":
            this.checkStatePermanent("disconnected", id);
            break;
        }
      }
    },

    /**
     * Function to delay code execution for sometimes
     */
    customdelay(ms) {
      return new Promise(res => setTimeout(res, ms));
    },

    /**
     * Function to check if a ice connect state change is permanent
     */
    async checkStatePermanent(iceState, id) {
      this.setPeerConnections({
        ...this.peerConnections,
        [id]: {
          ...this.peerConnections[id],
          videoReceivedByteCount: 0,
          audioReceivedByteCount: 0
        }
      });

      let firstFlag = await this.isPermanentDisconnect(id);
      console.log("firstFlag");
      console.log(firstFlag);

      await this.customdelay(2000);

      let secondFlag = await this.isPermanentDisconnect(id); //Call this func again after 2 seconds to check whether data is still coming in.
      console.log("secondFlag");
      console.log(secondFlag);

      // For ice state, disconnected
      if (secondFlag) {
        if (iceState == "disconnected") {
          // If permanent disconnect then we need to mark the peer as permanently disconnected and rejoin connection
          this.setPeerConnections({
            ...this.peerConnections,
            [id]: {
              ...this.peerConnections[id],
              iceState: "permanentlyDisconnected"
            }
          });
          console.log("Disconnected => permanentlyDisconnected");
        }
      }

      // For ice state, failed (For testing the below code is commented) and bottom to that is used.
      /*if (!secondFlag) {
        //If temp failure then restart ice i.e audio/video is still flowing
        if (iceState == "failed") {
          this.peerConnections[id].pc.restartIce();
        }
      }*/
      if (iceState == "failed") {
        this.setPeerConnections({
          ...this.peerConnections,
          [id]: {
            ...this.peerConnections[id],
            iceState: "permanentlyDisconnected"
          }
        });
        console.log("failed => permanentlyDisconnected");
      }

      if ("permanentlyDisconnected" === this.peerConnections[id].iceState) {
        console.log("Calling Remove Socket Connection");
        this.removeSocketConnection(id);
      }
    },

    /**
     * Function to check if peer id is permanently disconnected
     */
    async isPermanentDisconnect(id) {
      try {
        let isPermanentDisconnectFlag = false;
        let videoIsAlive = false;
        let audioIsAlive = false;

        await this.peerConnections[id].pc.getStats(null).then(stats => {
          stats.forEach(report => {
            if (
              report.type === "inbound-rtp" &&
              (report.kind === "audio" || report.kind === "video")
            ) {
              //check for inbound data only
              if (report.kind === "audio") {
                //Here we must compare previous data count with current
                if (
                  report.bytesReceived >
                  this.peerConnections[id].audioReceivedByteCount
                ) {
                  // If current count is greater than previous then that means data is flowing to other peer. So this disconnected or failed ICE state is temporary
                  audioIsAlive = true;
                } else {
                  audioIsAlive = false;
                }

                this.setPeerConnections({
                  ...this.peerConnections,
                  [id]: {
                    ...this.peerConnections[id],
                    audioReceivedByteCount: report.bytesReceived
                  }
                });
              }

              if (report.kind === "video") {
                if (
                  report.bytesReceived >
                  this.peerConnections[id].videoReceivedByteCount
                ) {
                  // If current count is greater than previous then that means data is flowing to other peer. So this disconnected or failed ICE state is temporary
                  videoIsAlive = true;
                } else {
                  videoIsAlive = false;
                }

                this.setPeerConnections({
                  ...this.peerConnections,
                  [id]: {
                    ...this.peerConnections[id],
                    videoReceivedByteCount: report.bytesReceived
                  }
                });
              }

              if (audioIsAlive || videoIsAlive) {
                //either audio or video is being recieved.
                isPermanentDisconnectFlag = false; //Disconnected is temp
              } else {
                isPermanentDisconnectFlag = true;
              }
            }
          });
        });

        return isPermanentDisconnectFlag;
      } catch (err) {
        return true;
      }
    },

    /**
     * Function to set the camera/screenshare stream on speaker view
     */
    loadVideoStream() {
      this.$refs.localVideo.srcObject =
        "camera" === this.meetDetails.videoTrackType
          ? this.cameraStream
          : this.screenShareStream;

      this.$refs.localVideo.load();
    },

    /**
     * Function to remove the self view of user
     */
    removeSelfView() {
      this.openUserOptions = false;
      this.hideSelfView();
    },

    /**
     * Function to pin any participant on to middle.
     */
    pinParticipant(peerId) {
      this.setMeetDetails({
        ...this.meetDetails,
        hideSelfView: false
      });
      this.setPinnedPeerId(peerId);
      this.setDisplayView("pinned");
    }
  },
  mounted() {
    document.body.addEventListener("click", () => {
      this.openUserOptions = false;
    });
  }
};
</script>

<style scoped>
/* Hiding the controls of videos */
.flipEffect {
  -webkit-transform: scaleX(-1) !important;
  transform: scaleX(-1) !important;
}
.flipEffect::-webkit-media-controls {
  display: none !important;
}
video {
  min-width: 100%;
  max-width: 100%;
}
.participant-screen-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#participant-list {
  margin: 0 auto;
  text-align: center;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
}
#participant-list li {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  position: relative;
  margin-right: 0.45%;
  margin-left: 0.45%;
  margin-bottom: 0%;
}
.participant-quickview {
  background: #282828;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
}
.participant-quickview video {
  width: 100%;
  max-height: 100%;
  /* object-fit: contain; */
}
.participant-quickview img {
  max-height: 100%;
}
#participant-list li .gallery-view-options {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 25px;
  text-align: center;
  background: #d26d13;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#participant-list li .gallery-view-options a {
  color: #fff;
}

#participant-list.speaker-view li:first-child:nth-last-child(2),
#participant-list.speaker-view li:first-child:nth-last-child(2) ~ li {
  width: 70%;
  height: 95%;
  margin-right: 0px;
  margin-left: 0px;
}

#participant-list.gallery-view li:first-child:nth-last-child(2),
#participant-list.gallery-view li:first-child:nth-last-child(2) ~ li {
  width: 43.5%;
  height: 78%;
}

#participant-list.gallery-view.hide-self-view li:first-child:nth-last-child(2),
#participant-list.gallery-view.hide-self-view
  li:first-child:nth-last-child(2)
  ~ li {
  width: 70%;
  height: 95%;
  margin-right: 0px;
  margin-left: 0px;
}

#participant-list.speaker-view li:first-child:nth-last-child(3),
#participant-list.speaker-view li:first-child:nth-last-child(3) ~ li {
  width: 43.5%;
  height: 78%;
}

#participant-list.gallery-view li:first-child:nth-last-child(3),
#participant-list.gallery-view li:first-child:nth-last-child(3) ~ li {
  width: 27.5%;
  height: 80%;
}

#participant-list.gallery-view.hide-self-view li:first-child:nth-last-child(3),
#participant-list.gallery-view.hide-self-view
  li:first-child:nth-last-child(3)
  ~ li {
  width: 43.5%;
  height: 78%;
}

#participant-list.speaker-view li:first-child:nth-last-child(4),
#participant-list.speaker-view li:first-child:nth-last-child(4) ~ li {
  width: 35.5%;
  height: 47.5%;
}

#participant-list.gallery-view li:first-child:nth-last-child(4),
#participant-list.gallery-view li:first-child:nth-last-child(4) ~ li {
  width: 35.5%;
  height: 47.5%;
}

#participant-list.gallery-view.hide-self-view li:first-child:nth-last-child(4),
#participant-list.gallery-view.hide-self-view
  li:first-child:nth-last-child(4)
  ~ li {
  width: 27.5%;
  height: 80%;
}

/* The below css are not needed as we have maximum 4 participants. But in case user refreshes the page then it takes few seconds of time to remove the previous user. So, we have considered maximum 8 participnats instead of 4 */

#participant-list.speaker-view li:first-child:nth-last-child(5),
#participant-list.speaker-view li:first-child:nth-last-child(5) ~ li {
  width: 35.5%;
  height: 48.5%;
}

#participant-list.gallery-view li:first-child:nth-last-child(5),
#participant-list.gallery-view li:first-child:nth-last-child(5) ~ li {
  width: 29.5%;
  height: 48.5%;
}

#participant-list.gallery-view.hide-self-view li:first-child:nth-last-child(5),
#participant-list.gallery-view.hide-self-view
  li:first-child:nth-last-child(5)
  ~ li {
  width: 35.5%;
  height: 47.5%;
}

#participant-list.speaker-view li:first-child:nth-last-child(6),
#participant-list.speaker-view li:first-child:nth-last-child(6) ~ li {
  width: 28.5%;
  height: 48.5%;
}

#participant-list.gallery-view li:first-child:nth-last-child(6),
#participant-list.gallery-view li:first-child:nth-last-child(6) ~ li {
  width: 29.5%;
  height: 48.5%;
}

#participant-list.gallery-view.hide-self-view li:first-child:nth-last-child(6),
#participant-list.gallery-view.hide-self-view
  li:first-child:nth-last-child(6)
  ~ li {
  width: 29.5%;
  height: 48.5%;
}

#participant-list.speaker-view li:first-child:nth-last-child(7),
#participant-list.speaker-view li:first-child:nth-last-child(7) ~ li {
  width: 25.5%;
  height: 48.5%;
}

#participant-list.gallery-view li:first-child:nth-last-child(7),
#participant-list.gallery-view li:first-child:nth-last-child(7) ~ li {
  width: 22.5%;
  height: 48.5%;
}

#participant-list.gallery-view.hide-self-view li:first-child:nth-last-child(7),
#participant-list.gallery-view.hide-self-view
  li:first-child:nth-last-child(7)
  ~ li {
  width: 29.5%;
  height: 48.5%;
}

#participant-list.speaker-view li:first-child:nth-last-child(8),
#participant-list.speaker-view li:first-child:nth-last-child(8) ~ li {
  width: 22.5%;
  height: 48.5%;
}

#participant-list.gallery-view li:first-child:nth-last-child(8),
#participant-list.gallery-view li:first-child:nth-last-child(8) ~ li {
  width: 22.5%;
  height: 48.5%;
}

#participant-list.gallery-view.hide-self-view li:first-child:nth-last-child(8),
#participant-list.gallery-view.hide-self-view
  li:first-child:nth-last-child(8)
  ~ li {
  width: 22.5%;
  height: 48.5%;
}

#participant-list.speaker-view li:first-child:nth-last-child(9),
#participant-list.speaker-view li:first-child:nth-last-child(9) ~ li {
  width: 22.5%;
  height: 48.5%;
}

#participant-list.gallery-view li:first-child:nth-last-child(9),
#participant-list.gallery-view li:first-child:nth-last-child(9) ~ li {
  width: 21%;
  height: 32%;
}

#participant-list.gallery-view.hide-self-view li:first-child:nth-last-child(9),
#participant-list.gallery-view.hide-self-view
  li:first-child:nth-last-child(9)
  ~ li {
  width: 22.5%;
  height: 48.5%;
}

#participant-list.speaker-view li:first-child:nth-last-child(10),
#participant-list.speaker-view li:first-child:nth-last-child(10) ~ li {
  width: 18.5%;
  height: 47.5%;
}

#participant-list.gallery-view li:first-child:nth-last-child(10),
#participant-list.gallery-view li:first-child:nth-last-child(10) ~ li {
  width: 21%;
  height: 32%;
}

#participant-list.gallery-view.hide-self-view li:first-child:nth-last-child(10),
#participant-list.gallery-view.hide-self-view
  li:first-child:nth-last-child(10)
  ~ li {
  width: 18.5%;
  height: 47.5%;
}

.name-thumb {
  width: 145px;
  height: 145px;
}

/* CSS to pin any participant on to middle */
.room-pinned {
  width: 15%;
  padding-left: 1%;
  height: 98%;
}
.room-pinned #participant-list li {
  width: 100%;
  height: 18.9%;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 4%;
}
/* Up to 4 participants height will be 24% else 18.9% */
.room-pinned #participant-list:has(> :nth-child(-n + 4):last-child) li {
  height: 24%;
}
.room-pinned #participant-list li:last-child {
  margin-bottom: 0%;
}
.room-pinned #participant-list {
  align-content: flex-start;
}
.room-pinned #participant-list li .participant-quickview .name-thumb {
  width: 70px;
  height: 70px;
}
.room-pinned #participant-list li .participant-quickview .name-thumb .name {
  font-size: 30px;
}
.room-pinned #participant-list li .participant-quickview video {
  object-fit: cover;
}
.mobile-view.win-participant {
  display: none;
}
.web-view.win-participant {
  display: flex;
}
@media screen and (max-width: 1024px) {
  #participant-list.gallery-view li:first-child:nth-last-child(3),
  #participant-list.gallery-view li:first-child:nth-last-child(3) ~ li {
    width: 35.5%;
    height: 47.5%;
  }

  #participant-list.gallery-view li:first-child:nth-last-child(4),
  #participant-list.gallery-view li:first-child:nth-last-child(4) ~ li {
    width: 40.5%;
    height: 47.5%;
  }

  #participant-list.gallery-view.hide-self-view
    li:first-child:nth-last-child(4),
  #participant-list.gallery-view.hide-self-view
    li:first-child:nth-last-child(4)
    ~ li {
    width: 35.5%;
    height: 47.5%;
  }

  .name-thumb {
    width: 110px;
    height: 110px;
  }

  /* CSS to pin any participant to middle */
  .room-pinned {
    width: 20%;
  }
  .room-pinned #participant-list li .participant-quickview .name-thumb {
    width: 60px;
    height: 60px;
  }
  .mobile-view.win-participant {
    display: flex;
  }
  .web-view.win-participant {
    display: none;
  }
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .view-options {
    display: none;
  }

  #participant-list li {
    margin-right: 0px;
    margin-left: 0px;
  }

  #participant-list.speaker-view li:first-child:nth-last-child(2),
  #participant-list.speaker-view li:first-child:nth-last-child(2) ~ li {
    width: 98%;
    height: 98%;
  }

  #participant-list.speaker-view li:first-child:nth-last-child(3),
  #participant-list.speaker-view li:first-child:nth-last-child(3) ~ li {
    width: 80%;
    height: 48%;
    margin-bottom: 2%;
  }

  #participant-list.speaker-view li:first-child:nth-last-child(4),
  #participant-list.speaker-view li:first-child:nth-last-child(4) ~ li {
    width: 65%;
    height: 30%;
    margin-bottom: 2%;
  }

  /* The below css are not needed as we have maximum 4 participants. But in case user refreshes the page then it takes few seconds of time to remove the previous user. So, we have considered maximum 8 participnats instead of 4 */
  #participant-list.speaker-view li:first-child:nth-last-child(5),
  #participant-list.speaker-view li:first-child:nth-last-child(5) ~ li {
    width: 55%;
    height: 24%;
    margin-bottom: 2%;
  }

  #participant-list.speaker-view li:first-child:nth-last-child(6),
  #participant-list.speaker-view li:first-child:nth-last-child(6) ~ li {
    width: 44%;
    height: 31%;
    margin-bottom: 0%;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }

  #participant-list.speaker-view li:first-child:nth-last-child(7),
  #participant-list.speaker-view li:first-child:nth-last-child(7) ~ li {
    width: 44%;
    height: 31%;
    margin-bottom: 0%;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }

  #participant-list.speaker-view li:first-child:nth-last-child(8),
  #participant-list.speaker-view li:first-child:nth-last-child(8) ~ li {
    width: 28%;
    height: 32%;
    margin-bottom: 0%;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }

  #participant-list.speaker-view li:first-child:nth-last-child(9),
  #participant-list.speaker-view li:first-child:nth-last-child(9) ~ li {
    width: 28%;
    height: 32%;
    margin-bottom: 0%;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }

  #participant-list.speaker-view li:first-child:nth-last-child(10),
  #participant-list.speaker-view li:first-child:nth-last-child(10) ~ li {
    width: 28%;
    height: 32%;
    margin-bottom: 0%;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }

  #participant-list.gallery-view li:first-child:nth-last-child(2),
  #participant-list.gallery-view li:first-child:nth-last-child(2) ~ li {
    width: 80%;
    height: 48%;
    margin-bottom: 2%;
  }

  #participant-list.gallery-view li:first-child:nth-last-child(3),
  #participant-list.gallery-view li:first-child:nth-last-child(3) ~ li {
    width: 65%;
    height: 30%;
    margin-bottom: 2%;
  }

  #participant-list.gallery-view li:first-child:nth-last-child(4),
  #participant-list.gallery-view li:first-child:nth-last-child(4) ~ li {
    width: 55%;
    height: 23%;
    margin-bottom: 2%;
  }

  /* The below css are not needed as we have maximum 4 participants. But in case user refreshes the page then it takes few seconds of time to remove the previous user. So, we have considered maximum 8 participnats instead of 4 */
  #participant-list.gallery-view li:first-child:nth-last-child(5),
  #participant-list.gallery-view li:first-child:nth-last-child(5) ~ li {
    width: 44%;
    height: 31%;
    margin-bottom: 0%;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }

  #participant-list.gallery-view li:first-child:nth-last-child(6),
  #participant-list.gallery-view li:first-child:nth-last-child(6) ~ li {
    width: 44%;
    height: 31%;
    margin-bottom: 0%;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }

  #participant-list.gallery-view li:first-child:nth-last-child(7),
  #participant-list.gallery-view li:first-child:nth-last-child(7) ~ li {
    width: 45%;
    height: 24%;
    margin-bottom: 0%;
    margin-left: 1%;
    margin-right: 1%;
  }

  #participant-list.gallery-view li:first-child:nth-last-child(8),
  #participant-list.gallery-view li:first-child:nth-last-child(8) ~ li {
    width: 45%;
    height: 24%;
    margin-bottom: 0%;
    margin-left: 1%;
    margin-right: 1%;
  }

  #participant-list.gallery-view li:first-child:nth-last-child(9),
  #participant-list.gallery-view li:first-child:nth-last-child(9) ~ li {
    width: 28%;
    height: 32%;
    margin-bottom: 0%;
    margin-left: 1%;
    margin-right: 1%;
  }

  #participant-list.gallery-view li:first-child:nth-last-child(10),
  #participant-list.gallery-view li:first-child:nth-last-child(10) ~ li {
    width: 30%;
    height: 24%;
    margin-bottom: 0%;
    margin-left: 1%;
    margin-right: 1%;
  }

  #participant-list li:last-child {
    margin-bottom: 0% !important;
  }

  .name-thumb {
    width: 100px;
    height: 100px;
  }

  /* CSS to pin any participant to middle */
  .room-pinned {
    width: 100%;
    height: 85px;
  }
  .room-pinned #participant-list li {
    width: 23%;
    height: 100%;
    margin-right: 1%;
    margin-bottom: 0%;
  }
  /* .room-pinned #participant-list li:last-child {
    margin-right: 0%;
  } */
  .room-pinned #participant-list li .participant-quickview .name-thumb {
    width: 40px;
    height: 40px;
  }
  .room-pinned #participant-list li .participant-quickview .name-thumb .name {
    font-size: 20px;
  }
}
@media screen and (orientation: landscape) and (max-height: 425px) {
  .view-options {
    display: none;
  }

  #participant-list.speaker-view li:first-child:nth-last-child(2),
  #participant-list.speaker-view li:first-child:nth-last-child(2) ~ li {
    width: 98%;
    height: 98%;
  }

  #participant-list.speaker-view li:first-child:nth-last-child(3),
  #participant-list.speaker-view li:first-child:nth-last-child(3) ~ li {
    width: 43.5%;
    height: 98%;
  }

  #participant-list.speaker-view li:first-child:nth-last-child(4),
  #participant-list.speaker-view li:first-child:nth-last-child(4) ~ li {
    width: 27.5%;
    height: 98%;
  }

  /* Gallery View start */
  #participant-list.gallery-view li:first-child:nth-last-child(2),
  #participant-list.gallery-view li:first-child:nth-last-child(2) ~ li {
    width: 43.5%;
    height: 98%;
  }

  #participant-list.gallery-view li:first-child:nth-last-child(3),
  #participant-list.gallery-view li:first-child:nth-last-child(3) ~ li {
    width: 27.5%;
    height: 98%;
  }

  #participant-list.gallery-view li:first-child:nth-last-child(4),
  #participant-list.gallery-view li:first-child:nth-last-child(4) ~ li {
    width: 23.5%;
    height: 98%;
  }
  /* Gallery View end */

  .name-thumb {
    width: 94px;
    height: 94px;
  }

  /* CSS to pin any participant to middle */
  .room-pinned {
    width: 15%;
    height: 100%;
  }
  .room-pinned #participant-list li {
    margin-bottom: 3%;
  }
  .room-pinned #participant-list li:first-child:nth-last-child(2),
  .room-pinned #participant-list li:first-child:nth-last-child(2) ~ li {
    width: 100%;
    height: 49%;
  }
  .room-pinned #participant-list li:first-child:nth-last-child(3),
  .room-pinned #participant-list li:first-child:nth-last-child(3) ~ li {
    width: 100%;
    height: 32.5%;
  }
  .room-pinned #participant-list li:first-child:nth-last-child(4),
  .room-pinned #participant-list li:first-child:nth-last-child(4) ~ li {
    width: 100%;
    height: 23.5%;
  }
  .room-pinned #participant-list li:first-child:nth-last-child(5),
  .room-pinned #participant-list li:first-child:nth-last-child(5) ~ li {
    width: 100%;
    height: 19%;
  }
  .room-pinned #participant-list li:last-child {
    margin-bottom: 0% !important;
  }
  .room-pinned #participant-list li .participant-quickview .name-thumb {
    width: 35px;
    height: 35px;
  }
  .room-pinned #participant-list li .participant-quickview .name-thumb .name {
    font-size: 16px;
  }
}
</style>
