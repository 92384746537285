var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"participant-view full-panel",staticStyle:{"display":"block"},attrs:{"id":"participants-container"}},[_c('div',{staticClass:"sideblock-container"},[_c('div',{staticClass:"side-topbar"},[_c('h4',[_vm._v(" Participants "),_c('span',{staticClass:"total-pc"},[_vm._v(" ("+_vm._s(Object.keys(_vm.peerConnections).length + 1)+") ")])]),_c('div',{staticClass:"side-topbar-dropdown"},[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){return _vm.toggleSidebar({ clickEvent: 'participant', isClose: true })}}},[_c('i',{staticClass:"bi bi-x icon-dropdown icon-sidepanel-close"})])])]),_c('div',{staticClass:"side-topbar-content"},[_c('ul',{staticClass:"participant-list custom-scroll-v2 participant-scroll"},[_c('li',[_c('div',{staticClass:"pc-initial pc-host"},[_vm._v(" "+_vm._s(("" + (_vm.userName.firstName ? _vm.userName.firstName[0] : "") + (_vm.userName.lastName ? _vm.userName.lastName[0] : "")).toUpperCase())+" ")]),_c('div',{staticClass:"pc-name"},[_vm._v(" "+_vm._s(("" + (_vm.userName.firstName) + (_vm.userName.middleName ? " " + _vm.userName.middleName : "") + (_vm.userName.lastName ? " " + _vm.userName.lastName : "")))+" ")]),_c('div',{staticClass:"pc-cta"},[_c('a',{staticClass:"cursorPointer tooltip",on:{"click":_vm.checkAndChangeAudioStatus}},[_c('i',{class:{
                  bi: true,
                  'bi-mic': !_vm.meetDetails.isUserAudioMuted,
                  'bi-mic-mute-fill': _vm.meetDetails.isUserAudioMuted
                }}),_c('span',{staticClass:"tooltiptext"},[_vm._v("Mute/Unmute audio")])]),_c('a',{staticClass:"cursorPointer tooltip",on:{"click":function($event){return _vm.toggleVideo(null)}}},[_c('i',{class:{
                  bi: true,
                  'bi-camera-video': !_vm.meetDetails.isUserVideoMuted,
                  'bi-camera-video-off-fill': _vm.meetDetails.isUserVideoMuted
                }}),_c('span',{staticClass:"tooltiptext right-arrow"},[_vm._v("Start/Stop video")])])]),_c('div',{staticClass:"clear"})]),_vm._l((_vm.peerConnections),function(connection,key){return _c('li',{key:key},[_c('div',{staticClass:"pc-initial pc-host"},[_vm._v(" "+_vm._s(("" + (connection.userName.firstName ? connection.userName.firstName[0] : "") + (connection.userName.lastName ? connection.userName.lastName[0] : "")).toUpperCase())+" ")]),_c('div',{staticClass:"pc-name",domProps:{"innerHTML":_vm._s(
              ("" + (connection.userName.firstName) + (connection.userName.middleName
                  ? ' ' + connection.userName.middleName
                  : '') + (connection.userName.lastName
                  ? ' ' + connection.userName.lastName
                  : ''))
            )}}),_c('div',{staticClass:"pc-cta"},[_c('a',{staticClass:"cursorPointer tooltip",on:{"click":function($event){return _vm.toggleParticipantStream('audio', key, connection.isAudioMuted)}}},[_c('i',{class:{
                  bi: true,
                  'bi-mic': !connection.isAudioMuted,
                  'bi-mic-mute-fill': connection.isAudioMuted
                }}),_c('span',{staticClass:"tooltiptext"},[_vm._v("Mute/Unmute Audio")])]),_c('a',{staticClass:"cursorPointer tooltip",on:{"click":function($event){return _vm.toggleParticipantStream('video', key, connection.isVideoMuted)}}},[_c('i',{class:{
                  bi: true,
                  'bi-camera-video': !connection.isVideoMuted,
                  'bi-camera-video-off-fill': connection.isVideoMuted
                }}),_c('span',{class:{
                  'right-arrow': _vm.userId !== _vm.hostInfo.id,
                  tooltiptext: true
                }},[_vm._v("Start/Stop video")])]),(_vm.userId === _vm.hostInfo.id)?_c('a',{staticClass:"cursorPointer tooltip",on:{"click":function($event){return _vm.removeParticipant(key)}}},[_c('i',{staticClass:"bi bi-person-x"}),_c('span',{staticClass:"tooltiptext right-arrow"},[_vm._v("Remove Participant")])]):_vm._e()]),_c('div',{staticClass:"clear"})])}),_vm._l((_vm.notJoinedParticipants),function(participant){return _c('li',{key:participant.userId},[_c('div',{staticClass:"pc-initial pc-host"},[_vm._v(" "+_vm._s(("" + (participant.userName.firstName ? participant.userName.firstName[0] : "") + (participant.userName.lastName ? participant.userName.lastName[0] : "")).toUpperCase())+" ")]),_c('div',{staticClass:"pc-name",domProps:{"innerHTML":_vm._s(
              ("" + (participant.userName.firstName) + (participant.userName.middleName
                  ? ' ' + participant.userName.middleName
                  : '') + (participant.userName.lastName
                  ? ' ' + participant.userName.lastName
                  : ''))
            )}}),_c('div',{staticClass:"pc-cta"},[(participant.isUserDeniedToMeetRoom)?_c('a',{staticClass:"cursorPointer tooltip",on:{"click":function($event){return _vm.acceptParticipant(participant.userId)}}},[_c('i',{staticClass:"bi bi-person-check"}),_c('span',{staticClass:"tooltiptext right-arrow"},[_vm._v("Accept participant")])]):_vm._e()]),_c('div',{staticClass:"clear"})])})],2)])]),(_vm.userId === _vm.hostInfo.id)?_c('div',{staticClass:"host-cta-options"},[_c('a',{staticClass:"btn-secondary cursorPointer",on:{"click":_vm.showInviteParticipant}},[_vm._v(" Invite ")]),_c('a',{staticClass:"btn-secondary cursorPointer",on:{"click":_vm.muteAllParticipants}},[_vm._v(" Mute All ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }