var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modalWindow",attrs:{"id":"allow-participant-in"}},[_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"modal-body"},[_c('div',{attrs:{"id":"participant-permission"}},[_c('h4',[_c('span',{staticClass:"par-fname"},[_vm._v(" "+_vm._s(("" + (_vm.participant.userName.firstName ? _vm.participant.userName.firstName[0] : "") + (_vm.participant.userName.lastName ? _vm.participant.userName.lastName[0] : "")).toUpperCase())+" ")]),_c('span',{staticClass:"info-container"},[_c('span',{staticClass:"info-text"},[_vm._v("Someone wants to join this call.")]),_c('span',{staticClass:"txt-highlight-pri",domProps:{"innerHTML":_vm._s(
                ("" + (_vm.participant.userName.firstName) + (_vm.participant.userName.middleName
                    ? ' ' + _vm.participant.userName.middleName
                    : '') + (_vm.participant.userName.lastName
                    ? ' ' + _vm.participant.userName.lastName
                    : ''))
              )}})])]),(_vm.participant.userId)?[_c('p',[_vm._v("As the host, you can remove any one at any time.")]),_c('div',{staticClass:"modal-entry-cta"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.allowParticipant({
                  userId: _vm.participant.userId
                })}}},[_vm._v(" Admit ")]),_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.denyParticipant(_vm.participant.userId)}}},[_vm._v(" Deny Entry ")])])]:[_c('p',[_c('span',{staticClass:"txt-highlight-pri guest-user-name",domProps:{"innerHTML":_vm._s(
                ("" + (_vm.participant.userName.firstName) + (_vm.participant.userName.middleName
                    ? ' ' + _vm.participant.userName.middleName
                    : '') + (_vm.participant.userName.lastName
                    ? ' ' + _vm.participant.userName.lastName
                    : ''))
              )}}),_vm._v(" is trying to enter the meeting in a non-standard way. If you recognize them, it's safe to admit them. If you want to make sure they get added as a contact so you'll have a record of them attending, click \"Link to Contact.\" Otherwise, you can have them join as a guest. ")]),_c('button',{staticClass:"btn btn-primary-outline",on:{"click":function($event){_vm.openContact = !_vm.openContact}}},[_vm._v(" Link To Contact ")]),_c('button',{staticClass:"btn btn-primary-outline",on:{"click":function($event){return _vm.allowGuest({
                socketId: _vm.participant.socketId
              })}}},[_vm._v(" Join As Guest ")]),_c('button',{staticClass:"btn btn-primary-outline",on:{"click":function($event){return _vm.denyGuest(_vm.participant.socketId)}}},[_vm._v(" Deny ")]),(_vm.openContact)?_c('div',{staticClass:"guest-matching"},[_c('h4',[_vm._v(" Your contacts ")]),_c('input',{attrs:{"type":"text","placeholder":"Search Contacts"},domProps:{"value":_vm.userSearchVal},on:{"input":function($event){return _vm.filterSearch($event.target.value)}}}),_c('br'),_c('br'),_c('div',{staticClass:"container-contacts"},[_c('div',{staticClass:"custom-scroll scroll-small"},[('pending' === _vm.userListFetchStatus)?_c('div',{staticClass:"loader"}):_vm._l((_vm.userList),function(user){return _c('div',{key:user.user_id,staticClass:"radio-container",on:{"click":function($event){return _vm.selectUser(user.user_id)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedUser.userId),expression:"selectedUser.userId"}],attrs:{"type":"radio"},domProps:{"value":user.user_id,"checked":_vm._q(_vm.selectedUser.userId,user.user_id)},on:{"change":function($event){return _vm.$set(_vm.selectedUser, "userId", user.user_id)}}}),_c('label',{staticClass:"radio",domProps:{"innerHTML":_vm._s(user.name)}})])})],2),(_vm.showUserSelectErr)?_c('div',{staticClass:"error-message no-contact-selected"},[_vm._v(" "+_vm._s("Please Select a Contact to link the guest user")+" ")]):_vm._e()]),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.allowGuest(Object.assign({}, _vm.selectedUser,
                  {socketId: _vm.participant.socketId}))}}},[_vm._v(" Admit ")])]):_vm._e()]],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }