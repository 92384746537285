import Vue from "vue";

/**
 * Mutations for meet
 */
export const mutations = {
  /**
   * Setting loggedin user camera stream
   *
   * @param {Object} state
   * @param {Object} cameraStream
   */
  setCameraStream(state, cameraStream) {
    state.cameraStream = cameraStream;
  },

  /**
   * Setting flag which determine whether camera stream ready
   *
   * @param {Object} state
   * @param {Boolean} value
   */
  setIsCameraStreamReady(state, value) {
    state.isCameraStreamReady = value;
  },

  /**
   * Flag to listen for peer event
   *
   * @param {Object} state
   * @param {Boolean} value
   */
  setListenToPeerEvent(state, value) {
    state.listenToPeerEvent = value;
  },

  /**
   * Sets mobile camera facing mode
   *
   * @param {Object} state
   * @param {Boolean} Value
   */
  setCameraFacingMode(state, value) {
    state.cameraFacingMode = value;
  },

  /**
   * Setting audio track from loggedin user camera
   *
   * @param {Object} state
   * @param {Object} track
   */
  setAudioTrack(state, track) {
    state.audioTrack = track;
  },

  /**
   * Setting video track from loggedin user camera
   *
   * @param {Object} state
   * @param {Object} track
   */
  setVideoTrack(state, track) {
    state.videoTrack = track;
  },

  /**
   * Setting loggedin user screenshare stream
   *
   * @param {Object} state
   * @param {Object} screenShareStream
   */
  setScreenShareStream(state, screenShareStream) {
    state.screenShareStream = screenShareStream;
  },

  /**
   * Setting peer connections
   *
   * @param {Object} state
   * @param {Object} peerConnection
   */
  setPeerConnections(state, connections) {
    state.peerConnections = connections;
  },

  /**
   * Rename the connected as peers name
   *
   * @param {Object} state
   * @param {Object} data
   */
  renamePeerConnection(state, data) {
    for (const id in state.peerConnections) {
      if (state.peerConnections[id].userId === data.userId) {
        state.peerConnections[id].userName.firstName = data.firstName;
        state.peerConnections[id].userName.middleName = data.middleName;
        state.peerConnections[id].userName.lastName = data.lastName;
      }
    }
  },

  /**
   * Sets the rtcRtpSenders
   *
   * @param {Object} state
   * @param {Object} rtcRtpSenders
   */
  setRtcRtpSenders(state, rtcRtpSenders) {
    state.rtcRtpSenders = rtcRtpSenders;
  },

  /**
   * Mutation to set meet details
   *
   * @param {Object} state
   * @param {Object} meetDetails
   */
  setMeetDetails(state, meetDetails) {
    state.meetDetails = meetDetails;
  },

  /**
   * Mutation to set participant list
   *
   * @param {Object} state
   * @param {Array} participantList
   */
  setParticipantList(state, participantList) {
    state.participantList = participantList;
  },

  /**
   * Mark participant as allowed on participant list
   *
   * @param {Object} state
   */
  markParticipantAllowedOnParticipantList(state, userId) {
    // Get the index of participant
    let participant = state.participantList.find(participant => {
      return participant.userId === userId;
    });

    if (participant) {
      participant.waitRoomSocketConn = [];
    }
  },

  /**
   * Mark participant as denied on participant list
   *
   * @param {Object} state
   * @param {Object} {userId, isDenied}
   */
  markParticipantDeniedOnParticipantList(state, { userId, isDenied }) {
    // Get the index of participant
    let participant = state.participantList.find(participant => {
      return participant.userId === userId;
    });

    if (participant) {
      participant.waitRoomSocketConn = [];

      // Mark participant as denied
      participant.isUserDeniedToMeetRoom = isDenied;
    }
  },

  /**
   * Push the participant to waiting room list
   *
   * @param {Object} state
   * @param {Object} param1
   */
  pushParticipantToWaitingRoom(state, { userId, socketId, ...participantObj }) {
    // Find the index of participant id
    const searchedIndex = state.participantList.findIndex(
      participant => participant.userId === userId
    );

    // If index found, add to waiting room socket connection
    if (
      searchedIndex > -1 &&
      !state.participantList[searchedIndex].waitRoomSocketConn.includes(
        socketId
      )
    ) {
      state.participantList[searchedIndex].waitRoomSocketConn.push(socketId);
    } else {
      if (state.hostInfo.id !== userId) {
        state.participantList.push({ userId, ...participantObj });
      }
    }
  },

  /**
   * Removes participant socket id from waiting room list
   *
   * @param {Object} state
   * @param {String} socketId
   */
  removeParticipantFromWaitingRoom(state, socketId) {
    // Get the index of socketId on participant list
    state.participantList.forEach(participant => {
      // Find the index, of the socketId
      const searchedIndex = participant.waitRoomSocketConn.findIndex(
        connectionId => connectionId === socketId
      );

      // Delete the socket connection
      if (searchedIndex > -1) {
        participant.waitRoomSocketConn.splice(searchedIndex, 1);
      }
    });
  },

  /**
   * Push the participant to meeting room list
   *
   * @param {Object} state
   * @param {Object} data
   */
  pushParticipantToMeetingRoom(state, data) {
    // Find the index
    const searchedIndex = state.participantList.findIndex(
      participant => participant.userId === data.userId
    );

    // If index found, add to meeting room socket connection
    if (
      searchedIndex > -1 &&
      !state.participantList[searchedIndex].meetRoomSocketConn.includes(
        data.socketId
      )
    ) {
      state.participantList[searchedIndex].meetRoomSocketConn.push(
        data.socketId
      );
    }
  },

  /**
   * Removes participant socket id from meeting room list
   *
   * @param {Object} state
   * @param {String} socketId
   */
  removeParticipantFromMeetingRoom(state, socketId) {
    // Get the index of socketId
    state.participantList.forEach(participant => {
      // Find the index, of the socketId
      const searchedIndex = participant.meetRoomSocketConn.findIndex(
        connectionId => connectionId === socketId
      );

      // Delete the socket connection
      if (searchedIndex > -1) {
        participant.meetRoomSocketConn.splice(searchedIndex, 1);
      }
    });
  },

  /**
   * Rename the participant on participant list
   *
   * @param {Object} state
   * @param {Object} data
   */
  renameParticipant(state, data) {
    state.participantList.forEach(participant => {
      if (participant.userId === data.userId) {
        participant.userName = {
          ...participant.userName,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName
        };
      }
    });
  },

  /**
   * Sets guest user list
   *
   * @param {Object} state
   * @param {Array} guestUserList
   */
  setGuestUserList(state, guestUserList) {
    state.guestUserList = guestUserList;
  },

  /**
   * Setting the list of audio input
   *
   * @param {Object} state
   * @param {Object} audioInput
   */
  setAudioInput(state, audioInput) {
    state.audioInput = audioInput;
  },

  /**
   * Setting the list of video input
   *
   * @param {Object} state
   * @param {Object} videoInput
   */
  setVideoInput(state, videoInput) {
    state.videoInput = videoInput;
  },

  /**
   * Setting the list of audio output
   *
   * @param {Object} state
   * @param {Object} audioOutput
   */
  setAudioOutput(state, audioOutput) {
    state.audioOutput = audioOutput;
  },

  /**
   * Sets selected audio input id
   *
   * @param {Object} state
   * @param {Object} selectedAudioInput
   */
  setSelectedAudioInput(state, selectedAudioInput) {
    state.selectedAudioInput = selectedAudioInput;
  },

  /**
   * Sets selected video input id
   *
   * @param {Object} state
   * @param {String} selectedVideoInput
   */
  setSelectedVideoInput(state, selectedVideoInput) {
    state.selectedVideoInput = selectedVideoInput;
  },

  /**
   * Sets selected audio output id
   *
   * @param {Object} state
   * @param {String} selectedAudioOutput
   */
  setSelectedAudioOutput(state, selectedAudioOutput) {
    state.selectedAudioOutput = selectedAudioOutput;
  },

  /**
   * Setting the full screen mode
   *
   * @param {Object} state
   * @param {Boolean} value
   */
  setFullScreenMode(state, value) {
    state.fullScreenMode = value;
  },

  /**
   * Setting the host information
   *
   * @param {Object} state
   * @param {Object} param1
   */
  setHostInfo(state, hostInfo) {
    state.hostInfo = hostInfo;
  },

  /**
   * Sets the display view
   *
   * @param {Object} state
   * @param {String} displayView
   */
  setDisplayView(state, displayView) {
    state.displayView = displayView;
  },

  /**
   * Sets the currently active pinned peer id
   *
   * @param {Object} state
   * @param {String} peerId
   */
  setPinnedPeerId(state, peerId) {
    state.pinnedPeerId = peerId;
  },

  /**
   * Sets openEndMeetPopup flag
   *
   * @param {Object} state
   * @param {Boolean} openEndMeetPopup
   */
  setIsEndMeetPopupOpened(state, isOpen) {
    state.isEndMeetPopupOpened = isOpen;
  },

  /**
   * Sets openEndMeetPopup flag
   *
   * @param {Object} state
   * @param {Boolean} openEndMeetPopup
   */
  setIsRecordDrpDwnOpened(state, isOpen) {
    state.isRecordDrpDwnOpened = isOpen;
  },

  /**
   * Setting the join id
   *
   * @param {Object} state
   * @param {Integer} joinId
   */
  setJoinId(state, joinId) {
    state.joinId = joinId;
  },

  /**
   * Sets the peer id for which entry audio has been played
   *
   * @param {Object} state
   * @param {Object} peerEntryAudio
   */
  setPeerEntryAudio(state, peerEntryAudio) {
    state.peerEntryAudio = peerEntryAudio;
  },

  /**
   * Function to reset meet state
   *
   * @param {Object} state
   */
  resetMeetState(state) {
    state.audioTrack = null;
    state.videoTrack = null;
    state.peerConnections = {};
    state.rtcRtpSenders = {};
    state.participantList = [];
    state.guestUserList = [];
  }
};
