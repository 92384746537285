<template>
  <div>
    <div class="loader" v-if="showLoader"></div>
    <form action="#" method="POST" v-else>
      <label for="fname"> First Name <span class="mandatory">*</span> </label>
      <br />
      <input
        type="text"
        id="fname"
        name="fname"
        @input="setFirstName($event.target.value)"
        v-model="name.firstName.val"
      />
      <span v-if="name.firstName.error" class="name-error">
        {{ name.firstName.error }}
      </span>
      <label for="fname">Middle Name</label>
      <br />
      <input
        type="text"
        id="fname"
        name="fname"
        @input="setMiddleName($event.target.value)"
        v-model="name.middleName.val"
      />
      <span v-if="name.middleName.error" class="name-error">
        {{ name.middleName.error }}
      </span>
      <label for="lname">Last Name</label>
      <br />
      <input
        type="text"
        id="lname"
        name="lname"
        @input="setLastName($event.target.value)"
        v-model="name.lastName.val"
      />
      <span v-if="name.lastName.error" class="name-error">
        {{ name.lastName.error }}
      </span>
      <br />
      <div class="error-message error" v-if="serverErr.length > 0">
        {{ serverErr }}
      </div>
      <button class="btn btn-primary" @click.prevent="validateAndSubmitForm">
        Submit
      </button>

      <div class="confirm-rename" v-if="showWarning">
        <p>
          This will change your name to {{ newNameFull }} for all participants.
          Do you want to continue ?
        </p>

        <div>
          <button
            class="btn btn-primary yes-btn"
            @click.prevent="sumbitRenameRequest"
          >
            Yes
          </button>

          <button class="btn-secondary" @click.prevent="hideModal">
            No
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import GeneralConstant from "../../constant/GeneralConstant";
import { validateAndFormat } from "../../mixins/ValidateAndFormat";

export default {
  name: "Rename",
  mixins: [validateAndFormat],
  data() {
    return {
      name: {
        firstName: {
          val: "",
          error: ""
        },
        middleName: {
          val: "",
          error: ""
        },
        lastName: {
          val: "",
          error: ""
        }
      },
      serverErr: "",
      showLoader: false,
      showWarning: false
    };
  },
  computed: {
    ...mapGetters({
      userName: "getUserName"
    }),

    /**
     * Returns the full name of new name
     */
    newNameFull() {
      return `${this.name.firstName.val}${
        this.name.middleName.val ? ` ${this.name.middleName.val}` : ""
      }${this.name.lastName.val ? ` ${this.name.lastName.val}` : ""}`;
    }
  },
  methods: {
    ...mapActions("participant", {
      renameParticipant: "renameParticipant"
    }),

    ...mapMutations({
      setModal: "setModal"
    }),

    /**
     * Function to format and set the first name
     */
    setFirstName(value) {
      this.name.firstName.val = value.substring(0, 60);
    },

    /**
     * Function to format and set the middle name
     */
    setMiddleName(value) {
      if (value) {
        this.name.middleName.val = value.substring(0, 60);
      }
    },

    /**
     * Function to format and set the last name
     */
    setLastName(value) {
      if (value) {
        this.name.lastName.val = value.substring(0, 60);
      }
    },

    /**
     * Function to reset all errors on the guest user add object
     */
    resetAllErrors() {
      for (const property in this.name) {
        this.name[property].error = "";
      }
      this.serverErr = "";
    },

    /**
     * Submit the rename participant form
     */
    async validateAndSubmitForm() {
      // Reset all errors
      this.resetAllErrors();

      if (!this.validateName(this.name.firstName.val)) {
        // Validating first name
        this.name.firstName.error = GeneralConstant.error.invalidFirstName;
      } else if (
        this.name.lastName.val &&
        !this.validateName(this.name.lastName.val)
      ) {
        // Validating last name
        this.name.lastName.error = GeneralConstant.error.invalidLastName;
      } else if (
        this.name.middleName.val &&
        !this.validateName(this.name.middleName.val)
      ) {
        // Validating last name
        this.name.middleName.error = GeneralConstant.error.invalidMiddleName;
      } else {
        // Check if any of the value has changed
        if (
          this.name.firstName.val !== this.userName.firstName ||
          this.name.middleName.val !== this.userName.middleName ||
          this.name.lastName.val !== this.userName.lastName
        ) {
          this.showWarning = true;
        } else {
          this.hideModal();
        }
      }
    },

    /**
     * Submit request to server for rename participant
     */
    async sumbitRenameRequest() {
      // Show Loader
      this.showLoader = true;

      // Send Rename request to server
      const response = await this.renameParticipant({
        firstName: this.name.firstName.val,
        middleName: this.name.middleName.val,
        lastName: this.name.lastName.val
      });

      // Hide Loader
      this.showLoader = false;

      // Show Error, if any
      if ("0" !== response.reasonCode) {
        this.serverErr = response.error.message;
      } else {
        this.hideModal();
      }
    },

    /**
     * Hide modal
     */
    hideModal() {
      this.setModal({
        ...this.modal,
        display: false
      });
    }
  },
  mounted() {
    this.name.firstName.val = this.userName.firstName;
    this.name.middleName.val = this.userName.middleName;
    this.name.lastName.val = this.userName.lastName;
  }
};
</script>

<style scoped>
.mandatory {
  color: #c13e3e;
}
.error {
  margin-bottom: 8px;
}
input {
  border: 1px solid #7a7a7a;
  background: #282828;
  max-width: calc(100% - 24px);
  padding: 5px 10px;
  color: #f6f6f6;
  position: relative;
  left: 1px;
  margin-bottom: 10px;
}
.submit-btn {
  margin-top: 10px;
  float: right;
}
.name-error {
  font-size: 13px;
  color: #c13e3e;
  position: relative;
  top: -5px;
  display: block;
}
.confirm-rename {
  font-size: 14px;
  color: #fff;
}
.yes-btn {
  margin-right: 1%;
}
</style>
