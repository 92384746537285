export const validateAndFormat = {
  data() {
    return {};
  },
  methods: {
    /**
     * Validate and format name(firstName, lastName)
     *
     * @param {String} value
     */
    validateName(value) {
      return (
        "string" === typeof value &&
        value.trim().length > 0 &&
        value.trim().length <= 60
      );
    },

    /**
     * Validate and format emails
     *
     * @param {String} value
     */
    validateEmail(value) {
      return (
        "string" === typeof value &&
        value.trim().length > 0 &&
        value.trim().length <= 60 &&
        value.trim().match(/^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/)
      );
    },

    /**
     * Validate phone number
     *
     * @param {String} value
     */
    validatePhone(value) {
      // Set phone as true
      let phone = true;
      try {
        if ("string" !== typeof value || value.trim().length <= 0) {
          phone = false;
        } else {
          // Split and join to get only the value
          value = value.split("-").join("");

          // Check if phone number value is interger
          if (isNaN(parseInt(value))) {
            phone = false;
          }

          // Check if phone number is within the range
          if (value < 1000000000 || value > 9999999999) {
            phone = false; // Phone is set to false if not in range
          }
        }
      } catch (err) {
        phone = false;
      }

      // Return phone
      return phone;
    },

    /**
     * Function to format the phone number
     *
     * @param {String} phoneNumber
     */
    formatPhone(phoneNumber) {
      const nums = phoneNumber.split("");
      let newNums = "";
      for (let i = 0; i < nums.length; i++) {
        if (!isNaN(nums[i]) && newNums.length < 12) {
          if (i !== 0 || parseInt(nums[i]) !== 0) {
            if (newNums.length === 3 || newNums.length === 7) {
              newNums += "-";
            }
            newNums += nums[i];
          }
        }
      }

      return newNums;
    }
  }
};
