/**
 * Getters for meet
 */
export const getters = {
  /**
   * Returns loggedin user stream
   *
   * @param {Object} state
   */
  getCameraStream(state) {
    return state.cameraStream;
  },

  /**
   * Returns flag which determines whether camera stream is ready
   *
   * @param {Object} state
   */
  getIsCameraStreamReady(state) {
    return state.isCameraStreamReady;
  },

  /**
   * Return flag which determines if peer event to be listened
   *
   * @param {Object} state
   */
  getListenToPeerEvent(state) {
    return state.listenToPeerEvent;
  },

  /**
   * Returns mobile camera facing mode
   *
   * @param {Object} state
   */
  getCameraFacingMode(state) {
    return state.cameraFacingMode;
  },

  /**
   * Returns the audio track
   *
   * @param {Object} state
   */
  getAudioTrack(state) {
    return state.audioTrack;
  },

  /**
   * Returns the video track
   *
   * @param {Object} state
   */
  getVideoTrack(state) {
    return state.videoTrack;
  },

  /**
   * Returns the loggedin user screen stream
   *
   * @param {Object} state
   */
  getScreenShareStream(state) {
    return state.screenShareStream;
  },

  /**
   * Returns Peer Connections Object list
   *
   * @param {Object} state
   */
  getPeerConnections(state) {
    return state.peerConnections;
  },

  /**
   * Returns the rtcRtpSenders List
   *
   * @param {Object} state
   */
  getRtcRtpSenders(state) {
    return state.rtcRtpSenders;
  },

  /**
   * Returns the meet room lock status
   *
   * @param {Object} state
   */
  getMeetDetails(state) {
    return state.meetDetails;
  },

  /**
   * Returns particiant list
   *
   * @param {Object} state
   */
  getParticipantList(state) {
    return state.participantList;
  },

  /**
   * Returns guest user list
   *
   * @param {Object} state
   */
  getGuestUserList(state) {
    return state.guestUserList;
  },

  /**
   * Returns list of audio input
   *
   * @param {Object} state
   */
  getAudioInput(state) {
    return state.audioInput;
  },

  /**
   * Returns list of video input
   *
   * @param {Object} state
   */
  getVideoInput(state) {
    return state.videoInput;
  },

  /**
   * Returns list of audio output
   *
   * @param {Object} state
   */
  getAudioOutput(state) {
    return state.audioOutput;
  },

  /**
   * Returns the selected audio input device id
   *
   * @param {Object} state
   */
  getSelectedAudioInput(state) {
    return state.selectedAudioInput;
  },

  /**
   * Returns the selected video input device id
   *
   * @param {Object} state
   */
  getSelectedVideoInput(state) {
    return state.selectedVideoInput;
  },

  /**
   * Returns the selected audio output device id
   *
   * @param {Object} state
   */
  getSelectedAudioOutput(state) {
    return state.selectedAudioOutput;
  },

  /**
   * Returns flag which determined if application is on full screen mode
   *
   * @param {Object} state
   */
  getFullScreenMode(state) {
    return state.fullScreenMode;
  },

  /**
   * Returns the host information
   *
   * @param {Object} state
   */
  getHostInfo(state) {
    return state.hostInfo;
  },

  /**
   * Returns the current display view
   *
   * @param {Object} state
   */
  getDisplayView(state) {
    return state.displayView;
  },

  /**
   * Returns the currently active pinned peer id
   *
   * @param {Object} state
   */
  getPinnedPeerId(state) {
    return state.pinnedPeerId;
  },

  /**
   * Returns flag that checks if end meeting popup is opened
   *
   * @param {Object} state
   */
  getIsEndMeetPopupOpened(state) {
    return state.isEndMeetPopupOpened;
  },

  /**
   * Returns the flag that checks if record dropdown is opened
   *
   * @param {Object} state
   */
  getIsRecordDrpDwnOpened(state) {
    return state.isRecordDrpDwnOpened;
  },

  /**
   * Returns the join id
   *
   * @param {Object} state
   */
  getJoinId(state) {
    return state.joinId;
  },

  /**
   * Returns the peers for which entry sound has been played
   *
   * @param {Object} state
   */
  getPeerEntryAudio(state) {
    return state.peerEntryAudio;
  }
};
