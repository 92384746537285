/**
 * Actions for participant
 */
export const actions = {
  /**
   * Request to send RSVP reply for participants
   *
   * @param {Object} context
   * @param {Object} param1
   */
  async sendRsvpReply(context, { connectCode, rsvpReply }) {
    const json = await context.dispatch(
      "makeHttpRequest",
      {
        url: `rsvp/${connectCode}`,
        method: "PUT",
        data: {
          RSVP: rsvpReply
        }
      },
      {
        root: true
      }
    );

    return json;
  },

  /**
   * Request to rename a participant
   *
   * @param {Object} param0
   * @param {Object} data
   */
  async renameParticipant({ dispatch, commit, rootGetters }, data) {
    const json = await dispatch(
      "makeHttpRequest",
      {
        url: `participant/rename`,
        method: "PUT",
        data: { ...data }
      },
      {
        root: true
      }
    );

    if ("0" === json.reasonCode) {
      // Set username
      commit("setUserName", json.userResponse, {
        root: true
      });

      // Set host info
      if (rootGetters["meet/getHostInfo"].id == rootGetters.getUserId) {
        commit(
          "meet/setHostInfo",
          {
            ...rootGetters["meet/getHostInfo"],
            name: {
              ...rootGetters["meet/getHostInfo"].name,
              firstName: data.firstName,
              middleName: data.middleName,
              lastName: data.lastName
            }
          },
          { root: true }
        );
      }
    }

    return json;
  },

  /**
   * Request to fetch connect code details
   *
   * @param {Object} context
   * @param {Object} param1
   */
  async getConnectCodeDetails(context, { connectCode }) {
    const json = await context.dispatch(
      "makeHttpRequest",
      {
        url: `participant/connectCode/${connectCode}`,
        method: "GET"
      },
      {
        root: true
      }
    );

    return json;
  },

  /**
   * Request to send the meeting invitation to all selected contacts
   *
   * @param {Object} context
   * @param {Object} param1
   */
  async sendMeetingInvitation(context, { body, to }) {
    const json = await context.dispatch(
      "makeHttpRequest",
      {
        url: `participant/sendInvitation`,
        method: "POST",
        data: {
          body,
          to
        }
      },
      {
        root: true
      }
    );

    return json;
  },

  /**
   * Request to fetch contact list
   *
   * @param {Object} context
   */
  async fetchUserList(context) {
    // Check the userListFetchStatus from state, if already fetched don't do any request
    if ("notInitiated" === context.getters.getUserListFetchStatus) {
      // Setting user list fetch status to pending on vuex store
      context.commit("setUserListFetchStatus", "pending");

      // Fetching the user list
      const json = await context.dispatch(
        "makeHttpRequest",
        {
          url: `participant/userList`,
          method: "GET"
        },
        {
          root: true
        }
      );

      if ("0" === json.reasonCode) {
        // Setting the client list on store
        context.commit(
          "setClients",
          json.contactList.details.filter(
            user => "person" === user.type && user.user_id > 0
          )
        );

        // Setting the person list on store
        context.commit(
          "setPersons",
          json.contactList.details.filter(
            user => "other_event" === user.type && user.user_id > 0
          )
        );

        // Setting the subscriber list on store
        context.commit(
          "setSubscribers",
          json.contactList.details.filter(
            user => "subscriber" === user.type && user.user_id > 0
          )
        );

        // Setting the app user list on store
        context.commit(
          "setAppUsers",
          json.contactList.details.filter(
            user => "app_user" === user.type && user.user_id > 0
          )
        );
      }

      // Setting contact list fetched to success on vuex store
      context.commit("setUserListFetchStatus", "success");

      // Return the response
      return json;
    }
  }
};
