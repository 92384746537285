<template>
  <div
    id="chat-conversation"
    :class="{
      'custom-scroll-v2': true,
      'chat-scroll': true,
      'wait-room': 'waitRoom' === meetDetails.room
    }"
    @scroll="listenToScrollBehaviour"
  >
    <template v-for="message in messages">
      <!-- UI for received message -->
      <div
        class="chat-msg-con-1"
        v-if="message.senderId !== userId"
        :key="message.id"
        :id="'chat-message' + message.id"
      >
        <ul class="cm-user-info">
          <li>
            <div class="cm-user-initial">
              {{
                `${
                  message.userName.firstName
                    ? message.userName.firstName[0]
                    : ""
                }${
                  message.userName.lastName ? message.userName.lastName[0] : ""
                }`.toUpperCase()
              }}
            </div>
          </li>
          <li>
            <div class="cm-user-name">
              {{
                `${message.userName.firstName}${
                  message.userName.middleName
                    ? " " + message.userName.middleName
                    : ""
                }${
                  message.userName.lastName
                    ? " " + message.userName.lastName
                    : ""
                }`
              }}
            </div>
            <div class="cm-user-time">
              {{ formattedTime(message.sentTime) }}
            </div>
          </li>
        </ul>
        <div
          class="cm-user-message"
          v-html="convertLinkMessage(message.message, 'received')"
        ></div>
      </div>

      <!-- UI for sent messages -->
      <template v-else>
        <div
          class="chat-msg-con-2"
          :key="message.id"
          :id="'chat-message' + message.id"
        >
          <ul class="cm-user-info">
            <li>
              <div class="cm-user-time">
                {{ formattedTime(message.sentTime) }}
              </div>
            </li>
          </ul>
          <div
            class="cm-user-message"
            v-html="convertLinkMessage(message.message, 'sent')"
          ></div>
        </div>

        <div class="clear" :key="'clear_' + message.id"></div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { bus } from "../../main";
import socket from "../../socket/socket";
import moment from "moment-timezone";
import { sanitizeAndFormat } from "../../mixins/sanitizeAndFormat";

export default {
  name: "MessageList",
  data() {
    return {};
  },
  mixins: [sanitizeAndFormat],
  computed: {
    ...mapGetters({
      userId: "getUserId",
      userTimezone: "getUserTimezone"
    }),

    ...mapGetters("chat", {
      messages: "getMessages"
    }),

    ...mapGetters("meet", {
      meetDetails: "getMeetDetails",
      hostInfo: "getHostInfo"
    })
  },
  props: {
    toggleDropdown: Function
  },
  methods: {
    ...mapMutations("chat", {
      readAllMessages: "readAllMessages"
    }),

    /**
     * Returns the formatted time
     */
    formattedTime(time) {
      let timezone = this.hostInfo.timezone;
      if (this.userTimezone) {
        timezone = this.userTimezone;
      }

      const sentTime = moment.utc(time, null).tz(timezone);

      const currentTime = moment().tz(timezone);

      if (0 === currentTime.diff(sentTime, "days")) {
        return sentTime.format("hh:mm A");
      } else {
        return sentTime.format("MM-YYYY-DD hh:mm A");
      }
    },

    /**
     * Function for onScroll listener
     */
    listenToScrollBehaviour() {
      const chatConversation = document.getElementById("chat-conversation");
      let clientHeight = chatConversation.clientHeight;
      let windowScrollHeight = chatConversation.scrollHeight;
      let scrollTop = chatConversation.scrollTop;

      // Toggling the dropdown.
      if (scrollTop + clientHeight <= windowScrollHeight * 0.98) {
        this.toggleDropdown(true);
      } else {
        this.toggleDropdown(false);

        // Mark read to all messages, when scrolled to down.
        const unreadMessages = this.messages.filter(
          message => message.senderId !== this.userId && !message.isRead
        );
        if (unreadMessages.length > 0) {
          socket.io.emit("readMsg", {
            id: this.messages[this.messages.length - 1].id
          });
        }
        this.readAllMessages();
      }
    },

    /**
     * Function to scroll the chat message list to bottom of div
     */
    scrollChatMessagesToBottom(
      eventType = null,
      scrollToBottomOnUpdate = false
    ) {
      const chatConversation = document.getElementById("chat-conversation");
      let clientHeight = chatConversation.clientHeight;
      let windowScrollHeight = chatConversation.scrollHeight;
      let scrollTop = chatConversation.scrollTop;

      // When new message received, show a dropdown
      if (
        "receiveMessage" === eventType &&
        scrollTop + clientHeight <= windowScrollHeight * 0.98
      ) {
        this.toggleDropdown(true);
      } else {
        // Scroll to bottom
        if (scrollToBottomOnUpdate) {
          this.$nextTick(this.scrollToBottom);
        } else {
          this.scrollToBottom();
        }

        if ("receiveMessage" === eventType) {
          const unreadMessages = this.messages.filter(
            message => message.senderId !== this.userId && !message.isRead
          );
          if (unreadMessages.length > 0) {
            socket.io.emit("readMsg", {
              id: this.messages[this.messages.length - 1].id
            });
          }
          this.readAllMessages();
        }
      }
    },

    /**
     * Function to scroll the chat scrollbar till bottom
     */
    scrollToBottom(scrollToFirstUnreadMessage = false) {
      const chatConversation = document.getElementById("chat-conversation");

      if (scrollToFirstUnreadMessage) {
        for (let i = 0; i <= this.messages.length; i++) {
          if (
            this.messages[i] &&
            false === this.messages[i].isRead &&
            this.messages[i].senderId !== this.userId
          ) {
            const elementToScrollTo = document.getElementById(
              "chat-message" + this.messages[i].id
            );
            const coordinates = elementToScrollTo.getBoundingClientRect();
            if (!("scrollTo" in chatConversation)) {
              chatConversation.scrollTop =
                coordinates.y -
                window.innerHeight +
                (window.innerWidth <= 768 ? 152 : 215);
            } else {
              chatConversation.scrollTo(
                coordinates.x,
                coordinates.y -
                  window.innerHeight +
                  (window.innerWidth <= 768 ? 152 : 215)
              );
            }

            return;
          }
        }
        chatConversation.scrollTop = chatConversation.scrollHeight;
      } else {
        chatConversation.scrollTop = chatConversation.scrollHeight;
      }
    }
  },
  async created() {
    bus.$on("scrollChatMessagesToBottom", this.scrollChatMessagesToBottom);
  },
  mounted() {
    const chatConversation = document.getElementById("chat-conversation");
    const clientHeight = chatConversation.clientHeight;
    const windowScrollHeight = chatConversation.scrollHeight;
    if (clientHeight === windowScrollHeight) {
      const unreadMessages = this.messages.filter(
        message => message.senderId !== this.userId && !message.isRead
      );
      if (unreadMessages.length > 0) {
        socket.io.emit("readMsg", {
          id: this.messages[this.messages.length - 1].id
        });
      }
      this.readAllMessages();
    } else {
      this.scrollToBottom(true);
    }
  }
};
</script>

<style scoped>
.simple-bar {
  height: 442px;
}
#chat-conversation {
  padding: 10px 15px;
  /* Changes for chat */
  /* height: 442px; */
}
/* Changes for chat */
#chat-conversation.wait-room {
  height: calc(100% - 106px);
}
.chat-msg-con-1 {
  margin-bottom: 10px;
}
.cm-user-info {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;

  /* This css is added later to display the avatar and name on same line */
  white-space: nowrap;
}
.cm-user-info li {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}
.cm-user-initial {
  border-radius: 50%;
  background: #f6f6f6;
  width: 30px;
  height: 30px;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 13px;
  border: 1px solid #ccc;
}
.cm-user-name {
  font-size: 12px;
  color: #666;

  /* This css is added later to display the avatar and name on same line */
  width: 100px !important;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.cm-user-time {
  font-size: 10px;
  color: #aaa;
}
.cm-user-message {
  background: #f1f1f1;
  border-radius: 5px;
  padding: 2px 10px;
  border: 1px solid #ccc;
  margin: 5px 20px 5px 0;
  border-bottom-left-radius: 0;
  display: inline-block;
  width: auto;
  word-break: break-word;
  white-space: pre-wrap;
}
.chat-msg-con-2 {
  float: right;
  max-width: 240px;
  word-break: break-all;
}
.chat-msg-con-2 .cm-user-message {
  background: #4089c5;
  color: #fff;
  border: 1px solid #2773b1;
  margin: 2px 0 10px 0;
  float: right;
}
.chat-msg-con-2 .cm-user-info li {
  margin-right: 0;
  display: block;
  text-align: right;
}
.un-msg-count {
  background: #888;
  font-size: 9px;
  padding: 1px 10px;
  border-radius: 10px;
  z-index: 3;
  position: relative;
  left: 0px;
  top: 5px;
}
a.icon-msg-scroll {
  width: 45px;
  height: 45px;
  display: block;
  background: url("../../assets/img/icon-msg-scroll.png");
  position: relative;
  z-index: 1;
}
#wrapper.zoomed-view #chat-conversation {
  height: 100%;
}
</style>
