<template>
  <div id="app-header">
    <!-- Psybooks Logo -->
    <a class="app-logo cursorPointer">
      <img :src="logoIcon" alt="Psybooks Connect" />
    </a>
    <a class="app-title cursorPointer">
      PSYBooks Connect
    </a>

    <!-- Section listing header icons -->
    <div class="header-icons">
      <!-- Icons for desktop view -->
      <div class="timer">
        <!-- Timer Section -->
        {{ timeDiff }}

        <!-- View Display Icon -->
        <a
          v-if="Object.keys(peerConnections).length > 0"
          @click.stop="toggleViewPopup"
          class="fullscreen-toggle switch-view cursorPointer"
        >
          <i class="bi-menu-app-fill"></i> VIEW
        </a>

        <!-- Displaying the switch view -->
        <div class="switch-view">
          <switch-view-vue
            v-if="openViewPopup"
            :toggle-view-popup="toggleViewPopup"
          ></switch-view-vue>
        </div>

        <!-- Full-Screen Icon -->
        <a
          v-if="checkFullScreenSupport"
          @click="toggleFullScreen"
          class="fullscreen-toggle cursorPointer"
        >
          <i class="bi bi-arrows-angle-expand"></i>
        </a>
      </div>

      <!-- Icons for mobile -->
      <ul class="header-tr-mob">
        <!-- Full-Screen Icon -->
        <li>
          <a
            v-if="checkFullScreenSupport"
            class="fullscreen-toggle cursorPointer"
            @click="toggleFullScreen"
          >
            <i class="bi bi-arrows-angle-expand"></i>
          </a>
        </li>

        <!-- Camera switch Icon -->
        <li>
          <a class="icon-flip cursorPointer" @click="switchMobileCamera">
            <i class="bi bi-arrow-repeat"> </i>
          </a>
        </li>

        <!-- Participant listing Icon -->
        <li>
          <a
            class="cursorPointer"
            @click="
              toggleSidebar({
                clickEvent: 'participant'
              })
            "
          >
            <span class="participant-count">
              {{ Object.keys(peerConnections).length + 1 }}
            </span>
            <i class="bi bi-people-fill"></i>
          </a>
        </li>

        <!-- Button to end/leave the  meeting -->
        <li>
          <a
            :class="{
              btn: true,
              'btn-primary': true,
              'btn-sm': true,
              'click-here': true,
              disabled: isEndMeetPopupOpened,
              cursorPointer: true
            }"
            @click.stop="toggleEndMeeting(true)"
          >
            End
          </a>
        </li>
      </ul>
    </div>

    <!-- End Meeting popup for mobile view -->
    <div class="meeting-cta" v-if="isEndMeetPopupOpened">
      <div id="end-meeting">
        <div class="common-modal" id="modal-end-meeting">
          <div class="modal-container">
            <div class="modal-header">
              <a
                @click.stop="toggleEndMeeting(false)"
                class="icon-close cursorPointer"
              >
                <i class="bi bi-x"></i>
              </a>
            </div>
            <div class="modal-body">
              <a class="btn-primary cursorPointer" @click="endMeetingForAll">
                End Meeting for All
              </a>
              <a class="btn-primary cursorPointer" @click="leaveMeeting">
                Leave Meeting
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { endMeeting } from "../../mixins/EndMeeting";
import { checkMobile } from "../../mixins/CheckMobile";
import SwitchViewVue from "./SwitchView";
import GeneralConstant from "../../constant/GeneralConstant";

export default {
  name: "Header",
  data() {
    return {
      logoIcon: require("../../assets/img/app-logo.png"),
      timeDiff: "00:00:00",
      startTime: new Date().getTime(),
      openViewPopup: false
    };
  },
  components: {
    SwitchViewVue
  },
  mixins: [checkMobile, endMeeting],
  props: {
    toggleSidebar: Function,
    toggleFullScreen: Function
  },
  computed: {
    ...mapGetters({
      userId: "getUserId",
      openedDropdown: "getOpenedDropdown",
      modal: "getModal"
    }),

    ...mapGetters("record", {
      recordingState: "getRecordingState",
      recordFileUploadStatus: "getRecordFileUploadStatus",
      recordedChunks: "getRecordedChunks"
    }),

    ...mapGetters("meet", {
      hostInfo: "getHostInfo",
      peerConnections: "getPeerConnections",
      isCameraStreamReady: "getIsCameraStreamReady",
      meetDetails: "getMeetDetails",
      cameraFacingMode: "getCameraFacingMode",
      cameraStream: "getCameraStream",
      audioTrack: "getAudioTrack",
      isEndMeetPopupOpened: "getIsEndMeetPopupOpened"
    }),

    /**
     * Computed property to check full-screen support.
     */
    checkFullScreenSupport() {
      return (
        document.fullscreenEnabled ||
        document.mozFullScreenEnable ||
        document.webkitFullscreenEnabled ||
        document.msFullscreenEnabled
      );
    }
  },
  watch: {
    openedDropdown: function(drpDwnName) {
      let keys = ["openViewPopup"];
      keys.forEach(key => {
        if (key !== drpDwnName) {
          this[key] = false;
        }
      });
    }
  },
  methods: {
    ...mapMutations({
      setOpenedDropdown: "setOpenedDropdown",
      setModal: "setModal",
      setDisplayModalOverlay: "setDisplayModalOverlay"
    }),

    ...mapMutations("meet", {
      setCameraFacingMode: "setCameraFacingMode",
      setIsEndMeetPopupOpened: "setIsEndMeetPopupOpened"
    }),

    /**
     * Function to be triggered on click of end meeting button
     */
    toggleEndMeeting(open) {
      if (this.recordFileUploadStatus === "uploading") {
        this.$store.dispatch("displayGlobalAlert", {
          type: "alert",
          msg: GeneralConstant.error.hostDisabledMultipleScreenShare
        });
      } else if (
        "recording" === this.recordingState ||
        (this.recordFileUploadStatus === "not-uploaded" &&
          this.recordedChunks.length > 0 &&
          "idle" === this.recordingState)
      ) {
        this.setModal({
          ...this.modal,
          display: true,
          title: "Upload Recording",
          bodyComponentName: "new-record-popup-vue",
          styleOverride: {}
        });
        this.setDisplayModalOverlay(true);
      } else {
        // If user is host, display the endMeetPopUp modal, else leave meeting
        if (this.userId === this.hostInfo.id) {
          this.setIsEndMeetPopupOpened(open);
          if (open) {
            this.setOpenedDropdown("isEndMeetPopupOpened");
          }
        } else {
          this.leaveMeeting();
        }
      }
    },

    /**
     * Function to toggle the pop-up view
     */
    toggleViewPopup() {
      this.openViewPopup = !this.openViewPopup;
      this.setOpenedDropdown(this.openViewPopup ? "openViewPopup" : "");
    },

    /**
     * Function to switch front/back camera of mobile
     */
    switchMobileCamera() {
      if (
        "screen" === this.meetDetails.videoTrackType ||
        !this.isCameraStreamReady ||
        this.meetDetails.isUserVideoMuted
      ) {
        return false;
      }

      this.setCameraFacingMode(
        "front" === this.cameraFacingMode ? "back" : "front"
      );
    }
  },
  async created() {
    // Sets the time on every 1 second interval
    setInterval(() => {
      // Get current date and time
      const now = new Date().getTime();

      // Find the difference between current time and start time
      const diff = now - this.startTime;

      // Time calculations for days, hours, minutes and seconds
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"
      this.timeDiff = `${("0" + hours).slice(-2)}:${("0" + minutes).slice(
        -2
      )}:${("0" + seconds).slice(-2)}`;
    }, 1000);
  }
};
</script>

<style scoped>
/*===Header===*/
#app-header {
  position: relative;
  border-bottom: 1px solid #111;
}
.record.disabled {
  color: #d2c8c8 !important;
}
a.app-title {
  position: absolute;
  width: 160px;
  color: #fff;
  font-size: 16px;
  left: 50%;
  margin-left: -80px;
  z-index: 2;
  top: 8px;
  text-align: center;
}
nav {
  position: fixed;
  top: 0;
  height: 40px;
  background: #000;
  width: 100%;
  z-index: 1;
}

#app-window-cta {
  float: right;
  width: 90px;
  padding: 0;
  margin: 8px 20px 0 0;
}

#app-window-cta li {
  display: inline-block;
  margin-right: 20px;
}
#app-window-cta li:last-child {
  margin-right: 0px;
}
#app-window-cta li a {
  color: #eeb747;
}
.switch-view {
  display: inline;
  color: #eeb747;
}
a.app-logo {
  position: absolute;
  top: 2px;
  left: 10px;
  z-index: 3;
  max-width: 110px;
  padding: 0 10px 0 5px;
}
a.app-logo img {
  max-width: 100%;
}

.header-tr-mob {
  display: none;
}
/* End Meeting Style Start */
#end-meeting {
  position: absolute;
  bottom: 220px;
  right: 440px;
  padding: 0;
  text-align: center;
}
#modal-end-meeting {
  background: #282828;
  width: 380px;
  padding: 20px 10px;
}
#modal-end-meeting .modal-header {
  position: relative;
  padding-top: 0;
}
#modal-end-meeting .modal-header a.icon-close i {
  color: #fff;
  font-size: 30px;
  position: absolute;
  right: 10px;
  top: -20px;
}
#modal-end-meeting .modal-body {
  padding: 10px;
  margin-top: 30px;
}
#modal-end-meeting .modal-body a {
  margin: 0px 10px 0;
}
.header-tr-mob li a.disabled {
  background: #e1a065 !important;
  border-color: #e1a065;
}
@media screen and (max-width: 768px),
  screen and (orientation: landscape) and (max-height: 425px) {
  .switch-view {
    display: none;
  }
  a.app-title {
    display: none;
  }
  .timer {
    top: 10px;
    left: 50%;
    margin-left: -50px;
    width: 100px;
  }
  .header-tr-mob {
    display: block;
    list-style: none;
    float: right;
    margin: 3px 5px 0 0;
    padding: 0;
  }
  .header-tr-mob li {
    display: inline-block;
    margin: 2px 20px 0 5px;
    position: relative;
  }
  .header-tr-mob li:last-child {
    margin-right: 0;
  }
  .header-tr-mob li a {
    color: #eeb747;
  }
  .header-tr-mob li a.fullscreen-toggle {
    padding-left: 0;
  }
  .header-tr-mob li a span.participant-count {
    font-size: 11px;
    position: absolute;
    right: -9px;
    top: -5px;
    color: #fff;
  }
  .header-tr-mob li i {
    font-size: 20px;
  }
  .header-tr-mob li i.bi.bi-arrow-repeat {
    font-size: 24px;
  }
  .header-tr-mob li i.bi.bi-arrows-angle-expand {
    font-size: 18px;
  }
  .header-icons {
    width: 210px;
    float: right;
    padding: 0;
    margin: 0 10px 0 0;
  }
  a.icon-flip {
    font-size: 22px;
    color: #fff;
    display: block;
  }
  a.btn.click-here {
    color: #fff;
  }
  .timer {
    display: none;
  }
  #app-header {
    min-height: 50px;
    max-height: 50px;
  }
  #footer {
    position: fixed;
  }
  #main-wrapper {
    top: 20px;
  }
  .meeting-cta {
    display: block;
    position: absolute;
    top: 30px;
    width: 99%;
    left: 0;
  }
  #end-meeting {
    width: 100%;
    bottom: 0;
    right: 0;
    left: -20px;
  }
  #modal-end-meeting {
    width: 100%;
  }
  #modal-end-meeting .modal-body a {
    display: block;
    margin: 0 auto 20px 0;
  }
}
@media screen and (max-width: 320px) {
  .app-logo {
    top: 10px;
  }
  .app-logo img {
    max-width: 100px;
  }
  a.app-logo {
    max-width: 90px;
    padding: 6px 10px 0 0px;
  }
}

@media screen and (orientation: landscape) and (max-height: 425px) {
  .switch-view {
    display: none;
  }
}

/* End Meeting Style End */
</style>
