let updateSocketLeaveTimeInterval = false;

import socket from "../../../socket/socket";

/**
 * Actions for meet
 */
export const actions = {
  /**
   * Request to validate meet link
   *
   * @param {Object} context
   * @param {Object} param1
   */
  async validateMeetLink(context, { meetCode, passCode }) {
    // Make the http request
    const json = await context.dispatch(
      "makeHttpRequest",
      {
        url: `auth/${
          "true" === window.sessionStorage.getItem("isGuestUser")
            ? "validateGuestUserMeetLink"
            : "validateMeetLink"
        }`,
        method: "POST",
        data: {
          meetCode: meetCode,
          passCode: passCode
        }
      },
      {
        root: true
      }
    );

    if ("0" === json.reasonCode) {
      // Set if user is a guest user
      context.commit(
        "setIsGuestUser",
        json.userResponse.isGuestUser ? true : false,
        {
          root: true
        }
      );

      // Setting guest user flag on session storage
      if (json.userResponse.isGuestUser) {
        window.sessionStorage.setItem("isGuestUser", true);
      } else {
        window.sessionStorage.removeItem("isGuestUser");
      }

      // Set the token on session storage
      window.sessionStorage.setItem("token", json.userResponse.token);

      // Set the token on vuex store
      context.commit("setToken", json.userResponse.token, {
        root: true
      });

      // Set the userId
      context.commit("setUserId", json.userResponse.userId, {
        root: true
      });

      // Set the username
      context.commit("setUserName", json.userResponse.userName, {
        root: true
      });

      // Set user timezone
      context.commit("setUserTimezone", json.userResponse.userTimezone, {
        root: true
      });

      // Set encrypted provider id
      context.commit(
        "setEncryptedProviderId",
        json.userResponse.encryptedExternalId,
        {
          root: true
        }
      );

      // Set meet room lock status, wait room enabled/disabled status and meeting scheduled time on vuex store
      context.commit("setMeetDetails", {
        ...context.state.meetDetails,
        meetRoomLockStatus:
          "isLocked" in json.userResponse && json.userResponse.isLocked
            ? true
            : false,
        enableWaitRoom:
          "isWaitRoomEnabled" in json.userResponse &&
          json.userResponse.isWaitRoomEnabled
            ? true
            : false,
        meetScheduledTime: json.userResponse.scheduledTime
      });

      // Set the host info
      context.commit("setHostInfo", {
        ...context.state.hostInfo,
        id: json.userResponse.hostId,
        contact: json.userResponse.hostPhone,
        name: json.userResponse.hostName,
        timezone: json.userResponse.hostTimezone
      });
    }

    return json;
  },

  /**
   * Update socket leaving time periodically
   *
   * @param {Object} context
   */
  async updateSocketLeaveTimePeriodically(context) {
    if (updateSocketLeaveTimeInterval) {
      context.dispatch("clearUpdateSocketLeaveTimeInterval");
    }

    // On every 10 seconds send a request to backed to update socket leaving time
    if (socket.io) {
      updateSocketLeaveTimeInterval = setInterval(async () => {
        socket.io.emit("updateSocketLeavingTime");
      }, 60000);
    }
  },

  /**
   * Clear interval for updating socket leaving time
   */
  clearUpdateSocketLeaveTimeInterval() {
    clearInterval(updateSocketLeaveTimeInterval);
  },

  /**
   * Request to lock/unlock meet room
   *
   * @param {Object} context
   * @param {Boolean} param1
   */
  async lockUnlockMeetRoom(context, { isLock }) {
    // Make the http request
    const json = await context.dispatch(
      "makeHttpRequest",
      {
        url: `session/lock`,
        method: "POST",
        data: {
          isLock: isLock
        }
      },
      {
        root: true
      }
    );

    // Sets meet room lock status on vuex store
    context.commit("setMeetDetails", {
      ...context.state.meetDetails,
      meetRoomLockStatus: isLock
    });

    return json;
  },

  /**
   * Request to enable/disable wait room
   *
   * @param {Object} context
   * @param {Boolean} param1
   */
  async toggleWaitRoom(context, { isEnable }) {
    // Make the http request
    const json = await context.dispatch(
      "makeHttpRequest",
      {
        url: `session/toggleWaitRoom`,
        method: "POST",
        data: {
          isEnable
        }
      },
      {
        root: true
      }
    );

    // Sets waiting room enabled/disabled status on vuex store
    context.commit("setMeetDetails", {
      ...context.state.meetDetails,
      enableWaitRoom: isEnable
    });

    return json;
  }
};
