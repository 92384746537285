import socket from "../../socket/socket";
import { mapGetters, mapMutations } from "vuex";
import { media } from "../Media";

export const hostControlEventListener = {
  data() {
    return {};
  },
  mixins: [media],
  computed: {
    ...mapGetters("meet", {
      meetDetails: "getMeetDetails"
    })
  },
  methods: {
    ...mapMutations("meet", {
      setMeetDetails: "setMeetDetails"
    }),

    /**
     * Listening to host event
     */
    listenToHostEvent() {
      // Listener, when host changes any existing settings
      socket.io.on("hostControl", data => {
        this.setMeetDetails({
          ...this.meetDetails,
          ...data
        });
      });

      // Listener on toggling(off/on) of participants stream(video/audio)
      socket.io.on("toggleParticipantStreamByHost", data => {
        if ("audio" === data.type) {
          this.toggleAudio(data.mute);
        } else if ("video" === data.type) {
          this.toggleVideo(data.mute);
        }
      });
    }
  }
};
