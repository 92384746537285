/**
 * Getters for chat
 */
export const getters = {
  /**
   * Returns the chat-message list
   *
   * @param {Object} state
   */
  getMessages(state) {
    return state.messages;
  },

  /**
   * Returns notification list
   *
   * @param {Object} state
   */
  getNotifications(state) {
    return state.notifications;
  },

  /**
   * Retuns chat message fetch status
   *
   * @param {Object} state
   */
  getChatMessageFetchStatus(state) {
    return state.chatMessageFetchStatus;
  }
};
