/**
 * Actions for chat
 */
export const actions = {
  /**
   * Request to fetch all chat messages of the user
   *
   * @param {Object} context
   * @param {Object} param1
   */
  async fetchChatMessages(context) {
    // Setting contact list fetch status to pending on vuex store
    context.commit("setChatMessageFetchStatus", "pending");

    // Fetching the contact list
    const json = await context.dispatch(
      "makeHttpRequest",
      {
        url: `chat/messages`,
        method: "GET"
      },
      {
        root: true
      }
    );

    // Setting contact list fetched to success on vuex store
    context.commit("setChatMessageFetchStatus", "success");

    // Setting the contact list on store
    if ("0" === json.reasonCode) {
      context.commit("setMessages", json.messageList);
    }

    // Return the response
    return json;
  }
};
