<template>
  <div class="common-modal" id="video-setting-m1">
    <div class="modal-container">
      <div class="modal-header">
        <h4>Can you see your face ?</h4>
        <a @click="hideVideoTestPopup" class="icon-close cursorPointer">
          <i class="bi bi-x"></i>
        </a>
      </div>

      <div class="modal-body">
        <form action="#">
          <label id="user-video-opt" class="dis-bl">
            Select camera
          </label>
          <div class="custom-select sel-medium">
            <select id="user-video-opt" ref="cameraList" @change="changeCamera">
              <option
                v-for="input in videoInput"
                :value="input.key"
                :key="input.key"
                :selected="input.key === selectedVideoInput"
              >
                {{ input.value }}
              </option>
            </select>
          </div>
          <div class="pids-wrapper test-video">
            <video
              autoplay
              muted
              playsinline
              ref="testvideo"
              id="testvideo"
              :class="{
                'flip-effect': enableMirrorEffect
              }"
            ></video>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <div class="modal-f-ctas">
          <a
            @click="hideVideoTestPopup"
            class="btn-primary-outline mr-1 cursorPointer"
          >
            Yes
          </a>
          <a
            @click="toggleMirrorEffect(!enableMirrorEffect)"
            class="btn-primary-outline btn-flip cursorPointer"
          >
            <i class="icon-flip-mirror"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkMobile } from "../../mixins/CheckMobile";

export default {
  name: "VideoTest",
  mixins: [checkMobile],
  data() {
    return {
      enableMirrorEffect: false,
      isCameraStreamReady: false
    };
  },
  computed: {
    ...mapGetters("meet", {
      videoInput: "getVideoInput",
      selectedVideoInput: "getSelectedVideoInput"
    })
  },
  methods: {
    /**
     * Function to change camera
     */
    changeCamera() {
      if (this.isCameraStreamReady) {
        this.loadVideoStream(this.$refs.cameraList.value);
      }
    },

    /**
     * Function to hide video test popup
     */
    hideVideoTestPopup() {
      this.$emit("hideVideoTestPopup");
    },

    /**
     * Function to toggle the mirror effect
     */
    toggleMirrorEffect(isEnable) {
      this.enableMirrorEffect = isEnable;
    },

    /**
     * Loading the video stream from a given video input device
     */
    loadVideoStream(videoInput) {
      this.isCameraStreamReady = false;
      navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: videoInput ? { exact: videoInput } : undefined
          },
          audio: false
        })
        .then(stream => {
          document.getElementById("testvideo").srcObject = stream;
          document.getElementById("testvideo").load();
        })
        .catch(this.errorHandler)
        .finally(() => {
          this.isCameraStreamReady = true;
        });
    },

    /**
     * Error on accessing input/output device gets catchup here
     *
     * @param {Object} error
     */
    errorHandler(error) {
      let errorMsg = error;
      if ("object" === typeof error) {
        errorMsg = error.message;
      }
      this.$store.dispatch("displayGlobalAlert", {
        type: "error",
        msg: errorMsg
      });
    }
  },
  async created() {
    // Loading the video stream input from default selected video input device
    this.loadVideoStream(this.selectedVideoInput);
  }
};
</script>

<style scoped>
/* Hiding the controls of videos */
.flip-effect {
  -webkit-transform: scaleX(-1) !important;
  transform: scaleX(-1) !important;
}
.flip-effect::-webkit-media-controls {
  display: none !important;
}

#video-setting-m1.common-modal {
  z-index: 4;
  /*--max-width: 360px;--*/
  position: relative;
  left: unset;
  width: 32%;
  margin-right: 1%;
  height: fit-content;
}
#video-setting-m1.common-modal .custom-select {
  margin-left: 0;
  max-width: 340px;
}

#wrapper.wrapper-waiting-room #main-wrapper video#testvideo {
  min-height: 240px !important;
  max-width: 100% !important;
  padding: 0;
  /* margin: 20px 0 0 !important; */
}
.test-video {
  min-height: 240px !important;
  margin: 20px 0 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
a.btn-flip {
  float: right;
  padding: 2px 5px;
  font-size: 0;
}
.icon-flip-mirror {
  background: url("../../assets/img/icon-flip-mirror.png") no-repeat center;
  width: 24px;
  height: 24px;
  display: inline-block;
}
@media screen and (max-width: 768px) {
  #video-setting-m1 {
    display: none;
  }
}
</style>
