<template>
  <div
    id="security-options"
    :class="{
      'cb-dropdown': true,
      'record-dropdown': true,
      'full-width': 'idle' === recordingState && recordedChunks.length > 0
    }"
    @click="preventClickAction"
  >
    <h6>
      {{ recordText }}
      <a class="icon-mobsettings-close cursorPointer">
        <i class="bi bi-x icon-dropdown"></i
      ></a>
    </h6>
    <div class="menu">
      <ul class="submenu">
        <li v-if="'idle' !== recordingState" class="record-options">
          <a class="cursorPointer" @click.stop="pausePlayRecording">
            <span class="icon-tick">
              <i
                class="bi bi-record-btn"
                v-if="'paused' === recordingState"
              ></i>
              <i class="bi bi-pause" v-else></i>
            </span>
            <span class="list-option">
              {{ "paused" === recordingState ? "Resume" : "Pause" }}
            </span>
          </a>
        </li>
        <li v-if="'idle' !== recordingState" class="record-options">
          <a class="cursorPointer" @click.stop="toggleRecording">
            <span class="icon-tick">
              <i class="bi bi-stop"></i>
            </span>
            <span class="list-option"> Stop </span>
          </a>
        </li>
        <li v-if="'idle' === recordingState && recordedChunks.length > 0">
          <div class="file-upload-tool">
            <a @click.stop="uploadRecordFile" class="upload-link cursorPointer">
              <div
                v-if="'uploading' === recordFileUploadStatus"
                class="loader loader-xs loader-fs"
              ></div>
              <span class="icon-tick" v-else>
                <i class="bi bi-arrow-bar-up"></i>
              </span>
              <span class="list-option"> Upload </span>
            </a>
            <span
              class="file-upload-info"
              v-if="
                'uploading' === recordFileUploadStatus && uploadPercentage > 0
              "
            >
              {{ uploadPercentage }}%
            </span>
          </div>
          <div class="file-upload-hint">
            NOTICE: The recording will not be saved until you upload it.
          </div>
        </li>
        <li v-if="fileId > 0" class="record-options">
          <a class="cursorPointer" @click.stop="downloadRecording">
            <div
              v-if="isRecordFileDownloading"
              class="loader loader-xs loader-fs"
            ></div>
            <span class="icon-tick" v-else>
              <i class="bi bi-download"></i>
            </span>
            <span class="list-option"> Download </span>
          </a>
          <span class="file-upload-info">
            <a class="cursorPointer" @click.stop="downloadRecording">
              {{ recordedFileName }}
            </a>
          </span>
        </li>
        <li
          v-if="
            'idle' === recordingState &&
              'uploading' !== this.recordFileUploadStatus
          "
          class="record-options"
        >
          <a class="cursorPointer" @click.stop="startRecording(true)">
            <span class="icon-tick">
              <i class="bi bi-record-btn"></i>
            </span>
            <span class="list-option"> Record Again </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "RecordOptions",
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapGetters({
      modal: "getModal"
    }),

    ...mapGetters("record", {
      recordingState: "getRecordingState",
      uploadPercentage: "getUploadPercentage",
      recordedChunks: "getRecordedChunks",
      recordFileUploadStatus: "getRecordFileUploadStatus",
      fileId: "getFileId",
      isRecordFileDownloading: "getIsRecordFileDownloading",
      recordedFileName: "getRecordedFileName"
    }),

    /**
     * Returns the recording text to be displayed
     */
    recordText() {
      if ("idle" === this.recordingState && this.recordedChunks.length > 0) {
        return "Recording Stopped";
      } else if ("paused" === this.recordingState) {
        return "Recording Paused";
      } else if ("recording" === this.recordingState) {
        return "Recording in Progress";
      } else {
        return "Record";
      }
    }
  },
  props: {
    toggleRecording: Function,
    pausePlayRecording: Function,
    startRecording: Function,
    downloadRecording: Function
  },
  methods: {
    ...mapMutations({
      setModal: "setModal"
    }),

    /**
     * Prevents click action
     */
    preventClickAction(event) {
      event.stopPropagation();
    },

    /**
     * Showing a popup to get a file name
     */
    uploadRecordFile() {
      if (
        "uploading" !== this.recordFileUploadStatus &&
        !this.isRecordFileDownloading
      ) {
        this.setModal({
          ...this.modal,
          display: true,
          title: "",
          bodyComponentName: "upload-file-vue",
          styleOverride: {
            top: "auto",
            left: "auto",
            right: "50px",
            bottom: "147px",
            "max-width": "550px",
            "z-index": 999
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.record-dropdown {
  bottom: 56px;
  right: -90px;
  text-align: left;
  width: 180px;
  display: initial !important;
  padding: 15px 15px 5px 15px !important;
}
.full-width {
  width: 200px;
}
#host-pc-controls li {
  position: relative;
}
#host-pc-controls li a.security-dropdown {
  position: absolute;
  left: 40px;
  top: -5px;
}

#host-pc-controls li a.security-dropdown {
  left: 55px;
}
#security-options span.icon-tick {
  top: 10px;
}
#security-options.show {
  display: block;
}
#security-options h6 {
  position: relative;
}
#security-options p {
  color: #fff;
}
.con-lhs {
  float: left;
  color: #fff;
}
.con-rhs {
  float: right;
  color: #fff;
}
.loader-fs {
  left: 5px !important;
  top: -42px !important;
}
a.icon-mobsettings-close {
  color: #fff;
  position: absolute;
  right: 30px;
  font-size: 24px !important;
  display: none;
  top: -3px !important;
}
ul.share-list {
  list-style: none;
  padding: 0;
  margin: 10px 0 0 50px;
}
ul.share-list li {
  margin-bottom: 5px;
}
ul.share-list li .bi-chevron-right {
  margin-left: 70px;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.upload-link {
  display: inline-block;
}

.upload-percentage {
  display: initial;
  float: right;
}

.menu .submenu li.record-options {
  display: flex !important;
  justify-content: space-between !important;
}

.menu .submenu li .file-upload-info {
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: right;
}

.menu .submenu li div.file-upload-tool {
  display: flex !important;
  justify-content: space-between !important;
}

.menu .submenu li div.file-upload-hint {
  margin: 5px 0px 0px 25px;
  color: #eeb747;
  font-style: italic;
  font-size: 12px;
}

@media screen and (max-width: 768px),
  /* Changes for settings on landscape view */screen and (orientation: landscape) and (max-height: 425px) {
  #security-options.cb-dropdown {
    position: fixed;
    display: flex;
    top: 0;
    flex-direction: column;
    /* Changes for settings on portrait view */
    /* height: calc(100vh - 91px); */
    height: 100%;
    left: 0;
    width: 100%;
    z-index: 999;
  }
  #security-options.cb-dropdown ul.submenu {
    width: 94% !important;
    margin: 0;
  }
  a.icon-mobsettings-close {
    display: block;
  }
  .menu {
    max-height: calc(100% - 45px);
    overflow-y: scroll;
  }
}
</style>
