import io from "socket.io-client";

export default {
  io: null,
  initialize(auth) {
    this.io = io(process.env.SOCKET_CONN_URL, {
      reconnectionDelay: 10000,
      reconnectionDelayMax: 10000,
      auth: {
        ...auth
      }
    });
  }
};
