<template>
  <div
    id="participants-container"
    class="participant-view full-panel"
    style="display: block;"
  >
    <div class="sideblock-container">
      <!-- Participant header section -->
      <div class="side-topbar">
        <h4>
          Participants
          <span class="total-pc">
            ({{ Object.keys(peerConnections).length + 1 }})
          </span>
        </h4>
        <div class="side-topbar-dropdown">
          <a
            class="cursorPointer"
            @click="toggleSidebar({ clickEvent: 'participant', isClose: true })"
          >
            <i class="bi bi-x icon-dropdown icon-sidepanel-close"></i>
          </a>
        </div>
      </div>

      <!-- Participant Listing Section -->
      <div class="side-topbar-content">
        <ul class="participant-list custom-scroll-v2 participant-scroll">
          <li>
            <div class="pc-initial pc-host">
              {{
                `${userName.firstName ? userName.firstName[0] : ""}${
                  userName.lastName ? userName.lastName[0] : ""
                }`.toUpperCase()
              }}
            </div>
            <div class="pc-name">
              {{
                `${userName.firstName}${
                  userName.middleName ? " " + userName.middleName : ""
                }${userName.lastName ? " " + userName.lastName : ""}`
              }}
            </div>
            <div class="pc-cta">
              <a
                class="cursorPointer tooltip"
                @click="checkAndChangeAudioStatus"
              >
                <i
                  :class="{
                    bi: true,
                    'bi-mic': !meetDetails.isUserAudioMuted,
                    'bi-mic-mute-fill': meetDetails.isUserAudioMuted
                  }"
                ></i>
                <span class="tooltiptext">Mute/Unmute audio</span>
              </a>
              <a class="cursorPointer tooltip" @click="toggleVideo(null)">
                <i
                  :class="{
                    bi: true,
                    'bi-camera-video': !meetDetails.isUserVideoMuted,
                    'bi-camera-video-off-fill': meetDetails.isUserVideoMuted
                  }"
                ></i>
                <span class="tooltiptext right-arrow">Start/Stop video</span>
              </a>
            </div>
            <div class="clear"></div>
          </li>
          <li v-for="(connection, key) in peerConnections" :key="key">
            <div class="pc-initial pc-host">
              {{
                `${
                  connection.userName.firstName
                    ? connection.userName.firstName[0]
                    : ""
                }${
                  connection.userName.lastName
                    ? connection.userName.lastName[0]
                    : ""
                }`.toUpperCase()
              }}
            </div>
            <div
              class="pc-name"
              v-html="
                `${connection.userName.firstName}${
                  connection.userName.middleName
                    ? ' ' + connection.userName.middleName
                    : ''
                }${
                  connection.userName.lastName
                    ? ' ' + connection.userName.lastName
                    : ''
                }`
              "
            ></div>
            <div class="pc-cta">
              <a
                class="cursorPointer tooltip"
                @click="
                  toggleParticipantStream('audio', key, connection.isAudioMuted)
                "
              >
                <i
                  :class="{
                    bi: true,
                    'bi-mic': !connection.isAudioMuted,
                    'bi-mic-mute-fill': connection.isAudioMuted
                  }"
                ></i>
                <span class="tooltiptext">Mute/Unmute Audio</span>
              </a>
              <a
                class="cursorPointer tooltip"
                @click="
                  toggleParticipantStream('video', key, connection.isVideoMuted)
                "
              >
                <i
                  :class="{
                    bi: true,
                    'bi-camera-video': !connection.isVideoMuted,
                    'bi-camera-video-off-fill': connection.isVideoMuted
                  }"
                ></i>
                <span
                  :class="{
                    'right-arrow': userId !== hostInfo.id,
                    tooltiptext: true
                  }"
                  >Start/Stop video</span
                >
              </a>
              <a
                class="cursorPointer tooltip"
                v-if="userId === hostInfo.id"
                @click="removeParticipant(key)"
              >
                <i class="bi bi-person-x"></i>
                <span class="tooltiptext right-arrow">Remove Participant</span>
              </a>
            </div>
            <div class="clear"></div>
          </li>
          <li
            v-for="participant in notJoinedParticipants"
            :key="participant.userId"
          >
            <div class="pc-initial pc-host">
              {{
                `${
                  participant.userName.firstName
                    ? participant.userName.firstName[0]
                    : ""
                }${
                  participant.userName.lastName
                    ? participant.userName.lastName[0]
                    : ""
                }`.toUpperCase()
              }}
            </div>
            <div
              class="pc-name"
              v-html="
                `${participant.userName.firstName}${
                  participant.userName.middleName
                    ? ' ' + participant.userName.middleName
                    : ''
                }${
                  participant.userName.lastName
                    ? ' ' + participant.userName.lastName
                    : ''
                }`
              "
            ></div>
            <div class="pc-cta">
              <a
                class="cursorPointer tooltip"
                v-if="participant.isUserDeniedToMeetRoom"
                @click="acceptParticipant(participant.userId)"
              >
                <i class="bi bi-person-check"></i>
                <span class="tooltiptext right-arrow">Accept participant</span>
              </a>
            </div>
            <div class="clear"></div>
          </li>
        </ul>
      </div>
    </div>

    <div class="host-cta-options" v-if="userId === hostInfo.id">
      <!-- Invite Participant Button -->
      <a @click="showInviteParticipant" class="btn-secondary cursorPointer">
        Invite
      </a>

      <!-- Button to mute all participants -->
      <a @click="muteAllParticipants" class="btn-secondary cursorPointer">
        Mute All
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import socket from "../../socket/socket";
import { media } from "../../mixins/Media";
import { participant } from "../../mixins/Participant";

export default {
  name: "Participant",
  data() {
    return {};
  },
  mixins: [participant, media],
  computed: {
    ...mapGetters({
      userId: "getUserId",
      userName: "getUserName",
      modal: "getModal"
    }),

    ...mapGetters("meet", {
      hostInfo: "getHostInfo",
      meetDetails: "getMeetDetails",
      peerConnections: "getPeerConnections",
      participantList: "getParticipantList"
    }),

    /**
     * Returns the participants which are not joined
     */
    notJoinedParticipants() {
      return this.userId === this.hostInfo.id
        ? this.participantList.filter(
            participant =>
              !Object.values(this.peerConnections).find(
                pc => pc.userId === participant.userId
              )
          )
        : [];
    }
  },
  props: {
    toggleSidebar: Function,
    toggleFooter: Function
  },
  methods: {
    ...mapMutations({
      setModal: "setModal",
      setDisplayModalOverlay: "setDisplayModalOverlay"
    }),

    /**
     * Show Invite Participant pop-up
     */
    showInviteParticipant() {
      this.setModal({
        ...this.modal,
        display: true,
        title: "Invite Participants",
        bodyComponentName: "invite-participant-vue",
        styleOverride: {
          top: "8%"
        }
      });
      this.setDisplayModalOverlay(true);
      this.toggleSidebar({ clickEvent: "participant", isClose: true });
    },

    /**
     * Function to restore deny participant request.
     */
    acceptParticipant(userId) {
      socket.io.emit("acceptParticipant", { userId });
    },

    /**
     * Function to mute all participants
     */
    muteAllParticipants() {
      socket.io.emit("toggleParticipantStreamByHost", {
        type: "audio",
        dest: "all",
        mute: true
      });
    },

    /**
     * Function to mute/un-mute any specific participant
     */
    toggleParticipantStream(type, socketId, isMuted) {
      if (
        this.userId === this.hostInfo.id &&
        ["connected", "completed"].includes(
          this.peerConnections[socketId].iceState
        )
      ) {
        socket.io.emit("toggleParticipantStreamByHost", {
          type,
          dest: socketId,
          mute: !isMuted
        });
      }
    },

    /**
     * Function to remove a participant by Host.
     */
    removeParticipant(socketId) {
      this.removeSocketConnection(socketId);

      socket.io.emit("removeParticipant", {
        socketId: socketId
      });
    }
  }
};
</script>

<style scoped>
/*---Participants Block---*/
#participants-container {
  display: none;
  position: relative;
}
#participants-container.participant-view.full-panel .side-topbar-content {
  padding: 5px 0;
  /* Changes for chat */
  /* height: calc(100vh - 100px); */
  height: calc(100% - 125px);
}
#participants-container.half-panel .side-topbar-content {
  height: calc(50vh - 30px) !important;
}
#participants-container.half-panel .custom-scroll-v2.participant-scroll {
  max-height: calc(50vh - 100px);
}
#participants-container.maximized-panel .custom-scroll-v2.participant-scroll {
  max-height: calc(100vh - 140px);
  height: calc(100vh - 140px);
}
.participant-list {
  padding: 0 15px;
  margin: 0;
  list-style: none;
}
.participant-list li {
  margin: 15px auto 20px;
}
.pc-initial {
  float: left;
  width: 30px;
  border-radius: 2px;
  height: 30px;
  background: #f5f5f9;
  border: 1px solid #ccc;
  text-align: center;
  font-size: 12px;
  line-height: 30px;
  margin-right: 10px;
  color: #384a65;
  font-weight: bold;
}
.pc-host {
  background: #4089c5;
  border: 1px solid #4089c5;
  color: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.pc-cohost {
  background: #eaac2f;
  border: 1px solid #eaac2f;
  color: #fff;
}
.pc-name {
  float: left;
  font-size: 13px;
  line-height: 30px;
  color: #6c757d;
}
.pc-cta {
  float: right;
  margin-top: 8px;
}
.pc-cta i {
  color: #000;
  font-size: 18px;
}
.pc-cta i.bi.bi-mic-mute-fill,
.pc-cta i.bi.bi-camera-video-off-fill {
  color: #d26d13 !important;
}
.pc-cta a {
  display: inline-block;
  margin-right: 20px;
}
.pc-cta a:last-child {
  margin-right: 0;
}
.host-cta-options {
  border-top: 1px solid #ccc;
  padding: 10px 15px;
  max-width: 270px;
  width: calc(100% - 30px);
  position: absolute;
  bottom: 0;
  background: #fff;
  min-width: calc(100% - 30px);
}
.host-cta-options a {
  display: inline-block;
  margin: 10px 10px 10px 0;
  text-align: center;
}
#participants-container.full-panel .sideblock-container {
  height: calc(100vh - 90px);
}
/* #participants-container.minified-panel .host-cta-options,
#participants-container.participant-view .host-cta-options {
  display: none !important;
} */
.btn-secondary {
  font-size: 14px;
  font-weight: bold;
}

.pc-name {
  width: calc(100% - 150px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -65px;
  font-size: 12px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip .tooltiptext.right-arrow {
  left: -160%;
}

.tooltip .tooltiptext.right-arrow::after {
  left: 81%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media screen and (max-width: 1024px) {
  .tooltiptext {
    display: none;
  }
}

@media screen and (max-width: 768px),
  screen /* Changes for participant - Added height on media query as well */and (orientation: landscape) and (max-height: 425px) {
  .full-panel {
    height: calc(100vh - 68px);
  }
  #participants-container.participant-view {
    background: #fff;
  }
  /* For mobile view iphone issue modification - start*/
  #participants-container.participant-view.full-panel .side-topbar-content {
    padding: 5px 0;
    height: calc(100% - 120px);
  }
  .full-panel .custom-scroll-v2.participant-scroll {
    max-height: 100%;
    height: 100%;
  }
  /* For mobile view iphone issue modification - end*/
}
</style>
