export const sanitizeAndFormat = {
  data() {
    return {};
  },
  methods: {
    /**
     * Function for xss escaping
     *
     * @param target
     * @returns {*}
     */
    xssEscape(string) {
      let match = {
        "&#x2F;": `/`,
        "&quot;": `"`,
        "&#x27;": `'`,
        "&amp;": `&`,
        "&gt;": `>`,
        "&lt;": `<`
      };

      string = string.replace(
        /&#x2F;|&quot;|&#x27;|&amp;|&gt;|&lt;/gi,
        matched => match[matched]
      );

      return string;
    },

    /**
     * Function to convert link messages to text message
     *
     * @param msg
     * @returns {string}
     */
    convertLinkMessage(inputText, msgType = "sent") {
      // Xss Escape the message
      inputText = this.xssEscape(inputText);

      // Initialize variables
      var replacedText, replacePattern1, replacePattern2, replacePattern3;

      // URLs starting with http://, https://, or ftp://
      replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&#\/%?=~_|!:,.;]*[-A-Z0-9+&#\/%=~_|])/gim;
      if (replacePattern1.test(inputText))
        inputText = inputText.replace(
          replacePattern1,
          `<a href="$1" target="_blank" style="top: 0px; color: ${
            "sent" === msgType ? "#ffffff" : "#333"
          }; text-decoration: underline !important;">$1</a>`
        );

      // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
      replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
      if (replacePattern2.test(inputText))
        inputText = inputText.replace(
          replacePattern2,
          `$1<a href="http://$2" style="top: 0px; color: ${
            "sent" === msgType ? "#ffffff" : "#333"
          }; text-decoration: underline !important;"  target="_blank">$2</a>`
        );

      // Change email addresses to mailto:: links.
      replacePattern3 = /(([a-zA-Z0-9\-\_\.])+[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
      if (replacePattern3.test(inputText))
        replacedText = inputText.replace(
          replacePattern3,
          `<a style="top: 0px; color: ${
            "sent" === msgType ? "#ffffff" : "#333"
          }; text-decoration: underline !important; " href="mailto:$1">$1</a>`
        );

      // Return the modified text message
      return inputText;
    },

    /**
     * Function ot get a cookie
     *
     * @param {String} cname
     */
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
  }
};
