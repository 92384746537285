export default {
  inviteParticipantTextAreaLimit: 500,
  error: {
    inviteContactNotSelected:
      "You have not selected any contact to send meeting invitation.",
    emptyInvitationMessage:
      "You have not entered any message for sending invitation.",
    hostDisabledMultipleScreenShare:
      "Sorry! Only one person can share screen at a time.",
    hostDisabledUnmute: "Sorry! The host has disabled un-mute.",
    getUserMediaNotSupported:
      "Sorry! Your browser does not support getUserMedia API. Try with a different browser",
    sessionExpired: "Your Session has been expired",
    screenShareInfinityMirror:
      "To avoid infinity mirror, don't share your screen on full screen mode. You can switch to other participant and do full screen mode.",
    hostDeniedFromJoining: "Sorry! Host has denied you from joining.",
    participantAccepted:
      "Host has allowed you to join the meeting. You can refresh the page and join the meeting.",
    webAudioApiNotSupported:
      "Sorry! Your browser does not support the web audio API. Please try with different browser.",
    inviteTextMaxAllowedCharsReached:
      "Sorry! Max 500 characters allowed. Please delete some words/character.",
    tinymceEditorNotLoadedYet:
      "Please wait some time till editor loads completely.",
    previousInvitationRequestNotFinished:
      "Please wait for some time, the previous invitation request not finished yet.",
    invalidFirstName: "Please provide a valid first name",
    invalidMiddleName: "Please provide a valid middle name",
    invalidLastName: "Please provide a valid last name",
    invalidEMail: "Please provide a valid email",
    invalidPhone: "Please provide a valid phone number",
    leaveMeeting: "{userName} has left the meeting.",
    internetDisconnect: "Sorry! Your internet is disconnected.",
    invalidFileName: "Sorry! Please provide a valid file name.",
    noDestinationPath: "Please provide a destination for uploading the file.",
    recordFileUploadInProgress:
      "Please wait for some times, we are uploaidng the record file",
    errInAwsFileUpload:
      "Sorry! Some error prevented us from uploading this recording.",
    invalidURL: "Sorry! You have entered an invalid URL.",
    inactiveMeeting: "Sorry! The video session is not active.",
    socketDisconnect: "Due to some reason, you are got disconnected.",
    socketReconnect: "Please wait we are reconnecting you."
  },
  success: {
    invitationSent: "Invitation sent Successfully.",
    callEndedByUser: "Thank you for using PSYBooks Connect!",
    callEndedByHost:
      "Host has ended the call. Thanks! for using psybooks connect.",
    participantRemovedByHost:
      "Host has removed you. Thanks! for using psybooks connect.",
    internetBack:
      "Your internet connection is restored. You can refresh the page and join the meeting",
    rsvpSaved: "Thanks! Your RSVP response is saved successfully.",
    fileUploadSuccess: "File uploaded successfully.",
    fileDownloadInProgress: "The record file will get downloaded shortly."
  }
};
