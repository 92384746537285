var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"screenContainer",class:{
    'participant-screen-container': true,
    'room-pinned': 'pinned' === _vm.displayView
  }},[_c('ul',{class:{
      'gallery-view': 'gallery' === _vm.displayView,
      'speaker-view': 'speaker' === _vm.displayView,
      'hide-self-view': _vm.meetDetails.hideSelfView
    },attrs:{"id":"participant-list"}},[_c('li',{directives:[{name:"show",rawName:"v-show",value:(
        ('gallery' === _vm.displayView || 'pinned' === _vm.displayView) &&
          !_vm.meetDetails.hideSelfView
      ),expression:"\n        ('gallery' === displayView || 'pinned' === displayView) &&\n          !meetDetails.hideSelfView\n      "}]},[_c('div',{staticClass:"participant-quickview"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCameraStreamReady),expression:"!isCameraStreamReady"}],attrs:{"src":require("../../assets/img/loader.gif")}}),_c('video',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.isCameraStreamReady &&
              ('screen' === _vm.meetDetails.videoTrackType ||
                ('camera' === _vm.meetDetails.videoTrackType &&
                  !_vm.meetDetails.isUserVideoMuted))
          ),expression:"\n            isCameraStreamReady &&\n              ('screen' === meetDetails.videoTrackType ||\n                ('camera' === meetDetails.videoTrackType &&\n                  !meetDetails.isUserVideoMuted))\n          "}],ref:"localVideo",class:{
            flipEffect:
              'camera' === _vm.meetDetails.videoTrackType &&
              _vm.meetDetails.enableMirrorEffect
          },attrs:{"autoplay":"","muted":"","playsinline":"","id":"localVideo"},domProps:{"muted":true}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.isCameraStreamReady &&
              'camera' === _vm.meetDetails.videoTrackType &&
              _vm.meetDetails.isUserVideoMuted
          ),expression:"\n            isCameraStreamReady &&\n              'camera' === meetDetails.videoTrackType &&\n              meetDetails.isUserVideoMuted\n          "}],staticClass:"name-thumb"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(("" + (_vm.userName.firstName ? _vm.userName.firstName[0] : "") + (_vm.userName.lastName ? _vm.userName.lastName[0] : "")).toUpperCase())+" ")])])]),_c('div',{staticClass:"view-options"},[_c('span',{staticClass:"gallery-view-options"},[_c('a',{staticClass:"cursorPointer",on:{"click":function($event){$event.stopPropagation();_vm.openUserOptions = true}}},[_c('i',{staticClass:"bi-three-dots"})])]),(_vm.openUserOptions)?_c('user-options-vue',{attrs:{"open-rename-popup":_vm.openRenamePopup,"remove-self-view":_vm.removeSelfView}}):_vm._e()],1),_c('div',{staticClass:"mobile-view win-participant"},[_c('span',{staticClass:"participant-name"},[_vm._v(" "+_vm._s(("" + (_vm.userName.firstName) + (_vm.userName.middleName ? " " + _vm.userName.middleName : "") + (_vm.userName.lastName ? " " + _vm.userName.lastName : "")))+" ")])]),_c('div',{class:{
          'web-view': true,
          'win-participant': true,
          'blue-background': _vm.pinnedPeerId === 'self'
        },on:{"click":function($event){return _vm.pinParticipant('self')}}},[_c('span',{staticClass:"participant-name"},[_vm._v(" "+_vm._s(("" + (_vm.userName.firstName) + (_vm.userName.middleName ? " " + _vm.userName.middleName : "") + (_vm.userName.lastName ? " " + _vm.userName.lastName : "")))+" ")]),(_vm.pinnedPeerId !== 'self')?_c('span',{staticClass:"pin-btn"},[_c('i',{staticClass:"bi bi-pin-fill"})]):_vm._e()])]),_vm._l((_vm.peerConnections),function(connection,key){return _c('li',{key:key,attrs:{"id":key}},[_c('div',{staticClass:"participant-quickview"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(
            'new' === connection.iceState ||
              'checking' === connection.iceState ||
              'permanentlyDisconnected' === connection.iceState
          ),expression:"\n            'new' === connection.iceState ||\n              'checking' === connection.iceState ||\n              'permanentlyDisconnected' === connection.iceState\n          "}],staticClass:"connecting-img",attrs:{"src":require("../../assets/img/connecting-blank.png")}}),_c('video',{directives:[{name:"show",rawName:"v-show",value:(
            ('connected' === connection.iceState ||
              'completed' === connection.iceState) &&
              ('screen' === connection.videoTrackType ||
                ('camera' === connection.videoTrackType &&
                  !connection.isVideoMuted))
          ),expression:"\n            ('connected' === connection.iceState ||\n              'completed' === connection.iceState) &&\n              ('screen' === connection.videoTrackType ||\n                ('camera' === connection.videoTrackType &&\n                  !connection.isVideoMuted))\n          "}],ref:("remoteVideo_" + key),refInFor:true,attrs:{"autoplay":"","playsinline":"","id":("remoteVideo_" + key)}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            (('connected' === connection.iceState ||
              'completed' === connection.iceState) &&
              'camera' === connection.videoTrackType &&
              connection.isVideoMuted) ||
              'failed' === connection.iceState ||
              'disconnected' === connection.iceState
          ),expression:"\n            (('connected' === connection.iceState ||\n              'completed' === connection.iceState) &&\n              'camera' === connection.videoTrackType &&\n              connection.isVideoMuted) ||\n              'failed' === connection.iceState ||\n              'disconnected' === connection.iceState\n          "}],staticClass:"name-thumb"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(("" + (connection.userName.firstName ? connection.userName.firstName[0] : "") + (connection.userName.lastName ? connection.userName.lastName[0] : "")).toUpperCase())+" ")])])]),_c('div',{staticClass:"mobile-view win-participant"},[_c('span',{staticClass:"participant-name",domProps:{"innerHTML":_vm._s(
            ("" + (connection.userName.firstName) + (connection.userName.middleName
                ? ' ' + connection.userName.middleName
                : '') + (connection.userName.lastName
                ? ' ' + connection.userName.lastName
                : ''))
          )}})]),_c('div',{class:{
          'win-participant': true,
          'web-view': true,
          'blue-background': _vm.pinnedPeerId === key
        },on:{"click":function($event){return _vm.pinParticipant(key)}}},[_c('span',{staticClass:"participant-name",domProps:{"innerHTML":_vm._s(
            ("" + (connection.userName.firstName) + (connection.userName.middleName
                ? ' ' + connection.userName.middleName
                : '') + (connection.userName.lastName
                ? ' ' + connection.userName.lastName
                : ''))
          )}}),(_vm.pinnedPeerId !== key)?_c('span',{staticClass:"pin-btn"},[_c('i',{staticClass:"bi bi-pin-fill"})]):_vm._e()])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }