import socket from "../socket/socket";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { participant } from "./Participant";
import GeneralConstant from "../constant/GeneralConstant";

export const endMeeting = {
  data() {
    return {};
  },
  mixins: [participant],
  computed: {
    ...mapGetters("meet", {
      meetDetails: "getMeetDetails"
    })
  },
  methods: {
    ...mapMutations("meet", {
      setMeetDetails: "setMeetDetails"
    }),

    ...mapActions("meet", {
      clearUpdateSocketLeaveTimeInterval: "clearUpdateSocketLeaveTimeInterval"
    }),

    /**
     * Leave the meeting room
     */
    leaveMeeting() {
      // Remove all socket connection
      this.removeSocketConnection("all");

      // Change the room
      this.setMeetDetails({
        ...this.meetDetails,
        room: null
      });

      // Display message
      this.$store.dispatch("displayGlobalAlert", {
        type: "success",
        msg: GeneralConstant.success.callEndedByUser
      });

      // Trigger remove participant from room
      socket.io.emit("removeParticipant", null);

      // Clearing interval for updating socket levaving time
      this.clearUpdateSocketLeaveTimeInterval();

      // Disconnect the socket connection
      if (socket.io.connected) {
        socket.io.disconnect();
      }
    },

    /**
     * Ending meeting for all members. (Only host can use this option.)
     */
    endMeetingForAll() {
      // Remove all socket connection
      this.removeSocketConnection("all");

      // Emit the event to server to remove all socket connection
      socket.io.emit("endMeetingForAll");

      // Change the meeting room for user
      this.setMeetDetails({
        ...this.meetDetails,
        room: null
      });

      // Display message
      this.$store.dispatch("displayGlobalAlert", {
        type: "success",
        msg: GeneralConstant.success.callEndedByUser
      });

      // Clearing interval for updating socket levaving time
      this.clearUpdateSocketLeaveTimeInterval();

      // Disconnect the socket connection
      if (socket.io.connected) {
        socket.io.disconnect();
      }
    }
  }
};
