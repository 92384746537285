import socket from "../../socket/socket";
import { mapGetters, mapMutations, mapActions } from "vuex";
import GeneralConstant from "../../constant/GeneralConstant";
import { participant } from "../Participant";

export const participantEventListener = {
  data() {
    return {};
  },
  mixins: [participant],
  computed: {
    ...mapGetters("meet", {
      meetDetails: "getMeetDetails",
      hostInfo: "getHostInfo",
      guestUserList: "getGuestUserList",
      participantList: "getParticipantList"
    }),

    ...mapGetters({
      userId: "getUserId"
    })
  },
  methods: {
    ...mapMutations({
      setUserName: "setUserName"
    }),

    ...mapMutations("meet", {
      setMeetDetails: "setMeetDetails",
      pushParticipantToWaitingRoom: "pushParticipantToWaitingRoom",
      markParticipantDeniedOnParticipantList:
        "markParticipantDeniedOnParticipantList",
      setHostInfo: "setHostInfo",
      pushParticipantToMeetingRoom: "pushParticipantToMeetingRoom",
      setGuestUserList: "setGuestUserList",
      renameParticipant: "renameParticipant",
      renamePeerConnection: "renamePeerConnection",
      removeParticipantFromWaitingRoom: "removeParticipantFromWaitingRoom",
      setParticipantList: "setParticipantList"
    }),

    ...mapMutations("chat", {
      renameMessages: "renameMessages"
    }),

    ...mapActions("meet", {
      clearUpdateSocketLeaveTimeInterval: "clearUpdateSocketLeaveTimeInterval"
    }),

    /**
     * Listening to socket events for section part
     */
    listenToParticipantEvent() {
      // Listener, When new user(Scheduled participant + Guest user) want to join
      socket.io.on("newUser", data => {
        if (data.guestId) {
          this.setGuestUserList([
            ...this.guestUserList,
            {
              ...data
            }
          ]);
        } else {
          // Push user socket connection to waiting list
          this.pushParticipantToWaitingRoom(data);
        }
      });

      socket.io.on("userJoinedToMeetRoom", data => {
        // Removing user socket connection from waiting room list
        this.removeParticipantFromWaitingRoom(data.socketId);

        // Push user socket connection to meeting room list
        this.pushParticipantToMeetingRoom(data);
      });

      // Listener to be called when host has restored the deney participant request
      socket.io.on("participantAccepted", data => {
        if (this.userId === data.userId) {
          this.$store.dispatch("displayGlobalAlert", {
            type: "success",
            msg: GeneralConstant.error.participantAccepted
          });
        } else {
          this.markParticipantDeniedOnParticipantList({
            userId: data.userId,
            isDenied: false
          });
        }
      });

      // Listener to be called when host deny participant from joining
      socket.io.on("participantDenied", data => {
        if (this.userId === data.userId) {
          // Change the meeting room for user
          this.setMeetDetails({
            ...this.meetDetails,
            room: null
          });

          // Display message
          this.$store.dispatch("displayGlobalAlert", {
            type: "alert",
            msg: GeneralConstant.error.hostDeniedFromJoining
          });
        } else {
          // Marking participant as denied on participant list
          this.markParticipantDeniedOnParticipantList({
            userId: data.userId,
            isDenied: true
          });
        }
      });

      socket.io.on("participantNameChanged", data => {
        // Rename participant on participant list
        this.renameParticipant(data);

        // Renaming participant on peer connection list
        this.renamePeerConnection(data);

        // Renaming partcipant on chat message list
        this.renameMessages(data);

        // If the user is host, change host names
        if (this.hostInfo.id == data.userId) {
          this.setHostInfo({
            ...this.hostInfo,
            name: {
              ...this.hostInfo.name,
              firstName: data.firstName,
              middleName: data.middleName,
              lastName: data.lastName
            }
          });
        }

        // If same user connected from different tab/device, then change name
        if (this.userId === data.userId) {
          this.setUserName({
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName
          });
        }
      });

      // Listener on removing a peer connection
      socket.io.on("removeConnection", data => {
        if ("all" in data && true === data.all) {
          console.log(
            "From removeConnection listener - remove all socket sonnection"
          );
          // Remove all socket connection
          this.removeSocketConnection("all");

          // Change the meeting room for user
          this.setMeetDetails({
            ...this.meetDetails,
            room: null
          });

          // Display the message
          this.$store.dispatch("displayGlobalAlert", {
            type: data.isParticipantRemovedByHost ? "alert" : "success",
            msg:
              GeneralConstant.success[
                data.isCallEndedByHost
                  ? this.hostInfo.id === this.userId
                    ? "callEndedByUser"
                    : "callEndedByHost"
                  : data.isParticipantRemovedByHost
                  ? "participantRemovedByHost"
                  : "callEndedByUser"
              ]
          });

          // Clearing interval for updating socket levaving time
          this.clearUpdateSocketLeaveTimeInterval();

          // Disconnect the socket connection
          if (socket.io.connected) {
            socket.io.disconnect();
          }
        } else {
          console.log(
            `From removeConnection listener - remove socket sonnection - ${data.socketId}`
          );
          this.removeSocketConnection(data.socketId, data.reason);
        }

        let hostSocketConn = this.participantList
          .filter(participant => participant.userId === this.hostInfo.id)
          .map(participant => participant.meetRoomSocketConn);
        hostSocketConn = [].concat.apply([], hostSocketConn);

        if (0 === hostSocketConn.length) {
          this.setMeetDetails({
            ...this.meetDetails,
            enableUnmute: true,
            screenShare: "multi-share",
            enableChat: true
          });
        }
      });

      // Listener when a participant is invited
      socket.io.on("participantInvited", data => {
        // Get the participant list from object
        const newParticipantList = data.participantList;

        const addParticipantList = [];
        newParticipantList.forEach(newParticipant => {
          // Check if new participant is on the existing participant list
          const addParticipant = this.participantList.find(participant => {
            return newParticipant.MeetUserId === participant.userId;
          });

          // If new participant is not on the list, add the participant on to an array
          if (!addParticipant && newParticipant.MeetUserId !== this.userId) {
            addParticipantList.push({
              userName: {
                firstName: newParticipant.ParticipantFirstName,
                lastName: newParticipant.ParticipantLastName
              },
              userId: newParticipant.MeetUserId,
              isUserDeniedToMeetRoom: false,
              waitRoomSocketConn: [],
              meetRoomSocketConn: [],
              userPhone: participant.ParticipantSmsNumber,
              externalId: participant.ParticipantExternalId,
              isGuest: false
            });
          }
        });

        // Concat both the new and old participant array and set the participant list
        this.setParticipantList([
          ...this.participantList,
          ...addParticipantList
        ]);
      });
    },

    /**
     * Handling errors
     */
    showErrOnConsole(error) {
      console.log(error);
    }
  }
};
