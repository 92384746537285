/**
 * Mutations for record
 */
export const mutations = {
  /**
   * Setting audio context
   *
   * @param {Object} state
   * @param {Object} audioContext
   */
  setAudioContext(state, audioContext) {
    state.audioContext = audioContext;
  },

  /**
   * Setting composed stream
   *
   * @param {Object} state
   * @package {Object} composedStream
   */
  setComposedStream(state, composedStream) {
    state.composedStream = composedStream;
  },

  /**
   * Setting Recording State
   *
   * @param {Object} state
   * @param {String} recordingState
   */
  setRecordingState(state, recordingState) {
    state.recordingState = recordingState;
  },

  /**
   * Setting audioDestinationNode
   *
   * @param {Object} state
   * @param {Object} audioDestinationNode
   */
  setAudioDestinationNode(state, audioDestinationNode) {
    state.audioDestinationNode = audioDestinationNode;
  },

  /**
   * Setting mediaRecorder
   *
   * @param {Object} state
   * @param {Object} mediaRecorder
   */
  setMediaRecorder(state, mediaRecorder) {
    state.mediaRecorder = mediaRecorder;
  },

  /**
   * Sets record stream
   *
   * @param {Object} state
   * @param {Object} stream
   */
  setRecordedScreenStream(state, stream) {
    state.recordedScreenStream = stream;
  },

  /**
   * Sets recorded file name
   *
   * @param {Object} state
   */
  setRecordedFileName(state, fileName) {
    state.recordedFileName = fileName;
  },

  /**
   * Sets record file upload status
   *
   * @param {Object} state
   * @param {Boolean} sttaus
   */
  setRecordFileUploadStatus(state, status) {
    state.recordFileUploadStatus = status;
  },

  /**
   * Sets flag to check whether record file is getting downloaded
   *
   * @param {Object} state
   * @param {Boolean} isDownloading
   */
  setIsRecordFileDownloading(state, isDownloading) {
    state.isRecordFileDownloading = isDownloading;
  },

  /**
   * Sets upload percentage
   *
   * @param {Object} state
   * @package {Number} uploadPercentage
   */
  setUploadPercentage(state, uploadPercentage) {
    state.uploadPercentage = uploadPercentage;
  },

  /**
   * Sets the file id
   *
   * @param {Object} state
   * @param {String} fileId
   */
  setFileId(state, fileId) {
    state.fileId = fileId;
  },

  /**
   * Sets recorded video chunks
   *
   * @param {Object} state
   * @param {Object} chunks
   */
  setRecordedChunks(state, chunks) {
    state.recordedChunks = chunks;
  },

  /**
   * Sets first time recording flag
   *
   * @param {Object} state
   * @param {Boolean} value
   */
  setFirstTimeRecord(state, value) {
    state.firstTimeRecord = value;
  }
};
