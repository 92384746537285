<template>
  <div class="dropdown" @click="preventClickAction">
    <ul>
      <li>
        <a class="cursorPointer" @click="openRenamePopup">
          <span class="list-option"> Rename </span>
        </a>
      </li>
      <li v-if="Object.keys(peerConnections).length > 0">
        <a class="cursorPointer" @click="removeSelfView">
          <span class="list-option"> Hide Self View </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "UserOptions",
  data() {
    return {};
  },
  mixins: [],
  props: {
    openRenamePopup: Function,
    removeSelfView: Function
  },
  computed: {
    ...mapGetters("meet", {
      meetDetails: "getMeetDetails",
      peerConnections: "getPeerConnections"
    })
  },
  methods: {
    ...mapMutations("meet", {
      setMeetDetails: "setMeetDetails"
    }),

    /**
     * Prevents click action
     */
    preventClickAction(event) {
      event.stopPropagation();
    }
  }
};
</script>

<style scoped>
div.dropdown {
  z-index: 99990;
  background-color: #282828;
  position: absolute;
  border: 1px solid #0d0d0d;
  border-radius: 5px;
  top: 20px;
  right: 5px;
  width: 120px;
}

div.dropdown ul {
  margin: 0px;
  padding: 0px;
}

div.dropdown li {
  position: relative;
}

span.icon-tick {
  position: absolute;
  left: 0px;
  top: 0px;
}

a.close {
  position: absolute;
  right: 7px;
  color: #fff;
  top: 0px;
}

div.dropdown ul li {
  list-style: none;
  margin: 10px;
  padding-bottom: 5px;
  display: flex;
}

div.dropdown ul li img {
  height: 20px;
  margin-right: 10px;
}

div.dropdown ul li a {
  font-size: 13px;
  color: #e6e6e6;
  font-weight: normal;
}

div.dropdown ul li a span.list-option {
  margin-left: 0px !important;
}

div.dropdown ul li:not(:last-of-type) {
  border-bottom: 1px solid #333;
  padding-bottom: 13px;
}

div.dropdown ul li:last-of-type {
  /* margin-bottom: 4px; */
}

div.dropdown ul li a i {
  position: absolute;
  right: 20px;
  top: 0;
}
div.dropdown ul li a span.icon-tick {
  left: 35px;
}
</style>
