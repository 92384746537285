<template>
  <div class="msg-block" @click="openChatPanel">
    <div
      :class="{
        'msg-thumb': true,
        yellow:
          hostInfo.id === userId ? true : notification.senderId !== hostInfo.id,
        orange:
          hostInfo.id === userId ? false : notification.senderId === hostInfo.id
      }"
    >
      {{ `${notification.userName.firstName}`[0].toUpperCase() }}
    </div>
    <div class="msg-right">
      <p class="name-c">
        {{
          `${notification.userName.firstName} ${
            notification.userName.lastName ? notification.userName.lastName : ""
          }`
        }}
      </p>
      <p class="msg-c" v-html="notification.message"></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ChatNotification",
  props: {
    notification: Object,
    toggleSidebar: Function,
    isChatPannelOpened: Boolean
  },
  computed: {
    ...mapGetters("meet", {
      hostInfo: "getHostInfo"
    }),

    ...mapGetters({
      userId: "getUserId"
    })
  },
  methods: {
    /**
     * Function to open chat panel
     */
    openChatPanel() {
      if (!this.isChatPannelOpened) {
        this.toggleSidebar({ clickEvent: "chat" });
      }
    }
  }
};
</script>

<style scoped>
.msg-block {
  width: 200px;
  background: #fff;
  border-radius: 25px;
  padding: 5px;
  margin-bottom: 10px;
  display: flex;
  cursor: pointer;
}
.msg-block .msg-thumb {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  margin-right: 10px;
  margin: auto 10px auto 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.yellow {
  background: #eeb747;
}
.orange {
  background: #d26d13;
}
.msg-block .msg-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 62px);
}
.msg-block .msg-right p.name-c {
  font-size: 14px;
  color: #000;
  margin: 0 0 5px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.msg-block .msg-right p.msg-c {
  font-size: 12px;
  color: #000;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .msg-block {
    width: 160px;
  }
  .msg-block .msg-thumb {
    width: 25px;
    height: 25px;
  }

  .msg-block .msg-right p.name-c {
    font-size: 12px;
  }

  .msg-block .msg-right p.msg-c {
    font-size: 10px;
  }
}
</style>
