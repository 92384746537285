/**
 * Mutations for participant
 */
export const mutations = {
  /**
   * Sets the flag which determines if invitation request is pending
   *
   * @param {Object} state
   * @param {Boolean} value
   */
  setIsSendInvitationRequestPending(state, value) {
    state.isSendInvitationRequestPending = value;
  },

  /**
   * Setting the client list
   *
   * @param {Object} state
   * @param {Object} clients
   */
  setClients(state, clients) {
    state.clients = clients;
  },

  /**
   * Setting the person list
   *
   * @param {Object} state
   * @param {Object} persons
   */
  setPersons(state, persons) {
    state.persons = persons;
  },

  /**
   * Setting the subscriber list
   *
   * @param {Object} state
   * @param {Object} subscribers
   */
  setSubscribers(state, subscribers) {
    state.subscribers = subscribers;
  },

  /**
   * Setting the app user list
   *
   * @param {Object} state
   * @param {Object} appUsers
   */
  setAppUsers(state, appUsers) {
    state.appUsers = appUsers;
  },

  /**
   * Sets the status  user list fetched status flag
   *
   * @param {Object} state
   * @param {Boolean} value
   */
  setUserListFetchStatus(state, value) {
    state.userListFetchStatus = value;
  }
};
