import socket from "../../socket/socket";

export const errorListener = {
  methods: {
    /**
     * Event listener for listening to error events
     */
    listenToErrorEvent() {
      // Listening to error event
      socket.io.on("error", this.parseError);
    },

    /**
     * Function to parse any object
     *
     * @param {Object} errorObj
     */
    parseError(errorObj) {
      let errorMsg = "";
      if ("string" === typeof errorObj) {
        try {
          const errorObj = JSON.parse(errorObj);
          errorMsg = errorObj.message;
        } catch (e) {
          return false;
        }
      } else if ("object" === typeof errorObj) {
        errorMsg = errorObj.message;
      } else {
        return false;
      }

      this.$store.dispatch("displayGlobalAlert", {
        type: "alert",
        msg: errorMsg
      });
    }
  }
};
