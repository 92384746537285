<template>
  <div id="wrapper-guest">
    <div id="guest-user">
      <div id="guest-notice-con">
        <template v-if="showLoader">
          <div class="loader"></div>
        </template>
        <template v-else-if="isMeetCodeValidated">
          <template v-if="hostPermissionStatus">
            <div class="host-permission">
              <template v-if="'pending' === hostPermissionStatus">
                <h3 class="txt-highlight-pri">Asking to be let in...</h3>
                <p>You will join the call when host lets you in.</p>
                <div class="loader-2">
                  <img
                    src="../../../src/assets/img/loader-2.gif"
                    alt="Loader"
                  />
                </div>
              </template>
              <template v-else>
                <h3 class="txt-highlight-pri">
                  {{ hostPermissionStatus }}
                </h3>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="form-header-block">
              <!-- Psybooks Logo -->
              <a :href="psybooksLoginUrl" class="app-logo">
                <img
                  src="../../assets/img/guest-user-logo-large.png"
                  alt="Psybooks Connect"
                  class="lg-logo"
                />
                <img
                  src="../../assets/img/guest-user-logo-small.png"
                  alt="Psybooks Connect"
                  class="sm-logo"
                />
              </a>
              <p>
                To join this meeting, you need to login to PSYBooks or join as a
                guest user. Please fill out the form below to join as a guest
                user.
              </p>
              <p>
                <a :href="psybooksLoginUrl">Click here</a> to login to PSYBooks.
              </p>
            </div>

            <form action="#" method="POST">
              <!-- First name input -->
              <label for="fname">
                First Name <span class="mandatory">*</span>
              </label>
              <br />
              <input
                type="text"
                @input="setFirstName($event.target.value)"
                v-model="participant.firstName.val"
                id="fname"
                name="fname"
              />
              <span v-if="participant.firstName.error">
                {{ participant.firstName.error }}
              </span>

              <!-- Middle name input -->
              <label for="lname">Middle Name</label><br />
              <input
                type="text"
                @input="setMiddleName($event.target.value)"
                v-model="participant.middleName.val"
                id="lname"
                name="lname"
              />
              <span v-if="participant.middleName.error">
                {{ participant.middleName.error }}
              </span>

              <!-- Last name input -->
              <label for="lname">Last Name</label><br />
              <input
                type="text"
                @input="setLastName($event.target.value)"
                v-model="participant.lastName.val"
                id="lname"
                name="lname"
              />
              <span v-if="participant.lastName.error">
                {{ participant.lastName.error }}
              </span>

              <!-- Phone number input -->
              <label for="lname">
                Phone Number <span class="mandatory">*</span>
              </label>
              <br />
              <input
                type="text"
                id="phn"
                name="phn"
                @input="setPhone"
                v-model="participant.phone.val"
              />
              <span v-if="participant.phone.error">
                {{ participant.phone.error }}
              </span>

              <!-- Email input -->
              <label for="lname"> Email </label>
              <br />
              <input
                type="text"
                id="email"
                name="email"
                @input="setEmail($event.target.value)"
                v-model="participant.email.val"
              />
              <span v-if="participant.email.error">
                {{ participant.email.error }}
              </span>

              <!-- Displaying server error -->
              <span class="global-error" v-if="guestUserSubmitErr.length > 0">
                {{ guestUserSubmitErr }}
              </span>

              <!-- Submit Button -->
              <button
                @click.prevent="submitGuestParticipant"
                class="btn btn-primary"
              >
                Submit
              </button>
            </form>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { guestUserEventListener } from "../../mixins/listener/GuestUser";
import { validateAndFormat } from "../../mixins/ValidateAndFormat";
import GeneralConstant from "../../constant/GeneralConstant";
import socket from "../../socket/socket";
import { errorListener } from "../../mixins/listener/Error";
import { mapMutations } from "vuex";
import { chatEventListener } from "../../mixins/listener/Chat";
import { hostControlEventListener } from "../../mixins/listener/HostControl";
import { mediaEventListener } from "../../mixins/listener/Media";
import { participantEventListener } from "../../mixins/listener/Participant";

export default {
  name: "GuestUserHome",
  mixins: [
    validateAndFormat,
    chatEventListener,
    guestUserEventListener,
    hostControlEventListener,
    mediaEventListener,
    participantEventListener,
    errorListener
  ],
  data() {
    return {
      participant: {
        firstName: {
          val: "",
          error: ""
        },
        lastName: {
          val: "",
          error: ""
        },
        middleName: {
          val: "",
          error: ""
        },
        phone: {
          val: "",
          error: ""
        },
        email: {
          val: "",
          error: ""
        }
      },
      showLoader: false,
      guestUserSubmitErr: "",
      hostPermissionStatus: "",
      psybooksLoginUrl:
        process.env.VUE_APP_MAINSITE_URL +
        `/login.php?meetCode=${this.$route.params.meetCode}&passCode=${this.$route.params.passCode}`,
      isMeetCodeValidated: false
    };
  },
  methods: {
    ...mapMutations({
      setIsGuestUser: "setIsGuestUser"
    }),

    /**
     * Function to reset all errors on the guest user add object
     */
    resetAllErrors() {
      for (const property in this.participant) {
        this.participant[property].error = "";
      }
      this.guestUserSubmitErr = "";
    },

    /**
     * Function to submit guest user as a participant to the meeting
     */
    async submitGuestParticipant() {
      // Reset all errors
      this.resetAllErrors();

      if (!this.validateName(this.participant.firstName.val)) {
        // Validating first name
        this.participant.firstName.error =
          GeneralConstant.error.invalidFirstName;
      } else if (
        this.participant.middleName.val &&
        !this.validateName(this.participant.middleName.val)
      ) {
        // Validating last name
        this.participant.middleName.error =
          GeneralConstant.error.invalidMiddleName;
      } else if (
        this.participant.lastName.val &&
        !this.validateName(this.participant.lastName.val)
      ) {
        // Validating last name
        this.participant.lastName.error = GeneralConstant.error.invalidLastName;
      } else if (!this.validatePhone(this.participant.phone.val)) {
        // Validating phone number
        this.participant.phone.error = GeneralConstant.error.invalidPhone;
      } else if (
        this.participant.email.val &&
        !this.validateEmail(this.participant.email.val)
      ) {
        // Validating email
        this.participant.email.error = GeneralConstant.error.invalidEMail;
      } else {
        // Show loader
        this.showLoader = true;

        // Join guest participants
        socket.io.emit(
          "newGuestUser",
          {
            firstName: this.participant.firstName.val,
            middleName: this.participant.middleName.val,
            lastName: this.participant.lastName.val,
            userPhone: this.participant.phone.val,
            userEmail: this.participant.email.val
          },
          response => {
            this.showLoader = false;
            if (response.status) {
              this.hostPermissionStatus = "pending";
            } else {
              this.guestUserSubmitErr = response.message;
            }
          }
        );
      }
    },

    /**
     * Function to format and set the first name
     */
    setFirstName(value) {
      this.participant.firstName.val = value.substring(0, 60);
    },

    /**
     * Function to format and set the middle name
     */
    setMiddleName(value) {
      if (value) {
        this.participant.middleName.val = value.substring(0, 60);
      }
    },

    /**
     * Function to format and set the last name
     */
    setLastName(value) {
      if (value) {
        this.participant.lastName.val = value.substring(0, 60);
      }
    },

    /**
     * Function to format and set the phone number
     */
    setPhone() {
      this.participant.phone.val = this.formatPhone(this.participant.phone.val);
    },

    /**
     * Function to format and set the email
     */
    setEmail(value) {
      if (value) {
        this.participant.email.val = value.substring(0, 60);
      }
    }
  },
  async created() {
    // Show loader
    this.showLoader = true;

    // Initialize socket
    socket.initialize({
      meetCode: this.$route.params.meetCode,
      passCode: this.$route.params.passCode
    });

    // Connect handler
    socket.io.on("connect", () => {
      this.showLoader = false;
      this.isMeetCodeValidated = true;
    });

    this.listenToErrorEvent();
    this.listenToGuestUserEvent();

    // On socket connect error show messages
    socket.io.on("connect_error", err => {
      this.showLoader = false;

      // Show error messages only for known error
      if (err.data && err.data.isKnownError) {
        // Mark isMeetCodeValidated flag to false
        if ("Invalid meeting code provided." === err.message) {
          this.isMeetCodeValidated = false;
        }

        // Show alert message
        this.$store.dispatch("displayGlobalAlert", {
          type: "alert",
          msg:
            "Invalid meeting code provided." === err.message
              ? GeneralConstant.error.invalidURL
              : err.message
        });
      }
    });
  },
  beforeDestroy() {
    socket.io.disconnect();
  }
};
</script>

<style scoped>
.mandatory {
  color: #c13e3e;
}
#wrapper-guest {
  min-height: 100%;
  height: 100%;
}
#guest-user {
  background: #000 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-height: 540px;
}
#guest-user a {
  color: #eeb747;
}
#guest-user a:hover {
  text-decoration: underline;
}
#guest-notice-con {
  max-width: 640px;
  margin: 0 auto;
  color: #fff;
  max-height: 100%;
  /* padding: 20px 0; */
}
#guest-user form {
  max-width: 600px;
  margin: 20px auto;
  color: #fff;
  background: #282828;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
}
#guest-user form input {
  background: #333;
  border: 1px solid #7a7a7a;
  color: #fff;
  padding: 5px 15px;
  display: block;
  width: calc(100% - 30px);
  margin-bottom: 20px;
}
#guest-user form input + span {
  font-size: 13px;
  color: #c13e3e;
  position: relative;
  top: -12px;
  display: block;
}
#guest-user form span.global-error {
  text-align: center;
  top: -10px;
}
.host-permission {
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 25px 35px;
  border: 1px solid #222;
}
.host-permission.error {
  border: none;
}
.host-permission h3 {
  margin-bottom: 10px;
}
.host-permission p {
  margin-bottom: 0;
}
.form-header-block {
  position: relative;
  padding-left: 75px;
}
.form-header-block .app-logo {
  position: absolute;
  left: -100px;
  display: block;
}

@media (min-width: 768px) {
  .sm-logo {
    display: none;
  }
}
@media screen and (max-width: 870px) {
  .form-header-block .app-logo {
    left: -18px;
  }
  .form-header-block {
    padding-left: 160px;
  }
}
@media screen and (max-width: 768px) {
  #guest-notice-con {
    max-width: 500px;
  }
  #guest-user form {
    max-width: 440px;
  }
  .form-header-block .app-logo img {
    max-width: 190px;
  }
  .form-header-block {
    padding-left: 112px;
  }

  .form-header-block .app-logo {
    left: 37px;
    top: 11px;
  }
  .sm-logo {
    display: block;
  }
  .lg-logo {
    display: none;
  }
  .form-header-block .app-logo img.sm-logo {
    width: 65px;
  }
}
@media screen and (max-width: 480px) {
  #guest-notice-con {
    max-width: 360px;
  }
  #guest-user form {
    /* max-width: 320px; */
    max-width: 85%;
  }

  #guest-user {
    height: auto;
  }
  .form-header-block .app-logo {
    top: 0;
    left: 0;
  }

  .form-header-block {
    padding-left: 75px;
  }
}
</style>
