<template>
  <div
    id="wrapper"
    :class="{
      'hide-scroll': isChatPannelOpened || isParticipantPannelOpened,
      'wrapper-waiting-room': 'waitRoom' === meetDetails.room,
      'zoomed-view': fullScreenMode
    }"
    ref="home"
  >
    <!-- The Header section -->
    <header-vue
      v-if="'meetRoom' === meetDetails.room"
      :toggle-sidebar="toggleSidebar"
      :toggle-full-screen="toggleFullScreen"
    ></header-vue>

    <section
      id="main-wrapper"
      :class="{
        'view-collapsed': isChatPannelOpened || isParticipantPannelOpened
      }"
      v-show="
        'meetRoom' === meetDetails.room || 'waitRoom' === meetDetails.room
      "
    >
      <div id="main-content">
        <!-- Component for showing wait-room/meet-room -->
        <section-vue
          :toggle-full-screen="toggleFullScreen"
          :toggle-sidebar="toggleSidebar"
          :is-chat-pannel-opened="isChatPannelOpened"
        ></section-vue>

        <!-- Component for showing footer -->
        <footer-vue
          :toggle-sidebar="toggleSidebar"
          :is-chat-pannel-opened="isChatPannelOpened"
          :is-participant-pannel-opened="isParticipantPannelOpened"
        ></footer-vue>
      </div>
    </section>

    <!-- Sidebar -->
    <aside
      id="sidebar"
      :class="{
        'view-collapsed': isChatPannelOpened || isParticipantPannelOpened
      }"
    >
      <div id="sidebar-content">
        <!-- Participant Pannel -->
        <participant-vue
          v-if="isParticipantPannelOpened"
          :toggle-sidebar="toggleSidebar"
        ></participant-vue>

        <!-- Chat Pannel -->
        <chat-vue
          v-if="isChatPannelOpened"
          :toggle-sidebar="toggleSidebar"
        ></chat-vue>
      </div>
    </aside>

    <!-- Various popups -->
    <!-- Popup for asking permission from host to allow participants -->
    <transition name="zoom">
      <participant-permission-vue
        :participant="waitingRoomUsers[0]"
        :hide-participant-permission="hideParticipantPermission"
        v-if="
          'meetRoom' === meetDetails.room &&
            userId === hostInfo.id &&
            waitingRoomUsers.length > 0 &&
            openParticipantPermissionPopup
        "
      >
      </participant-permission-vue>
    </transition>

    <!-- Popup for opening various modal dialog -->
    <modal-vue v-if="modal.display">
      <template v-slot:body>
        <component :is="modal.bodyComponentName" />
      </template>
    </modal-vue>

    <!-- Audio sound to be played upon entry -->
    <audio
      src="../../assets/audio/doorbell.mp3"
      id="entryAudio"
      class="hide"
    ></audio>
  </div>
</template>

<script>
import SectionVue from "./Section";
import { mapActions, mapMutations, mapGetters } from "vuex";
import socket from "../../socket/socket";
import ChatVue from "./Chat";
import ParticipantVue from "./Participant";
import FooterVue from "./Footer";
import HeaderVue from "./Header";
import GeneralConstant from "../../constant/GeneralConstant";
import { chatEventListener } from "../../mixins/listener/Chat";
import { guestUserEventListener } from "../../mixins/listener/GuestUser";
import { hostControlEventListener } from "../../mixins/listener/HostControl";
import { mediaEventListener } from "../../mixins/listener/Media";
import { participantEventListener } from "../../mixins/listener/Participant";
import { errorListener } from "../../mixins/listener/Error";
import { device } from "../../mixins/Device";
import { sanitizeAndFormat } from "../../mixins/sanitizeAndFormat";
import ParticipantPermissionVue from "./ParticipantPermission";
import { participant } from "../../mixins/Participant";
import NewRecordPopupVue from "./NewRecordPopup";
import NotifyOtherUserEntryVue from "./NotifyOtherUserEntry";
import UploadRecordPopupConfirmVue from "./UploadRecordPopupConfirm";
import RenameVue from "./Rename";
import ModalVue from "../common/Modal";
import DefaultModalBodyVue from "../common/DefaultModalBody";
import InviteParticipantVue from "./InviteParticipant.vue";
import RecordFirstTimeVue from "./RecordFirstTime.vue";
import UploadFileVue from "./UploadFile.vue";

export default {
  name: "MeetHome",
  mixins: [
    chatEventListener,
    guestUserEventListener,
    hostControlEventListener,
    mediaEventListener,
    participantEventListener,
    errorListener,
    device,
    sanitizeAndFormat,
    participant
  ],
  data() {
    return {
      isChatPannelOpened: false,
      isParticipantPannelOpened: false,
      openParticipantPermissionPopup: true
    };
  },
  components: {
    SectionVue,
    ParticipantVue,
    ChatVue,
    HeaderVue,
    FooterVue,
    ParticipantPermissionVue,
    NewRecordPopupVue,
    NotifyOtherUserEntryVue,
    UploadRecordPopupConfirmVue,
    ModalVue,
    RenameVue,
    DefaultModalBodyVue,
    InviteParticipantVue,
    RecordFirstTimeVue,
    UploadFileVue
  },
  computed: {
    ...mapGetters({
      userId: "getUserId",
      globalAlert: "getGlobalAlert",
      modal: "getModal",
      socketId: "getSocketId"
    }),

    ...mapGetters("meet", {
      meetDetails: "getMeetDetails",
      fullScreenMode: "getFullScreenMode",
      hostInfo: "getHostInfo",
      peerConnections: "getPeerConnections",
      participantList: "getParticipantList",
      guestUserList: "getGuestUserList",
      joinId: "getJoinId"
    }),

    ...mapGetters("record", {
      recordingState: "getRecordingState",
      recordFileUploadStatus: "getRecordFileUploadStatus",
      recordedChunks: "getRecordedChunks"
    }),

    /**
     * - Returns all participants present on waiting room
     * - Returns guests who have requested to join.
     */
    waitingRoomUsers() {
      const waitRoomUserList = [];
      this.participantList.forEach(participant => {
        participant.waitRoomSocketConn.forEach(socketId => {
          waitRoomUserList.push({
            userName: participant.userName,
            socketId: socketId,
            userId: participant.userId
          });
        });
      });

      return waitRoomUserList.concat(
        this.guestUserList.map(guestUser => {
          return {
            userName: guestUser.userName,
            socketId: guestUser.socketId,
            guestId: guestUser.guestId
          };
        })
      );
    }
  },
  watch: {
    "meetDetails.room": function(value) {
      if (!value) {
        this.isChatPannelOpened = false;
        this.isParticipantPannelOpened = false;
      }

      if (
        "meetRoom" === value &&
        null === window.sessionStorage.getItem("notifyOnOtherEntry")
      ) {
        this.setModal({
          ...this.modal,
          display: true,
          title: "Notify on others entry",
          bodyComponentName: "notify-other-user-entry-vue",
          styleOverride: {}
        });
        this.setDisplayModalOverlay(true);
      }
    }
  },
  methods: {
    ...mapMutations("meet", {
      setListenToPeerEvent: "setListenToPeerEvent",
      setFullScreenMode: "setFullScreenMode",
      setMeetDetails: "setMeetDetails"
    }),

    ...mapMutations({
      setDisplayModalOverlay: "setDisplayModalOverlay",
      setSocketId: "setSocketId",
      setModal: "setModal"
    }),

    ...mapActions("meet", {
      validateMeetLink: "validateMeetLink"
    }),

    ...mapActions("participant", {
      fetchUserList: "fetchUserList"
    }),

    ...mapActions("chat", {
      fetchChatMessages: "fetchChatMessages"
    }),

    /**
     * Function to hide participant permission pop-up.
     */
    hideParticipantPermission() {
      // Hiding participant permission pop-up.
      this.openParticipantPermissionPopup = false;

      // After 1 sec show another participant, if present
      setTimeout(() => {
        this.openParticipantPermissionPopup = true;
      }, 1000);
    },

    /**
     * Function to open participant panel
     */
    openParticipantPanel() {
      this.isChatPannelOpened = false;
      this.isParticipantPannelOpened = true;
    },

    /**
     * Function to close participant panel
     */
    closeParticipantPanel() {
      this.isParticipantPannelOpened = false;
    },

    /**
     * Function to open chat panel
     */
    openChatPanel() {
      this.isParticipantPannelOpened = false;
      this.isChatPannelOpened = true;
    },

    /**
     * Function to close chat panel
     */
    closeChatPanel() {
      this.isChatPannelOpened = false;
    },

    /**
     * Function to toggle side bar(Participant/Chat panel)
     */
    toggleSidebar({ clickEvent, isClose = null }) {
      // Toggle participant pannel
      if ("participant" === clickEvent) {
        if (isClose || this.isParticipantPannelOpened) {
          this.closeParticipantPanel();
        } else {
          this.openParticipantPanel();
        }
      }

      // Toggle chat pannel
      if ("chat" === clickEvent) {
        if (isClose || this.isChatPannelOpened) {
          this.closeChatPanel();
        } else {
          this.openChatPanel();
        }
      }
    },

    /**
     * Open speaker view on full-screen.
     */
    toggleFullScreen() {
      if ("meetRoom" === this.meetDetails.room) {
        // Upon full screen infinity infinite mirror will appear, If there is no participant and screen share is on. So,to prevent it show an alert
        if (
          0 === Object.keys(this.peerConnections).length &&
          "screen" === this.meetDetails.videoTrackType
        ) {
          this.$store.dispatch("displayGlobalAlert", {
            type: "alert",
            msg: GeneralConstant.error.screenShareInfinityMirror
          });

          return false;
        }

        if (document.fullscreenElement) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        } else {
          if (this.$refs.home.requestFullscreen) {
            this.$refs.home.requestFullscreen(); // W3C spec
          } else if (this.$refs.home.mozRequestFullScreen) {
            this.$refs.home.mozRequestFullScreen(); // Firefox
          } else if (this.$refs.home.webkitRequestFullscreen) {
            this.$refs.home.webkitRequestFullscreen(); // Safari
          } else if (this.$refs.home.msRequestFullscreen) {
            this.$refs.home.msRequestFullscreen(); // IE/Edge
          }
        }
      }
    }
  },
  async created() {
    window.onbeforeunload = event => {
      if (socket.io) {
        socket.io.emit("disconnectSocketConn");
        socket.io.disconnect();
      }
    };

    // Clear session storage
    if (
      window.sessionStorage.getItem("meetCode") &&
      window.sessionStorage.getItem("meetCode").length > 0 &&
      this.$route.params.meetCode !== window.sessionStorage.getItem("meetCode")
    ) {
      sessionStorage.clear();
    }

    // Listening to full screen change event and changing the state
    document.addEventListener("fullscreenchange", event => {
      const fullscreenElement =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement;
      this.setFullScreenMode(fullscreenElement ? true : false);
    });

    // Validate Meetlink
    const response = await this.validateMeetLink({
      meetCode: this.$route.params.meetCode,
      passCode: this.$route.params.passCode
    });

    if ("0" === response.reasonCode) {
      window.sessionStorage.setItem("meetCode", this.$route.params.meetCode);

      // Reload the page if session-hash is present on url.
      if (
        this.$route.query.sessionHash &&
        this.$route.query.sessionHash.length > 0
      ) {
        let query = { ...this.$route.query },
          queryStr = "";

        // Delete session hash query string from URL
        delete query["sessionHash"];

        // Get new query string url
        Object.keys(query).forEach((key, index, arr) => {
          if ("sessionHash" !== key) {
            queryStr += `${key}=${query[key]}`;

            if (index < arr.length - 1) {
              queryStr += `&`;
            }
          }
        });

        // Construct new URL and reload
        const url = new URL(window.location.href);
        window.location.href = `${url.origin}${url.pathname}${
          queryStr ? "?" + queryStr : ""
        }`;
      }

      // Initialize socket
      socket.initialize({
        token: response.userResponse.token,
        meetCode: this.$route.params.meetCode,
        passCode: this.$route.params.passCode
      });

      // Upon socket connection success, listen to socket events
      socket.io.on("connect", () => {
        // List devices and switch on camera and microphone
        if (null === this.socketId) {
          this.fetchCameraStream();
        } else {
          // Join the user to meet/wait room, if in case socket reconnects
          socket.io.emit("joinToVerifiedRoom", {
            room: this.meetDetails.room,
            meetJoinInfoId: this.joinId
          });
        }

        // Set socket id on vuex
        this.setSocketId(socket.io.id);
      });

      // Event handlers
      this.listenToErrorEvent();
      this.listenToHostEvent();
      this.listenToMediaEvent();
      this.listenToParticipantEvent();
      this.listenToChatEvent();
      this.listenToGuestUserEvent();
      this.setListenToPeerEvent(true);

      // Fetching all users
      if (response.userResponse.userId === response.userResponse.hostId) {
        this.fetchUserList();
      }
      this.fetchChatMessages();

      // On socket connect error show messages
      socket.io.on("connect_error", err => {
        console.log("socket error");
        console.log(err);
        if (err.data && err.data.isKnownError) {
          this.$store.dispatch("displayGlobalAlert", {
            type: "alert",
            msg: err.message
          });
        }
      });

      socket.io.on("disconnect", reason => {
        console.log("socket disconnected");
        console.log(reason);
        if (
          "waitRoom" === this.meetDetails.room &&
          reason !== "io client disconnect"
        ) {
          this.$store.dispatch("displayGlobalAlert", {
            type: "alert",
            msg: `${GeneralConstant.error.socketDisconnect}${
              "io server disconnect" === reason
                ? ""
                : ` ${GeneralConstant.error.socketReconnect}`
            }`
          });
        }
      });
    } else {
      if (1002 === response.error.code || 1003 === response.error.code) {
        // Redirect to corresponding page.
        const connectCode = window.sessionStorage.getItem("connectCode");
        if (connectCode) {
          window.location.href =
            process.env.VUE_APP_MAINSITE_URL +
            `/login.php?connectCode=${connectCode}`;
        } else {
          this.$router.push({
            name: "GuestUser",
            params: {
              meetCode: this.$route.params.meetCode,
              passCode: this.$route.params.passCode
            }
          });
        }
      } else {
        this.$store.dispatch("displayGlobalAlert", {
          type: "error",
          msg:
            1008 === response.error.code || 1014 === response.error.code
              ? GeneralConstant.error.invalidURL
              : response.error.message
        });
      }
    }
  }
};
</script>

<style scoped>
.top-zero {
  top: 0px !important;
}
/* Changes for chat and invite participant - Below section is added*/
@media screen and (max-width: 768px),
  screen and (orientation: landscape) and (max-height: 425px) {
  /* Changes for chat */
  .hide-scroll {
    overflow: hidden;
  }
}
::v-deep .view-collapsed #active-pinned-video.room-pinned {
  width: 80%;
}
::v-deep .view-collapsed .room-pinned {
  width: 20%;
}
@media screen and (max-width: 1024px) {
  ::v-deep .view-collapsed #active-pinned-video.room-pinned {
    width: 75%;
  }
  ::v-deep .view-collapsed .room-pinned {
    width: 25%;
  }
}
.zoom-enter-active {
  animation: zoomIn 0.2s ease-in-out forwards;
}
.zoom-leave-active {
  animation: zoomOut 0.2s ease-in-out forwards;
}
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}
</style>
