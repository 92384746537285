import socket from "../../socket/socket";
import { mapGetters, mapMutations } from "vuex";

export const mediaEventListener = {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("meet", {
      peerConnections: "getPeerConnections"
    }),

    ...mapGetters({
      userId: "getUserId"
    })
  },
  methods: {
    ...mapMutations("meet", {
      setPeerConnections: "setPeerConnections"
    }),

    /**
     * Listening to socket events for section part
     */
    listenToMediaEvent() {
      // Listener to be called when participant mute/unmute audio
      socket.io.on("userAudioMuted", data => {
        if (data.id !== socket.io.id && this.peerConnections[data.id]) {
          this.setPeerConnections({
            ...this.peerConnections,
            [data.id]: {
              ...this.peerConnections[data.id],
              isAudioMuted: data.muteStatus
            }
          });
        }
      });

      // Listener to be called when participant mute/unmute video
      socket.io.on("userVideoMuted", data => {
        if (data.id !== socket.io.id && this.peerConnections[data.id]) {
          this.setPeerConnections({
            ...this.peerConnections,
            [data.id]: {
              ...this.peerConnections[data.id],
              isVideoMuted: data.muteStatus
            }
          });
        }
      });

      // Listener to be called when participant changes the track
      socket.io.on("videoTrackChanged", data => {
        if (this.peerConnections[data.id]) {
          this.setPeerConnections({
            ...this.peerConnections,
            [data.id]: {
              ...this.peerConnections[data.id],
              videoTrackType: data.track
            }
          });
        }
      });
    }
  }
};
