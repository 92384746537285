<template>
  <div class="common-modal" id="microphone-setting-m1">
    <div class="modal-container">
      <div class="modal-header">
        <h4>Record and play, do you hear a replay?</h4>
        <a @click="hideMicTestPopup" class="icon-close cursorPointer">
          <i class="bi bi-x"></i>
        </a>
        <audio id="audioPlay" autoplay class="hide" controls></audio>
      </div>

      <div class="modal-body">
        <form action="#">
          <template v-if="audioInput.length > 0">
            <label id="user-microphone-opt" class="dis-bl">
              Select microphone
            </label>
            <div class="custom-select sel-medium">
              <select
                ref="micList"
                id="user-microphone-opt"
                @change="changeMic"
              >
                <option
                  v-for="input in audioInput"
                  :value="input.key"
                  :key="input.key"
                  :selected="input.key === selectedAudioInput"
                >
                  {{ input.value }}
                </option>
              </select>
            </div>
          </template>
          <div class="pids-wrapper">
            <h5>Input Level</h5>

            <div
              v-for="pid in pids"
              :class="{ pid: true, active: pid.isActive }"
              :key="pid.id"
            ></div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <div class="modal-f-ctas">
          <a
            @click="hideMicTestPopup"
            class="btn-primary-outline mr-1 cursorPointer"
          >
            Yes
          </a>
          <a
            @click="startRecording()"
            class="btn-primary ds-bl mt-2 cursorPointer"
            v-if="'inactive' === recordState"
          >
            Start Recording
          </a>
          <a
            @click="stopRecording(true)"
            class="btn-primary ds-bl mt-2 cursorPointer"
            v-else-if="'recording' === recordState"
          >
            Stop and Play
          </a>
          <a
            class="btn-primary ds-bl mt-2 cursorPointer"
            v-else-if="'playing' === recordState"
          >
            Playing...
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { soundMeter } from "../../mixins/SoundMeter";
import { mapGetters } from "vuex";

export default {
  name: "MicrophoneTest",
  mixins: [soundMeter],
  data() {
    return {
      testMic: false,
      mediaRecorder: null,
      recordState: "inactive",
      playAudio: false,
      audioTrack: null,
      isCameraStreamReady: false,
      audioBlobs: []
    };
  },
  computed: {
    ...mapGetters("meet", {
      audioInput: "getAudioInput",
      selectedAudioInput: "getSelectedAudioInput"
    })
  },
  methods: {
    /**
     * Function to start audio recording
     */
    startRecording() {
      if (this.isCameraStreamReady) {
        this.initMediaRecorder();
        this.mediaRecorder.start();
      }
    },

    /**
     * Function to stop audio recording
     */
    stopRecording(playAudio) {
      this.playAudio = playAudio;
      this.mediaRecorder.stop();
    },

    /**
     * Funcition to change the selected microphone
     */
    changeMic() {
      if (this.isCameraStreamReady) {
        this.loadAudioStream(this.$refs.micList.value);
        if ("recording" === this.mediaRecorder.state) {
          this.stopRecording(false);
        }
        document.getElementById("audioPlay").pause();
        this.recordState = "inactive";
      }
    },

    /**
     * Function to hide microphone test popup
     */
    hideMicTestPopup() {
      // this.toggleMicTesting(false);
      this.$emit("hideMicTestPopup");
    },

    /**
     * Function to initiate media recorder
     */
    initMediaRecorder() {
      // Creating new media recorder object
      this.mediaRecorder = new MediaRecorder(
        new MediaStream([this.audioTrack])
      );
      this.audioBlobs = [];

      // Listening to start recording event on media recorder object
      this.mediaRecorder.addEventListener("start", () => {
        this.recordState = "recording";
      });

      // Listeing to data available event on media recorder object
      this.mediaRecorder.addEventListener("dataavailable", event => {
        this.audioBlobs.push(event.data);
      });

      // Listening to stop event on media recorder object
      this.mediaRecorder.addEventListener("stop", () => {
        if (this.playAudio) {
          let audioData = new Blob(this.audioBlobs, { type: "audio/mp3;" });
          let audioURL = window.URL.createObjectURL(audioData);
          document.getElementById("audioPlay").src = audioURL;
        } else {
          this.recordState = "inactive";
        }
        this.audioBlobs = [];
      });
    },

    /**
     * Loading the audio stream from a given audio input device
     */
    loadAudioStream(audioInput) {
      this.isCameraStreamReady = false;
      navigator.mediaDevices
        .getUserMedia({
          video: false,
          audio: {
            deviceId: audioInput ? { exact: audioInput } : undefined
          }
        })
        .then(stream => {
          this.audioTrack = stream.getAudioTracks()[0];
        })
        .catch(this.errorHandler)
        .finally(() => {
          this.isCameraStreamReady = true;
        });
    },

    /**
     * Error on accessing input/output device gets catchup here
     *
     * @param {Object} error
     */
    errorHandler(error) {
      let errorMsg = error;
      if ("object" === typeof error) {
        errorMsg = error.message;
      }
      this.$store.dispatch("displayGlobalAlert", {
        type: "error",
        msg: errorMsg
      });
    }
  },
  mounted() {
    // Listening to audio end event
    document.getElementById("audioPlay").addEventListener("ended", event => {
      this.recordState = "inactive";
    });

    // Listening to audio play event
    document.getElementById("audioPlay").onplay = () => {
      this.recordState = "playing";
      let audioElement = document.getElementById("audioPlay");
      this.disconnect();
      this.createAudioContext();
      this.createAnalyser();
      this.createScriptProcessor();
      this.connectToSource(
        navigator.userAgent.indexOf("Firefox") > -1
          ? audioElement.mozCaptureStream()
          : audioElement.captureStream(),
        null
      );
    };
  },
  created() {
    // Loading the audio stream input from default selected audio input device
    this.loadAudioStream(this.selectedAudioInput);
  }
};
</script>

<style scoped>
#microphone-setting-m1.common-modal {
  z-index: 4;
  /*--max-width: 360px;--*/
  position: relative;
  left: unset;
  width: 30%;
  height: fit-content;
}
#microphone-setting-m1.common-modal .custom-select {
  margin-left: 0;
  max-width: 100%;
}
.custom-select.sel-medium select {
  max-width: 100%;
}
#microphone-setting-m1.common-modal form {
  overflow-x: hidden;
  min-width: 100%;
  width: 100%;
}
@media screen and (max-width: 768px) {
  #microphone-setting-m1 {
    display: none;
  }
}
</style>
