<template>
  <div class="invite-participant">
    <ul id="opt-copylink" v-if="!userListDisplay">
      <li>
        <a
          class="btn-primary btn-copy-invite cursorPointer"
          @click="copyInviteMeetLink"
        >
          Copy Invite Link
        </a>
      </li>
      <li>
        <a
          @click="fetchAndDisplayContactList"
          class="link-primary select-participants cursorPointer"
        >
          Select participants from your contacts
          <span>
            <i class="bi bi-chevron-right"> </i>
          </span>
        </a>
      </li>
    </ul>
    <transition name="slide" mode="out-in">
      <div class="custom-scroll scroll-modal iop" v-if="userListDisplay">
        <div id="invite-options">
          <ul>
            <li id="contact-list">
              <div v-if="isSendInvitationRequestPending" class="loader"></div>
              <template v-else>
                <div class="contact-header">
                  <a @click="cancelInvitation" class="back cursorPointer">
                    <i class="bi bi-arrow-left"></i>
                  </a>
                  <h4>Select Contacts</h4>
                </div>
                <input
                  type="text"
                  :value="searchValue"
                  @input="filterSearch($event.target.value)"
                  placeholder="Search Contacts"
                />
                <br /><br />
                <div class="container-contacts">
                  <div class="custom-scroll scroll-small">
                    <div
                      class="loader"
                      v-if="'pending' === userListFetchStatus"
                    ></div>
                    <template v-else>
                      <label
                        v-for="user in formattedUserList"
                        :key="user.user_id"
                        class="checkbox"
                      >
                        <div class="checkbox-name">{{ user.name }}</div>
                        <input
                          @change="verifyCheckboxVal($event)"
                          type="checkbox"
                          :value="user.user_id"
                          v-model="selectedUserIds"
                          checked="checked"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </template>
                  </div>

                  <div
                    class="error-message no-contact-selected"
                    v-if="userSelectionErr.show"
                  >
                    {{ userSelectionErr.msg }}
                  </div>
                </div>
                <a
                  class="btn-primary btn-select-participants btn-xs cursorPointer"
                  @click="selectParticipants"
                  v-if="!userSelected"
                >
                  Next
                </a>
                <div class="invite-message" v-else>
                  <label for="invite-message-text">Message</label>
                  <div
                    class="editor loading"
                    v-show="!isTinymceEditorInitialized"
                  >
                    Loading. Please wait...
                  </div>
                  <div class="editor" v-show="isTinymceEditorInitialized">
                    <editor
                      v-model="inviteText"
                      tinymce-script-src="/tinymce/js/tinymce/tinymce.min.js"
                      id="mailBody"
                      :init="{
                        height: 120,
                        menubar: false,
                        statusbar: false,
                        toolbar: false,
                        placeholder: 'Invite Message',
                        setup: initiateTinyMceEvent,
                        plugins: 'paste',
                        paste_as_text: true,
                        paste_preprocess: preProcessPasteEvent,
                        content_style: `
                              .mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before {
                                left: 0px;
                              },
                              body#tinymce p{
                                margin:5px 10px!important;
                              }
                            `
                      }"
                      @onInit="tinymceEditorInitialized"
                    />
                  </div>
                  <div
                    class="error-message no-invite-message"
                    v-if="invitationErr.show"
                  >
                    {{ invitationErr.msg }}
                  </div>

                  <div class="invite-ctas">
                    <div class="loader loader-send-invite"></div>
                    <button class="btn-primary" @click="sendInvitation">
                      Send Invite
                    </button>
                    <button class="btn-secondary" @click="cancelInvitation">
                      Cancel
                    </button>
                  </div>
                </div>
              </template>
            </li>
          </ul>
        </div>
        <!--Invite Modal Content Ends-->
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import GeneralConstant from "../../constant/GeneralConstant";
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "InviteParticipant",
  data() {
    return {
      userListDisplay: false,
      userSelected: false,
      inviteText: `Hi, <br/><br/> I would like to invite you to the MeetingLink: <br/><br/>
      <a href="${window.location.href}">${window.location.href}</a><br/><br/>Thanks<br/>`,
      selectedUserIds: [],
      userSelectionErr: {
        show: false,
        msg: ""
      },
      invitationErr: {
        show: false,
        msg: ""
      },
      searchValue: "",
      isTinymceEditorInitialized: false
    };
  },
  components: {
    editor: Editor
  },
  props: {
    isSidebarOpened: Boolean,
    isChatPannelOpened: Boolean,
    isParticipantPannelOpened: Boolean
  },
  computed: {
    ...mapGetters({
      userId: "getUserId",
      userName: "getUserName",
      modal: "getModal"
    }),

    ...mapGetters("participant", {
      isSendInvitationRequestPending: "getIsSendInvitationRequestPending",
      clients: "getClients",
      persons: "getPersons",
      subscribers: "getSubscribers",
      appUsers: "getAppUsers",
      userListFetchStatus: "getUserListFetchStatus"
    }),

    /**
     * Returns the complete user list
     */
    userList() {
      return this.clients.concat(this.persons, this.subscribers, this.appUsers);
    },

    /**
     * Returns formatted user list
     */
    formattedUserList() {
      if (this.searchValue.length > 0) {
        return this.userList.filter(contact => {
          return (
            contact.name
              .toLowerCase()
              .search(this.escape(this.searchValue.toLowerCase())) !== -1
          );
        });
      } else {
        return this.userList;
      }
    }
  },
  methods: {
    ...mapMutations({
      setModal: "setModal"
    }),

    ...mapMutations("participant", {
      setIsSendInvitationRequestPending: "setIsSendInvitationRequestPending"
    }),

    ...mapActions("participant", {
      sendMeetingInvitation: "sendMeetingInvitation"
    }),

    /**
     * Setting tinymce editor as initialized
     */
    tinymceEditorInitialized() {
      this.isTinymceEditorInitialized = true;
    },

    /**
     * Function fired when pasting to tinymce editor
     */
    preProcessPasteEvent(plugin, args) {
      //   let count = this.countTinyMceTextEditorCharacter();
      //   console.log(count);
      //   console.log(args.content.length + count);
      //   if (
      //     args.content.length + count >
      //     GeneralConstant.inviteParticipantTextAreaLimit
      //   ) {
      //     args.content = "";
      //   }
    },

    /**
     * Function to initiate event on tinymce editor
     */
    initiateTinyMceEvent(editor) {
      editor.on("keyup", e => {
        // backspace, delete, cursor and all keys except alphabetical ones are allowed
        let count = this.countTinyMceTextEditorCharacter();
        if (count >= GeneralConstant.inviteParticipantTextAreaLimit) {
          this.invitationErr.msg =
            GeneralConstant.error.inviteTextMaxAllowedCharsReached;
          this.invitationErr.show = true;
        } else {
          this.invitationErr.show = false;
        }
      });
    },

    /**
     * Function to count the number of character on tinymce text editor
     */
    countTinyMceTextEditorCharacter() {
      let body = tinyMCE.get("mailBody").getBody();
      let text = tinyMCE.trim(body.innerText || body.textContent);

      return text.length;
    },

    /**
     * Escape the given chars
     */
    escape(s) {
      // eslint-disable-next-line no-useless-escape
      return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
    },

    /**
     * Function to verify the checkbox value and hide the error message display
     */
    verifyCheckboxVal(event) {
      if (event.target.checked && this.userSelectionErr.show) {
        this.hideErrMsg();
      }
    },

    /**
     * Function to select the participant for sending invitation
     */
    selectParticipants() {
      if (0 === this.selectedUserIds.length) {
        this.userSelectionErr.msg =
          GeneralConstant.error.inviteContactNotSelected;
        this.userSelectionErr.show = true;
      } else {
        this.userSelected = true;
      }
    },

    /**
     * Function to send meeting invitation to selected participants
     */
    async sendInvitation() {
      let count = this.countTinyMceTextEditorCharacter();

      if (!this.isTinymceEditorInitialized) {
        this.invitationErr.msg =
          GeneralConstant.error.tinymceEditorNotLoadedYet;
        this.invitationErr.show = true;
      } else if (0 === this.selectedUserIds.length) {
        this.invitationErr.msg = GeneralConstant.error.inviteContactNotSelected;
        this.invitationErr.show = true;
      } else if (0 === this.inviteText.length) {
        this.invitationErr.msg = GeneralConstant.error.emptyInvitationMessage;
        this.invitationErr.show = true;
      } else if (count >= GeneralConstant.inviteParticipantTextAreaLimit) {
        this.invitationErr.msg =
          GeneralConstant.error.inviteTextMaxAllowedCharsReached;
        this.invitationErr.show = true;
      } else if (this.isSendInvitationRequestPending) {
        this.invitationErr.msg =
          GeneralConstant.error.previousInvitationRequestNotFinished;
        this.invitationErr.show = true;
      } else {
        // Hide errors, if any
        this.invitationErr.show = false;

        // Set loading to true
        this.setIsSendInvitationRequestPending(true);

        // Send Invitation
        let meetInvitationRes = await this.sendMeetingInvitation({
          body: this.inviteText,
          to: this.selectedUserIds
        });

        // Set loading to false
        this.setIsSendInvitationRequestPending(false);

        // If not success, show the error
        if ("0" !== meetInvitationRes.reasonCode) {
          this.invitationErr.msg = meetInvitationRes.error.message;
          this.invitationErr.show = true;
        } else {
          // Show a success message
          this.$store.dispatch("displayGlobalAlert", {
            msg: GeneralConstant.success.invitationSent,
            type: "success"
          });

          // Hide the popup
          this.setModal({
            ...this.modal,
            display: false
          });
        }
      }
    },

    /**
     * Function to cancel the meeting invitation process
     */
    cancelInvitation() {
      this.searchValue = "";
      this.selectedUserIds = [];
      this.userSelected = false;
      this.userListDisplay = false;
      this.hideErrMsg();
    },

    /**
     * Function to hide the error messages
     */
    hideErrMsg() {
      this.invitationErr.show = false;
      this.userSelectionErr.show = false;
    },

    /**
     * Function to copy the invitation link
     */
    copyInviteMeetLink() {
      navigator.clipboard.writeText(window.location.href);
    },

    /**
     * Function to fetch and display the contact list
     */
    async fetchAndDisplayContactList() {
      this.userListDisplay = !this.userListDisplay;
    },

    /**
     * Function to filter with a given search value
     */
    filterSearch(value) {
      this.searchValue = value;
    }
  },
  created() {
    this.hideErrMsg();
  }
};
</script>

<style scoped>
.invite-participant h4 {
  color: #fff;
  margin: 0px 0px 7px 0px;
}
.invite-participant .select-participants {
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #7a7a7a;
  padding: 5px 10px;
  border-radius: 5px;
  display: block;
}
#opt-copylink {
  margin: 20px 0;
  padding: 0;
  list-style: none;
}
#invite-options {
  padding: 0;
  margin: 0;
}
#invite-options ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#invite-options ul li {
  margin: 0 0 15px;
  display: inline-block;
  width: 100%;
  position: relative;
}
#invite-options ul li span {
  position: absolute;
  right: 10px;
}
#invite-options ul li a {
  text-decoration: none;
  font-size: 14px;
  padding: 5px 10px;
  display: block;
}
#invite-options ul li .contact-list {
  display: none;
}
#contact-list {
  margin-bottom: 10px !important;
}
#contact-list input {
  border: 1px solid #7a7a7a;
  background: #282828;
  max-width: calc(100% - 24px);
  padding: 5px 10px;
  color: #f6f6f6;
  position: relative;
  left: 1px;
}
.container-contacts {
  margin-bottom: 10px;
}
.container-contacts .checkbox {
  display: inline-block;
  width: 40%;
}
/* CSS added for name label (Start) */
.checkbox .checkbox-name {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.checkbox input {
  display: none;
}
/* CSS added for name label (End) */
.custom-scroll.scroll-small {
  max-height: 110px;
}
.btn-copy-invite,
.btn-select-participants {
  display: block;
}
.selected-contacts-forinvite {
  display: none;
}
.invite-message label {
  color: #fff !important;
  font-size: 14px;
  margin: 0 !important;
  display: block;
}
.invite-message textarea {
  width: 100%;
  margin-bottom: 10px;
  background: #252525 !important;
  color: #f6f6f6 !important;
  border-radius: 3px;
  font-size: 14px;
  height: 70px;
}
body#tinymce {
  margin: 5px 10px;
  padding: 0;
  background: #333 !important;
  color: #fff !important;
}
body#tinymce p {
  margin: 5px 10px !important;
}
.tox-tinymce {
  border: 1px solid #333;
}
.editor {
  margin-bottom: 10px;
  padding: 0;
  border-radius: 3px;
  font-size: 14px;
  width: 100%;
}
.no-contact-selected,
.no-invite-message {
  margin-bottom: 10px;
  margin-top: 10px;
}
.invite-ctas {
  margin-bottom: 5px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.invite-ctas button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loader-send-invite {
  display: none;
  font-size: 4px;
  width: 6px;
  height: 6px;
  position: absolute;
  left: 30px !important;
}
.btn-active-on {
  margin-left: 40px;
}
#opt-copylink li:not(:first-of-type) {
  margin: 15px 0 0px;
  display: inline-block;
  width: 100%;
  position: relative;
}
#opt-copylink li .select-participants span {
  position: absolute;
  right: 10px;
}
.contact-header {
  display: flex;
}
.back {
  padding: 0px 0px !important;
  color: #282828;
  width: 22px;
  height: 22px;
  background: #ebebeb;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  margin-right: 7px;
}
.custom-scroll.scroll-modal {
  max-height: none;
}

.slide-enter-active {
  animation: in 0.2s ease-in-out forwards;
}

/* stage 3. leave */
.slide-leave-active {
  animation: out 0s ease-in-out forwards;
}

.slide-move {
  transition: transform 0.2s ease-in-out;
}

/* Load message which is shown, until editor loads completely. */
.loading.editor {
  height: 80px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes out {
  from {
    transform: translateX(0px);
    opacity: 1;
  }

  to {
    transform: translateX(20%);
    opacity: 0;
  }
}

@keyframes in {
  from {
    transform: translateX(20%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
